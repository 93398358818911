import styled from 'styled-components';

export const CellPill = styled.div`
  background-color: ${p => p.theme.colors.grey10};
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  ${p => p.loading && `opacity: 0.4;`}
`;

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -6px;

  & > div {
    margin-top: 6px;
    margin-right: 6px;
    &:last-of-type { margin-right: 0; }
  }
`;
