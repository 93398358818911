import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { capitalize } from 'lodash';

import IconSvg from '../../../../components/IconSvg';
import Button from '../../../../components/Button';

import openSupportChat from '../../../../utils/openSupportChat';

import { HOW_IT_WORKS_TYPES as TYPES } from '../constants';
import {
  formatPlural,
  SUBSCRIPTION_TYPES_PLURAL_MAP,
} from '../../../../utils/pluralRules';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin: ${p => p.theme.spacing.medium} 0 0 0;
    ${p => p.theme.type.heading3}
    &.description {
      ${p => p.theme.type.textLarge};
      margin-bottom: ${p => p.theme.spacing.large};
    }
  }
`;

const ButtonStyled = styled(Button)`
  padding: 0;
  width: fit-content;
  font-size: 14px;
  ${p => p.hasMB && `margin-bottom: ${p.theme.spacing.medium};`}
`;

const HowItWorks = ({ type }) => {
  const pluralType = formatPlural(
    SUBSCRIPTION_TYPES_PLURAL_MAP[type].pluralString,
    2,
    SUBSCRIPTION_TYPES_PLURAL_MAP[type].pluralMap,
  );
  return (
    <Wrapper>
      <IconSvg
        name={TYPES[type].icon}
        size="28"
        box="56"
        color={TYPES[type].iconColor}
      />
      <div>How {capitalize(pluralType)} work</div>
      {TYPES[type].description}
      <ButtonStyled
        text
        large
        hasMB
        icon={<IconSvg name="new-window" size={16} />}
        href={TYPES[type].readMoreLink}
        target="_blank"
      >
        Read more
      </ButtonStyled>
      <ButtonStyled
        text
        large
        icon={<IconSvg name="chat" size={16} />}
        onClick={() => openSupportChat()}
      >
        Talk to Support
      </ButtonStyled>
    </Wrapper>
  );
};

HowItWorks.propTypes = {
  type: PropTypes.oneOf(Object.keys(TYPES)),
};

export default memo(withTheme(HowItWorks));
