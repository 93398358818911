import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isNil from 'lodash/isNil';
import { PAPI } from '@marketmuse/config/types';

import CreditsPurchaseForm from '../components/CreditsPurchaseForm';
import CreditsSummary from '../components/CreditsSummary';
import HowItWorks from '../components/HowItWorks';
import RecurlyBillingHistory from '../components/RecurlyBillingHistory';
import RecurlyBillingInfoWarnings from '../components/RecurlyBillinlgInfoWarnings';
import SimpleGrid from '../../../../components/SimpleGrid';

import { toggleDynamic } from '../../../../actions/miscActions';

import * as types from '../../../../config/types';
import { VIEWS } from '../../../../config/payment/settingsModal';

import useHasAccess from '../../../../hooks/useHasAccess';

const CreditBalance = () => {
  const dispatch = useDispatch();

  const canBuyCredits = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_CREDITS,
  });
  const isCheckingTransactions = useSelector(
    state => state?.loadings?.[types.RECURLY_GET_TRANSACTIONS],
  );
  const account = useSelector(state => state.payment.account);

  const hasAccount = useMemo(() => account && account !== 404, [account]);
  const hasBillingInfo = useMemo(() => !isNil(account?.billingInfo), [account]);

  const handleSettingsClick = useCallback(
    () =>
      dispatch(
        toggleDynamic({
          id: 'paymentSettingsModal',
          status: true,
          data: { activeScreen: VIEWS.card },
        }),
      ),
    [dispatch],
  );

  const isDisabled = useMemo(
    () =>
      isCheckingTransactions || !hasAccount || (hasAccount && !hasBillingInfo),
    [isCheckingTransactions, hasAccount, hasBillingInfo],
  );

  return (
    <div>
      <SimpleGrid.Container gap={'32px'} columns={2} padding="0">
        <SimpleGrid.Item span={{ initial: 2, md: 1 }}>
          <CreditsSummary />
        </SimpleGrid.Item>
        <SimpleGrid.Item span={{ initial: 2, md: 1 }}>
          {canBuyCredits && <CreditsPurchaseForm isDisabled={isDisabled} />}
        </SimpleGrid.Item>
        <SimpleGrid.Item span={{ initial: 2, md: 2 }}>
          <RecurlyBillingInfoWarnings
            handleSettingsClick={handleSettingsClick}
            isCheckingTransactions={isCheckingTransactions}
            hasAccount={hasAccount}
            hasBillingInfo={hasBillingInfo}
          />
        </SimpleGrid.Item>
        <SimpleGrid.Item span={{ initial: 2 }}>
          <RecurlyBillingHistory type="credit" />
        </SimpleGrid.Item>
        <SimpleGrid.Item span={{ initial: 2 }}>
          <HowItWorks type="credit" />
        </SimpleGrid.Item>
      </SimpleGrid.Container>
    </div>
  );
};

export default memo(CreditBalance);
