import React from 'react';
import openSupportChat from '../../../utils/openSupportChat';
import Clickable from '../../../components/Clickable';

export const HOW_IT_WORKS_TYPES = {
  credit: {
    description: (
      <div className="description">
        Credits are used to purchase content briefs. Extra Credits never expire
        and are rolled over to the next month.
      </div>
    ),
    icon: 'credits',
    iconColor: 'yellowBase',
    readMoreLink: 'https://docs.marketmuse.com/en/articles/35-credits-faqs',
    emptyIssuanceMessage: {
      premium: (
        <>
          You have not purchased any credits in platform. After purchasing
          credit packages they will be recorded here.
          <br />
          Please{' '}
          <Clickable
            style={{
              borderBottom: '1px solid currentColor',
              display: 'inline',
              lineHeight: '18px',
            }}
            onClick={() => openSupportChat()}
          >
            contact support
          </Clickable>{' '}
          to find out how to purchase more credits for your account.
        </>
      ),
      default:
        'You have not purchased any credits in platform. After purchasing credit packages they will be recorded here.',
    },
  },
  query: {
    description: (
      <div className="description">
        A query is used every time you enter a focus topic into one of the
        MarketMuse Applications as you research, create, and optimize content.
        Monthly Queries refresh once a month. They are not rolled over to the
        next month.
      </div>
    ),
    icon: 'queries',
    iconColor: 'greenBase',
    readMoreLink: 'https://docs.marketmuse.com/en/articles/44-queries-faqs',
    emptyIssuanceMessage: {
      default:
        'You have not purchased any queries in platform. After purchasing query packages they will be recorded here.',
    },
  },
};
