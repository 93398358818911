import React, { useMemo } from 'react';
import { useUser } from '@clerk/clerk-react';

import useSessionData from './useSessionData';
import { IdentityContext } from './context';

const IdentityProvider = ({ children }) => {
  const sessionId = useSessionData();
  const { isLoaded, isSignedIn, user } = useUser();

  const context = useMemo(
    () => ({
      sessionId,
      isLoading: isLoaded === null,
      isSignedIn,
      user,
    }),
    [sessionId, isLoaded, isSignedIn, user],
  );

  return (
    <IdentityContext.Provider value={context}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;
