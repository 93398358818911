import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import get from 'lodash/get';

import CreditCard from '../../../components/CreditCard';
import CreditCardInput from '../../../components/CreditCardInput';
import Toast from '../../../components/Toast';

import * as types from '../../../config/types';
import * as recurlyActions from '../../../actions/recurlyActions';
import useSessionStorage from '../../../hooks/useSessionStorage';

const Wrapper = styled.div``;

const CurrentCardHeader = styled.div`
  color: ${p => p.theme.colors.grey85};
  font-weight: bold;
  font-size: 12pt;
  margin-bottom: 4px;
`;

class UpdateBillingInfo extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      recurlyAccount: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(recurlyToken) {
    const silentMode = this.props.suppressMessage;
    if (typeof this.props.onSubmit === 'function') {
      return this.props.onSubmit(recurlyToken);
    }

    this.props.recurlyActions.upsertBillingInfo({
      recurlyToken,
      callbackFail: () => {
        if (typeof this.props.onUpdateFailed === 'function') {
          this.props.onUpdateFailed();
        }
      },
      callback: updatedBillingInfo => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSessionStorage('coupon').remove();
        this.setState({ recurlyAccount: updatedBillingInfo });
        if (!silentMode) Toast.fire('Payment information updated');
        if (typeof this.props.onUpdated === 'function') {
          this.props.onUpdated(updatedBillingInfo);
        }
      },
    });
  }

  render() {
    const hasAccount = this.props.payment?.account !== 404;
    const isCheckingAccount = this.props.loadings[types.RECURLY_GET_ACCOUNT];
    const isUpdating = this.props.loadings[types.RECURLY_UPSERT_BILLING_INFO];
    const isCreatingAccount = this.props.loadings[types.RECURLY_CREATE_ACCOUNT];
    const isLoading =
      isCheckingAccount ||
      isUpdating ||
      isCreatingAccount ||
      this.props.ctaLoading;

    const userFirstName =
      get(this.props, 'user.firstName') || get(this.props, 'user.fullName');
    const userLastName = get(this.props, 'user.lastName');

    const cardType = get(
      this.props,
      'payment.account.billingInfo.paymentMethod.cardType',
    );
    const cardLastFour = get(
      this.props,
      'payment.account.billingInfo.paymentMethod.lastFour',
    );
    const cardLastName = get(
      this.props,
      'payment.account.billingInfo.lastName',
    );
    const cardFirstName = get(
      this.props,
      'payment.account.billingInfo.firstName',
    );

    const firstName = userFirstName ? userFirstName : cardFirstName;
    const lastName =
      userFirstName && userLastName ? userLastName : cardLastName;

    return (
      <Wrapper className={this.props.className}>
        {!isLoading &&
          !hasAccount &&
          'No billing accounts has been set up for your organization. ' +
            'Please contact a MarketMuse strategist to get started.'}

        {!isLoading &&
          hasAccount &&
          this.props.showCurrentCard &&
          cardType &&
          cardLastFour && (
            <>
              <CurrentCardHeader style={{ marginBottom: 12 }}>
                Card in Use
              </CurrentCardHeader>
              <CreditCard
                type={cardType}
                lastFour={cardLastFour}
                name={`${firstName} ${lastName}`}
                style={{ marginBottom: 42, width: 220 }}
              />
              <CurrentCardHeader style={{ marginBottom: 22 }}>
                Update Card
              </CurrentCardHeader>
            </>
          )}

        {(isLoading || (!isLoading && hasAccount)) && (
          <CreditCardInput
            cta={this.props.ctaText || 'Update'}
            ctaAttributes={this.props.ctaAttributes}
            cancelButton={this.props.cancelButton}
            firstName={firstName}
            lastName={lastName}
            disabled={!hasAccount || isCheckingAccount}
            loading={isLoading}
            onCardSubmitted={this.props.onUpdateClicked}
            onTokenReceived={token => {
              if (!get(token, 'id')) {
                Toast.fire('Failed to process your card', 'error');
                if (typeof this.props.onUpdateFailed === 'function') {
                  this.props.onUpdateFailed();
                }
              } else {
                this.onSubmit(get(token, 'id'));
              }
            }}
          />
        )}
      </Wrapper>
    );
  }
}

UpdateBillingInfo.propTypes = {
  loadings: PropTypes.object,
  recurlyActions: PropTypes.object,
  // text that should appear on the button
  ctaText: PropTypes.string,
  ctaAttributes: PropTypes.object,
  // force cta button to be in a loading state
  ctaLoading: PropTypes.bool,
  // do not show a success message after update
  suppressMessage: PropTypes.bool,
  // call immediately after update action is taken
  onUpdateClicked: PropTypes.func,
  // function to call when billing info is updated.
  // receives full account data (ie. output of
  // recurlyGetAccount) with updated billing.
  onUpdated: PropTypes.func,
  onUpdateFailed: PropTypes.func,
  // show the current card atm
  showCurrentCard: PropTypes.bool,
  cancelButton: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => ({
  loadings: state.loadings,
  recurly: state.recurly,
  user: {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
  },
});

const mapDispatchToProps = dispatch => ({
  recurlyActions: bindActionCreators(recurlyActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBillingInfo);
