type Formatters = {
  default: Intl.NumberFormat;
  money: () => Intl.NumberFormat;
};

type Options = {
  type?: 'default' | 'money' | undefined;
  formatterOptions?: Record<any, any> | undefined;
};

// function to determine if formatter initialized correctly
const formatAvailable = (f: Intl.NumberFormat) =>
  f && typeof f.format === 'function';

const moneyOptions = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
};

// initialize formatter instances
const formatters: Formatters = !Intl
  ? ({} as Formatters)
  : {
      default: new Intl.NumberFormat('en-US'),
      money: (options = {}) =>
        new Intl.NumberFormat('en-US', {
          ...moneyOptions,
          ...options,
        }),
    };

function getFormatter(options: Options) {
  let formatter = formatters.default;

  if (options?.type && formatters[options.type]) {
    if (typeof formatters[options.type] === 'function') {
      // @ts-expect-error - doesnt work with possibles
      formatter = formatters[options?.type]?.(options?.formatterOptions);
    } else {
      // @ts-expect-error - doesnt work with possibles
      formatter = formatters[options.type];
    }
  }

  return formatter;
}

export const format = (number: number, options: Options = {}) => {
  // grab formatter
  const formatter = getFormatter(options);

  // if formatter not available, return
  if (formatter !== undefined && !formatAvailable(formatter)) {
    return number;
  }

  // try to format, return number on failure
  try {
    if (formatter) {
      const value = formatter.format(number);
      return value;
    } else {
      return number;
    }
  } catch (error) {
    return number;
  }
};
