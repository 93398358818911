export const VIEWS = {
  credits: 'payment-modal--credits',
  creditsUsage: 'payment-modal--creditsUsage',
  queries: 'payment-modal--queries',
  card: 'payment-modal--card',
  users: 'payment-modal--users',
  marketplace: 'payment-modal--marketplace',
};

export const VIEWS_CONFIG = {
  [VIEWS.credits]: { title: 'Credit Balance' },
  [VIEWS.creditsUsage]: { title: 'Credit Usage' },
  [VIEWS.queries]: { title: 'Query Balance' },
  [VIEWS.card]: { title: 'Payment Card', icon: 'payment-card' },
  [VIEWS.users]: { title: 'Add Users' },
  [VIEWS.marketplace]: { title: 'Marketplace' },
};
