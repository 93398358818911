import { get } from 'lodash';
import { SiteDocument } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';
import { updateCredits } from '../../actions/siteActions';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === types.GET_SITE) {
      const state = getState();
      const filterSiteID = get(state, 'filter.site.id');

      makeRequest({
        type: types.GET_SITE,
        mutation: SiteDocument,
        variables: {
          id: action.siteId,
        },
        options: {
          isQuery: true,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(response => {
        if (action.siteId === filterSiteID) {
          dispatch(
            updateCredits({
              briefCredits: get(response, 'site.briefCredits'),
              siteId: action.siteId,
            }),
          );
        }

        if (typeof action.callback === 'function') {
          action.callback(response.site);
        }
      });
    }

    return next(action);
  };
