import get from 'lodash/get';

import { SignupPublicDocument } from '@marketmuse/data-papi';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === types.SIGNUP_PUBLIC) {
      makeRequest({
        type: types.SIGNUP_PUBLIC,
        mutation: SignupPublicDocument,
        variables: action.fields,
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const errors =
          get(res, 'signupPublic.errors') ||
          get(res, 'errors') ||
          get(res, 'error');
        const data = get(res, 'signupPublic');

        if (errors && action.onError && typeof action.onError === 'function') {
          return action.onError(errors);
        }

        if (!errors) {
          return action.callback(data);
        }
      });
    }

    return next(action);
  };
