import { Suspense, memo } from 'react';
import { PAPI } from '@marketmuse/config/types';
import {
  useStoreSelector,
  useStoreDispatch,
} from '@marketmuse/data-state/hooks';
import { ACTION_TYPES, MISC_SIMPLE_KEYS } from '@marketmuse/data-state';
import { lazyLoad } from '@marketmuse/utilities';

import { SIGNUP_STEPS } from '../../config/signupSteps';
import ExpiredTrialModal from './SubscriptionStatus/ExpiredTrialModal';
import ExpiredNonTrialModal from './SubscriptionStatus/ExpiredNonTrialModal';
import Dunning from './SubscriptionStatus/Dunning';

const SubscriptionLadder = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SubscriptionLadder' */ '@marketmuse/account-subscription/SubscriptionLadder'
    ),
);

const SubscriptionModals = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'SubscriptionModals' */ '@marketmuse/account-subscription/SubscriptionModals'
    ),
);

const statusScreen = ({
  state,
  dunning,
  plan,
}: {
  plan: PAPI.SubscriptionPlanType | null;
  state: PAPI.SubscriptionState;
  dunning: boolean;
}) => {
  // if plan is free, never show status screen
  if (plan === PAPI.SubscriptionPlanType.FREE) {
    return null;
  }
  // hide dunning only if custom, or free
  if (dunning && plan !== PAPI.SubscriptionPlanType.CUSTOM) {
    return 'DUNNING';
  }

  // other cases we only care of state is expired
  if (state !== PAPI.SubscriptionState.EXPIRED) {
    return null;
  }

  if (plan === PAPI.SubscriptionPlanType.RESEARCH_TRIAL) {
    return 'EXPIRED_TRIAL';
  }

  return 'EXPIRED';
};

function Subscription() {
  const dispatch = useStoreDispatch();
  const subscription = useStoreSelector(state => state.payment.subscription);
  const onboardingStep = useStoreSelector(state => state.user?.onboardingStep);
  const showSubscriptionManagement = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.subscriptionManagement],
  );
  const subscriptionManagementData = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.subscriptionManagementData],
  );

  const inSignupPayment = SIGNUP_STEPS.payment.includes(onboardingStep);

  const status = statusScreen({
    dunning: subscription?.dunning || false,
    state: subscription?.state || PAPI.SubscriptionState.PAID,
    plan: subscription?.subscriptionPlan?.type || null,
  });

  return (
    <>
      {(showSubscriptionManagement || inSignupPayment) && (
        <Suspense fallback={<div />}>
          <SubscriptionLadder
            planName={
              subscriptionManagementData?.planName ||
              (onboardingStep?.split(':')[1] as PAPI.SubscriptionPlanType)
            }
            onClose={() => {
              dispatch({
                type: ACTION_TYPES.TOGGLE_DYNAMIC,
                payload: {
                  id: MISC_SIMPLE_KEYS.subscriptionManagement,
                  status: false,
                  data: null,
                },
              });
            }}
          />
        </Suspense>
      )}
      {status && status === 'EXPIRED' && <ExpiredNonTrialModal />}
      {status && status === 'EXPIRED_TRIAL' && <ExpiredTrialModal />}
      {status && status === 'DUNNING' && <Dunning />}
      <SubscriptionModals />
    </>
  );
}

export default memo(Subscription);
