import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Lock from '../../../assets/lock.svg';

import UpdateBillingInfo from '../UpdateBillingInfo';
const IconImg = styled.img`
  margin-right: ${p => p?.marginRight ?? p.theme.spacing.small};
`;

const Heading = styled.div`
  ${p => p.theme.type.heading2};
  margin-top: 0;
  margin-bottom: ${p => p.theme.spacing.small};
  font-size: 24px;
  line-height: 29px;
`;

const SubHeading = styled.div`
  ${p => p.theme.type.textSmall}
  font-size: 12px;
  line-height: 22px;
  color: ${p => p.theme.colors.black};
  margin-bottom: 17px;
`;

const CreditCardForm = ({ onSubmit, user, ctaText, ctaLoading }) => (
  <>
    <Heading>Payment Details</Heading>
    <SubHeading>
      <IconImg src={Lock} />
      We use 2,048-bit SSL encryption and are fully PCI Compliant.
    </SubHeading>
    <UpdateBillingInfo
      user={user}
      ctaText={ctaText}
      onSubmit={onSubmit}
      ctaLoading={ctaLoading}
    />
  </>
);

CreditCardForm.defaultProps = {
  ctaText: 'Complete and Upgrade',
  user: {},
};

CreditCardForm.propTypes = {
  ctaText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default CreditCardForm;
