import { isNil } from 'lodash';

import { ProjectOrderRemoveDocument } from '@marketmuse/data-papi';

import Toast from '../../components/Toast';
import { setSimple } from '../../actions/miscActions';
import { getSite } from '../../actions/siteActions';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';
import { getAggregateStats } from '../../actions/siteActions';

export default () =>
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === types.REMOVE_PROJECT_ORDER) {
      const { callback, projectId, siteId } = action;
      makeRequest({
        type: types.REMOVE_PROJECT_ORDER,
        mutation: ProjectOrderRemoveDocument,
        variables: {
          projectId: projectId,
        },
      }).then(res => {
        const isRemoved = !isNil(res?.removeProjectOrder?.id);
        if (res.error || res.errors || !isRemoved) {
          Toast.fire(
            res.error || 'Unable to cancel this Content Brief',
            'error',
          );
        }

        if (isRemoved) {
          dispatch(
            setSimple({
              key: 'tableViewRefetchTime',
              value: new Date(),
            }),
          );
          dispatch(getSite({ siteId }));
          dispatch(getAggregateStats({ siteId }));
        }

        if (typeof callback === 'function') {
          action.callback(isRemoved);
        }
      });
    }
    return next(action);
  };
