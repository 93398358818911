import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isNil from 'lodash/isNil';

import Clickable from '../Clickable';
import SimpleGrid from '../SimpleGrid';
import IconSvg from '../IconSvg';

const Wrapper = styled(Clickable)`
  width: 100%;
  height: 100%;
  position: relative;
  align-items: flex-start;
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  padding: 20px 26px;
  background-color: ${p => p.theme.colors.grey05};
  transition: ${p => p.theme.transition};
  &:hover {
    box-shadow: ${p => p.theme.boxShadowLow};
  }
`;

const IconSvgWrapper = styled(IconSvg)`
  flex-shrink: 0;
  position: absolute;
  top: 6px;
  left: 6px;
`;

const Contents = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  ${p => p.theme.type.textRegular};
  b, div {
    text-align: center;
  }
`;

const GreenText = styled.div`
  ${p => p.theme.type.textSmall};
  color: ${p => p.theme.colors.greenDark1};
`;

const PriceCard = props => (
  <SimpleGrid.Item span={{ initial: 6, sm: 2 }}>
    <Wrapper
      onClick={props.onClick}
    >
      {!isNil(props.icon) &&
      <IconSvgWrapper name="plus" size="12" box="10" color="blueDark1" />
      }
      <Contents>
        <b>{props.title}</b>
        <div>{`${props.currency || ''}${props.price}`}</div>
        {!isNil(props.savings) && (
          <GreenText><b>{props.savings}</b></GreenText>
        )}
      </Contents>
    </Wrapper>
  </SimpleGrid.Item>
);

PriceCard.propTypes = {
  icon: PropTypes.any,
  margin: PropTypes.number, // margin between cards
  title: PropTypes.string,
  currency: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  savings: PropTypes.string,
  onClick: PropTypes.func,
};

PriceCard.defaultProps = {
  margin: 12
};

export default PriceCard;
