import { ONBOARDING_DATA_KEYS } from './onboardingData';

// user.onboardingStep
// complete/null - user doesnt need to know about this
// checklist - user should see checklist in sidebar
// checklist:coupon - user should see checklist in sidebar, qualifies for coupon
export const ONBOARDING_STEPS = {
  complete: 'complete',
  checklist: 'checklist',
  coupon: 'checklist:coupon',
  onboardingData: Object.keys(ONBOARDING_DATA_KEYS).map(key => `onboardingData:${key}`),
};
