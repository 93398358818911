import { pluralize } from '@marketmuse/utilities';
/**
 *
 * @param {String} string - string to make plural
 * @param {Number} count - how many items to compare
 * @param {Object.<{zero: String, one: String, other: String}>} - key pair map of suffixes
 */
export const formatPlural = (string, count, pluralMap) =>
  pluralize(string, count, pluralMap);

export const SUBSCRIPTION_TYPES_PLURAL_MAP = {
  credit: {
    pluralString: 'credit',
    pluralMap: { zero: 's', one: '', other: 's' },
  },
  query: {
    pluralString: 'quer',
    pluralMap: { zero: 'ies', one: 'y', other: 'ies' },
  },
};
