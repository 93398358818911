import React  from 'react';
import PropTypes from 'prop-types';
import { TDHead } from './styleTD';

// note: 'TDHead' is an alias for clarity, the actual tag is <th>
const TDHeadComponent = ({
  style,
  children,
  width,
  fixedWidth,
  computed,
}) => (
  <TDHead
    style={style}
    width={width}
    fixedWidth={fixedWidth}
    computed={computed}
  >
    {children}
  </TDHead>
);

TDHeadComponent.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any,
  headerProps: PropTypes.object,
  fixedWidth: PropTypes.bool,
  columnName: PropTypes.string
};

export default TDHeadComponent;
