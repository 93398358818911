import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { checkSiteViableInventory } from '@marketmuse/utilities';
import {
  useStoreSelector,
  useStoreDispatch,
} from '@marketmuse/data-state/hooks';
import { savedViewCurrentSet } from '@marketmuse/data-state/savedViews';
import { InventoryType, SortDirection } from '@marketmuse/config/types/papi';
import { usePlansAndProjectsQuery } from '@marketmuse/data-papi';

import LoadingBlock from '../../../components/LoadingBlock';
import IconSvg from '../../../components/IconSvg';
import SimpleTextInput from '../../../components/SimpleTextInput/SimpleTextInput';

import * as miscActions from '../../../actions/miscActions';
import styles from '../../../styles/variables';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';
import isPageActive from '../../../utils/isPageActive';
import { formatPlural } from '../../../utils/pluralRules';

import SidebarItem from '../components/SidebarItem';
import DeletePlan from '../components/DeletePlan';
import PlanListItem from './PlanListItem';

const PlansListWrapper = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 428px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: auto;
`;

const PlanningSidebarScreen = ({
  activeScreen,
  collapsed,
  historyPathname,
  screenId,
  setActiveScreen,
  isAdmin,
  ...props
}) => {
  const [filterText, setFilterText] = useState('');
  const [planToDelete, setPlanToDelete] = useState(null);
  const navigate = useSuiteNavigate();
  const dispatch = useStoreDispatch();
  const site = useStoreSelector(state => state?.filter?.site);
  const siteVersions = useStoreSelector(state => state?.filter?.versions);
  const currentTopicsSavedView = useStoreSelector(
    state => state.savedViews.TOPICS.current,
  );
  const planStatistics = useStoreSelector(
    state => state.loadings.GET_PLANS_STATS,
  );
  const planFetch = useStoreSelector(state => state.loadings.PLANS_FETCH);

  const { loading, data, refetch } = usePlansAndProjectsQuery({
    skip: isEmpty(site?.id),
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 200,
      filters: [
        {
          site: [
            {
              id: site?.id,
            },
          ],
        },
      ],
    },
  });

  const activePlanId = useMemo(() => {
    const lastUrlParam = window?.location?.pathname?.split('/')?.pop();
    return lastUrlParam === 'planning' ? null : lastUrlParam;
  }, [window?.location?.pathname]);

  const plansList = useMemo(() => {
    const plansVisible = data?.plans || [];
    if (!filterText) {
      return plansVisible;
    }

    return plansVisible.filter(({ title }) => {
      return title.toLowerCase().indexOf(filterText.toLowerCase()) !== -1;
    });
  }, [filterText, data]);

  useEffect(() => {
    if (planStatistics === false || planFetch === false) {
      refetch();
    }
  }, [planStatistics]);

  const displayTopicByPlansLink = checkSiteViableInventory(site, siteVersions);

  const onViewPlanTerms = ({ title, terms }) => {
    const temporaryUser = {
      id: 'no-reply@marketmuse.com',
      email: 'no-reply@marketmuse.com',
      fullName: 'MarketMuse',
    };
    const savedViewTitle = `Topics in ${title}`;

    dispatch(
      savedViewCurrentSet({
        savedView: {
          ...currentTopicsSavedView,
          createdBy: temporaryUser,
          updatedBy: temporaryUser,
          id: 'unsaved-temporary-view',
          title: savedViewTitle,
          description: savedViewTitle,
          inventoryType: InventoryType.TOPICS,
          sorting: {
            field: 'term',
            direction: SortDirection.DESC,
            table: 'topics',
          },
          sharing: 'PUBLIC',
          filters: [
            {
              field: 'topics.term',
              qualifier: 'and',
              operand: 'find_in_set',
              value: terms,
            },
          ],
        },
      }),
    );

    navigate('inventory/topics');
  };

  return (
    <>
      {planToDelete && (
        <DeletePlan
          planToDelete={planToDelete}
          onCancel={() => {
            setPlanToDelete(null);
          }}
          onDeleteComplete={() => {
            setPlanToDelete(null);
            refetch();
          }}
        />
      )}
      <SidebarItem
        header
        paddingLR
        icon={<IconSvg name="arrow-left" size="16" />}
        title="Back to Main Menu"
        collapsed={collapsed}
        onClick={() => setActiveScreen('main')}
      />
      <SidebarItem
        header
        paddingLR
        icon={<IconSvg name="plans" size="16" box={20} />}
        color="grey05"
        title="Plans"
        collapsed={collapsed}
      />
      <SidebarItem
        paddingLR
        collapsed={collapsed}
        shouldFitContent
        title="All Plans"
        active={isPageActive('inventory/planning', true)}
        onClick={() => navigate('inventory/planning')}
      />

      {/* search */}
      {collapsed && (
        <SidebarItem
          subheader
          paddingLR
          title="Search Plans"
          icon={<IconSvg name="search" size="16" />}
          collapsed={collapsed}
          onClick={() => dispatch(miscActions.sidebarToggle())}
        />
      )}
      {!collapsed && (
        <>
          <SimpleTextInput
            inputTheme="dark"
            backgroundColor={styles.colors.black}
            style={{ margin: '12px 20px' }}
            placeholder="Search Plans..."
            itemsLeft={[<IconSvg name="search" size="16" />]}
            value={filterText}
            onChange={e => setFilterText(e.target.value)}
            data-mms--plans-search="selected"
          />
          {!!plansList?.length && (
            <SidebarItem
              subheader
              paddingLR
              title={`${plansList?.length} ${formatPlural(
                'result',
                plansList?.length,
              )}`}
              collapsed={collapsed}
            />
          )}
          <PlansListWrapper data-mms--plans-list="clicked" className="mm-tw">
            {loading &&
              new Array(4)
                .fill(0)
                .map((_, i) => (
                  <LoadingBlock
                    loadingTheme="dark"
                    key={i}
                    mb={12}
                    mr={12}
                    ml={12}
                    width="calc(100% - 24px)"
                  />
                ))}
            {!loading &&
              plansList.map(plan => (
                <PlanListItem
                  key={plan.id}
                  displayTopicByPlansLink={displayTopicByPlansLink}
                  isActive={activePlanId === plan.id}
                  onClick={planId => navigate(`inventory/planning/${planId}`)}
                  onSetPlanDelete={setPlanToDelete}
                  onViewPlanTerms={onViewPlanTerms}
                  plan={plan}
                />
              ))}
          </PlansListWrapper>
        </>
      )}
    </>
  );
};

PlanningSidebarScreen.propTypes = {
  isAdmin: PropTypes.bool,
  collapsed: PropTypes.bool,
  labelLoading: PropTypes.bool,
  historyPathname: PropTypes.string,
};

export default memo(PlanningSidebarScreen);
