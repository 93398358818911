import type { ClientOptions } from '@sentry/types';
import * as Sentry from '@sentry/react';

import { BrowserTracing } from '@sentry/tracing';
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations';
// import SentryFullStory from '@sentry/fullstory';

import { REACT_APP } from '../config/env';
import {
  ignoreErrors,
  denyUrls,
  beforeSend,
  beforeBreadcrumb,
} from '../config/sentryConfig';

export default () => {
  const options: ClientOptions = {
    environment: REACT_APP.SENTRY_ENVIRONMENT || 'local',
    dsn: REACT_APP.SENTRY_DSN,
    // don't create new release for local
    ...(!process.env.REACT_APP_DEPLOY_PREVIEW && {
      release: [REACT_APP.SENTRY_ENVIRONMENT, process.env.REACT_APP_COMMIT_REF]
        .filter(Boolean)
        .join('-'),
    }),
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    integrations: [
      new BrowserTracing(),
      // new SentryFullStory(REACT_APP.FULLSTORY_ORG),
      new ExtraErrorDataIntegration({ depth: 5 }),
    ],
    normalizeDepth: 7,
    tracesSampleRate: parseInt(REACT_APP.SENTRY_SAMPLING || 0),
    beforeSend,
    beforeBreadcrumb,
    ignoreErrors: ignoreErrors,
    denyUrls: denyUrls,
  };

  if (!process.env.REACT_APP_DEPLOY_PREVIEW) {
    Sentry.init(options);
  }
};
