import React from 'react';
import PropTypes from 'prop-types';

import { TBody } from './styleTB';

const TBodyComponent = ({ children, style }) => (
  <TBody style={style} data-mms--datatable-body>
    {children}
  </TBody>
);

TBodyComponent.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
};

export default TBodyComponent;
