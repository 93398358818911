import { SiteLevel } from '@marketmuse/config/types/papi';

type SiteConfig = {
  id: SiteLevel;
  title: string;
  configs: Record<string, number | string>;
};

type SitesConfig = Record<SiteLevel, SiteConfig>;

export const configs: SitesConfig = {
  [SiteLevel.APPSUMO]: {
    id: SiteLevel.APPSUMO,
    title: 'AppSumo',
    configs: {
      APP_SUMO_USER_SUBSCRIPTIONS_LIMIT: 4,
    },
  },
  [SiteLevel.PREMIUM]: {
    id: SiteLevel.PREMIUM,
    title: 'Premium',
    configs: {},
  },
  [SiteLevel.CLASSIC]: {
    id: SiteLevel.CLASSIC,
    title: 'Classic',
    configs: {},
  },
  [SiteLevel.STANDARD]: {
    id: SiteLevel.STANDARD,
    title: 'Standard',
    configs: {},
  },
  [SiteLevel.FREE]: {
    id: SiteLevel.FREE,
    title: 'Free',
    configs: {},
  },
  [SiteLevel.NONE]: {
    id: SiteLevel.NONE,
    title: 'None',
    configs: {},
  },
};
