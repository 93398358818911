import React from 'react';
import styled from 'styled-components';

import BackgroundRadials from '../../../assets/background-radials.svg';

import Button from '../../../components/Button';

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  overflow: hidden;
  max-width: 100%;
  margin: 0;
  padding: ${p => p.theme.spacing.xLarge} ${p => p.theme.spacing.xxLarge};
  background-color: ${p => p.theme.colors.black};

  text-align: center;

  h3 {
    ${p => p.theme.type.heading2};
    max-width: 660px;
    font-size: 42px;
    font-weight: black;
    color: ${p => p.theme.colors.white};
    margin-top: 0;
    margin-bottom: ${p => p.theme.spacing.xLarge};
  }
`;

const Banner = ({ onClick }) => (
  <Wrapper>
    <Image src={BackgroundRadials} alt="" />
    <h3>Unlock full content potential for your whole team.</h3>

    <Button xLarge primary onClick={onClick}>
      Upgrade your account
    </Button>
  </Wrapper>
);

export default Banner;
