import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import PriceCard from '../../../../components/PriceCard';
import Clicker from '../../../../components/Clicker';
import CheckoutSection from '../../../../components/CheckoutSection';

import * as types from '../../../../config/types';
import { purchaseCredits } from '../../../../actions/creditActions';

import { Title, TitleWrapper, Available } from '../../shared';
import SimpleGrid from '../../../../components/SimpleGrid';
import { formatPlural } from '../../../../utils/pluralRules';
import { formatCurrency } from '../../../../utils/formatNumbers';

const PRICING_OPTIONS = [{ value: 1 }, { value: 5 }, { value: 10 }];
const PRICES = {
  1: { currencyCode: 'USD', currency: '$', price: 25 },
  2: { currencyCode: 'USD', currency: '$', price: 47.5 },
  3: { currencyCode: 'USD', currency: '$', price: 70 },
  4: { currencyCode: 'USD', currency: '$', price: 92.5 },
  5: { currencyCode: 'USD', currency: '$', price: 115 },
  6: { currencyCode: 'USD', currency: '$', price: 137.5 },
  7: { currencyCode: 'USD', currency: '$', price: 160 },
  8: { currencyCode: 'USD', currency: '$', price: 182.5 },
  9: { currencyCode: 'USD', currency: '$', price: 205 },
  10: { currencyCode: 'USD', currency: '$', price: 227.5 },
};

const CreditsPurchaseForm = ({ isDisabled }) => {
  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState({
    totalPrice: 0,
    totalPriceCur: '$',
    totalPriceFull: '$0',
    totalPriceSavings: 0,
  });

  const availableCredits =
    useSelector(state => state.filter?.site?.briefCredits) ?? 0;
  const isPurchasing = useSelector(
    state => state?.loadings?.[types.PURCHASE_CREDITS],
  );

  const calculateSaving = (ppc, number, price) => {
    const realPrice = ppc * number;
    return Math.round(((realPrice - price) / realPrice) * 10000) / 100;
  };

  useEffect(() => {
    const pricing = PRICES?.[total] ?? {};
    const price = {
      totalPrice: pricing.price,
      totalPriceCur: pricing.currency,
      totalPriceFull: formatCurrency(pricing.price),
      totalPriceSavings: calculateSaving(PRICES[1].price, total, pricing.price),
    };
    setPrice(price);
  }, [total]);

  return (
    <div>
      <TitleWrapper>
        <Title>Get Extra Credits</Title>
        <Available>{availableCredits} Available</Available>
      </TitleWrapper>

      {/* price cards */}
      <SimpleGrid.Container gap={'32px'} columns={6} padding="20px 0 0 0">
        {PRICING_OPTIONS.map(({ value }) => (
          <PriceCard
            key={value}
            icon="+"
            title={`${value} ${formatPlural('Credit', value)}`}
            price={formatCurrency(PRICES[value].price)}
            savings={
              value > 1
                ? `${calculateSaving(
                    PRICES[1].price,
                    value,
                    PRICES[value].price,
                  )}% saving`
                : null
            }
            onClick={() => setTotal(value)}
          />
        ))}
      </SimpleGrid.Container>
      {/* checkout section */}
      <CheckoutSection
        total={price.totalPrice ? price.totalPriceFull : null}
        totalNum={total}
        savings={
          price.totalPriceSavings > 0
            ? `${price.totalPriceSavings}% savings applied`
            : null
        }
        items={[
          <>
            <Clicker
              value={total}
              onIncrement={() => setTotal(total => total + 1)}
              onDecrement={() => setTotal(total => total - 1)}
              disableDecrement={total <= 0}
              disableIncrement={total >= 10}
              totalStyle={{ width: 32 }}
              style={{ marginRight: 8 }}
            />
            Credits
          </>,
        ]}
        submitAction={purchaseCredits}
        onSubmitCallback={() => setTotal(0)}
        type="credit"
        isPurchasing={isPurchasing}
        isDisabledSubmitButton={isPurchasing || isDisabled || !total}
      />
    </div>
  );
};

CreditsPurchaseForm.propTypes = {
  close: PropTypes.func,
  handleSettingsClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default memo(CreditsPurchaseForm);
