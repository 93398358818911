import React from 'react';
import styled from 'styled-components';

import Sidebar from '../Sidebar/Sidebar';
import LockedFeatureWarning from '../Payments/LockedFeatureWarning';
import Subscription from '../Payments/Subscription';
import AppModalsMiscState from './AppModalsMiscState';

const ContentWrapper = styled.div.attrs({ role: 'main' })`
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background-color: white;
`;

export const LayoutProtected = ({ children }) => {
  return (
    <>
      <Sidebar />
      <LockedFeatureWarning key="lockedFeatureWarningModal" />

      <Subscription />
      <AppModalsMiscState />
      <ContentWrapper>{children}</ContentWrapper>
    </>
  );
};
