import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';

import Text from '../../../../../components/Text';
import Dates from '../../../../../components/Dates';
import Box from '../../../../../components/Box';
import Button from '../../../../../components/Button';

import { toggleDynamic } from '../../../../../actions/miscActions';
import { formatCurrency } from '../../../../../utils/formatNumbers';
import { useSuiteNavigate } from '../../../../../hooks/useSuiteNavigate';

const SuccessScreen = ({ addAnotherUser, email, subscription }) => {
  const navigate = useSuiteNavigate();
  const dispatch = useDispatch();

  const amountInCents = get(
    subscription,
    'subscription.unit_amount_in_cents[0]._',
  );
  const cost = amountInCents ? formatCurrency(amountInCents / 100) : 'Money';
  const billingDate = get(
    subscription,
    'subscription.current_period_ends_at[0]._',
    new Date(),
  );

  const seeAllSubscriptions = () => {
    dispatch(
      toggleDynamic({
        id: 'paymentSettingsModal',
        status: false,
        data: null,
      }),
    );
    navigate('/settings/payment');
  };

  return (
    <>
      <Text textVariant="heading3" mb={32}>
        Success!
      </Text>
      <Text textVariant="largeText" mb={32} style={{ maxWidth: '550px' }}>
        An email has been sent to {email} with a link for them to activate their
        account. {cost} will be billed to your card now and on the{' '}
        <Dates end={billingDate} dateFormat="Do" /> of every month for their
        subscription.
      </Text>
      <Box
        boxVariant="flex"
        flexDirection="row"
        alignContent="start"
        justifyContent="start"
      >
        <Box boxVariant="flex" mr={32}>
          <Button primary xLarge onClick={addAnotherUser}>
            Add another user
          </Button>
        </Box>
        <Button secondary xLarge onClick={seeAllSubscriptions}>
          See all subscriptions
        </Button>
      </Box>
    </>
  );
};

SuccessScreen.propTypes = {
  addAnotherUser: PropTypes.func.isRequired,
  subscription: PropTypes.shape({}).isRequired,
  email: PropTypes.string.isRequired,
};

export default memo(SuccessScreen);
