import React, { useState, useMemo } from 'react';

import { SELECTION_COL_ID } from './shared';
import Radio from './../../Radio/Radio';

export default ({
  data,
  selectable,
  selectableBulk,
  onSelectionChange,
  columns,
  columnsPinned = {},
  columnsHidden = {},
  columnsOrder = [],
} = {}) => {
  const [selection, selectionSet] = useState({});

  const updatedSelection = useMemo(
    () =>
      Object.keys(selection).reduce((acc, key) => {
        return { ...acc, [key]: data.some(o2 => key === o2.key) };
      }, {}),
    [data, selection],
  );

  const someSelected = useMemo(
    () => Object.values(updatedSelection).some(i => i === true),
    [updatedSelection],
  );
  const allSelected = useMemo(
    () =>
      someSelected && Object.values(updatedSelection).every(i => i === true),
    [someSelected, updatedSelection],
  );

  return useMemo(() => {
    if (!selectable) {
      return {
        columns,
        columnsPinned,
        columnsOrder,
        selection,
      };
    }

    const selectionColumn = {
      id: SELECTION_COL_ID,
      width: 42,
      fixedWidth: true,
      disableLoading: true,
      header: ({ loading, ...p }) => ({
        center: {
          component: !selectableBulk ? null : (
            <Radio
              selected={allSelected}
              halfSelected={someSelected}
              onClick={() => {
                if (!data?.length) return;
                const next = allSelected
                  ? {}
                  : data?.reduce((acc, d) => ({ ...acc, [d.key]: true }), {});
                selectionSet(next);
                onSelectionChange(next);
              }}
            />
          ),
        },
      }),
      cell: ({ row }) => ({
        center: {
          component: (
            <Radio
              selected={selection[row.key]}
              onClick={() =>
                selectionSet(s => {
                  const newValue = !s[row.key];
                  const newSelection = { ...s, [row.key]: newValue };
                  if (!newValue) delete newSelection[row.key];
                  onSelectionChange(newSelection);
                  return newSelection;
                })
              }
            />
          ),
        },
      }),
    };

    const hasLeftSticky = Object.keys(columnsPinned)
      .filter(cid => !columnsHidden[cid])
      .some(i => columnsPinned[i] === 'left' || columnsPinned[i] === true);

    const columnsNew = [selectionColumn, ...columns];
    const columnsPinnedNew = {
      ...columnsPinned,
      [SELECTION_COL_ID]: hasLeftSticky ? 'left' : false,
    };
    const columnsOrderNew = [SELECTION_COL_ID, ...columnsOrder];

    return {
      columns: columnsNew,
      columnsPinned: columnsPinnedNew,
      columnsOrder: columnsOrderNew,
      selection,
    };
  }, [
    selection,
    data,
    selectable,
    selectableBulk,
    onSelectionChange,
    columns,
    columnsPinned,
    columnsHidden,
    columnsOrder,
    allSelected,
    someSelected,
  ]);
};
