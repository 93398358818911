import React from 'react';
import PropTypes from 'prop-types';
import { TRHead } from './styleTR';

const TRHeadComponent = ({
  children,
  headerGroupProps,
}) => {

  return (
    <TRHead {...headerGroupProps}>
      {children}
    </TRHead>
  )
};

TRHeadComponent.propTypes = {
  children: PropTypes.any,
  headerGroupProps: PropTypes.object,
};

export default TRHeadComponent;