import get from 'lodash/get';
import { PAPI } from '@marketmuse/config/types';
import { checkIsGhosting, checkIsAdminMode } from '@marketmuse/utilities';

export const isGhosting = (
  admin: Partial<PAPI.User>,
  user: Partial<PAPI.User>,
) => {
  const adminId = get(admin, 'id');
  const userId = get(user, 'id');

  return checkIsGhosting({ adminId, userId });
};

const isAdminMode = (admin: Partial<User>, user: Partial<User>) => {
  const adminId = get(admin, 'id');
  const adminOrgId = get(admin, 'org.id');
  const userId = get(user, 'id');

  return checkIsAdminMode({
    adminId,
    adminOrgId,
    userId,
  });
};

export default isAdminMode;
