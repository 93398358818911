import { SiteAggregatePlansDocument } from '@marketmuse/data-papi';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';
import { setAggregateStats } from '../../actions/siteActions';

export default () =>
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === types.GET_PLANS_STATS) {
      makeRequest({
        type: types.GET_PLANS_STATS,
        mutation: SiteAggregatePlansDocument,
        options: { isQuery: true },
        variables: {
          siteId: action.siteId,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(result => {
        const data = result?.siteAggregateStats || {};

        dispatch(setAggregateStats({ data }));
      });
    }

    return next(action);
  };
