import get from 'lodash/get';

import { GetPlansDocument } from '@marketmuse/data-papi';
import { plansActionTypes } from '@marketmuse/data-state/plans';

import makeRequest from '../../utils/makeRequest';
import { getPlanStats } from '../../actions/siteActions';
import { refreshPlans } from '../../actions/planActions';

export default () =>
  ({ getState, dispatch }) =>
  next =>
  action => {
    const state = getState();
    const siteId = action.siteId || get(state, 'filter.site.id');

    if (action.type === plansActionTypes.PLANS_FETCH && siteId) {
      makeRequest({
        type: plansActionTypes.PLANS_FETCH,
        mutation: GetPlansDocument,
        options: { isQuery: true },
        keys: [plansActionTypes.PLANS_FETCH],
        variables: {
          limit: 200,
          filters: [
            {
              site: [
                {
                  id: siteId,
                },
              ],
            },
          ],
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const plans = get(res, 'plans');

        dispatch(
          refreshPlans({
            plans,
            siteId,
          }),
        );

        if (!action.skipAggregateRefresh) {
          dispatch(getPlanStats({ siteId }));
        }

        if (typeof action.callback === 'function' && plans) {
          action.callback(plans);
        }
      });
    }

    return next(action);
  };
