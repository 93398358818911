const useSessionStorage = (key, defaultValue) => {
  function init() {
    if (typeof defaultValue !== 'undefined') {
      saveValueToSessionStorage(key, defaultValue);
    }
  }

  function remove() {
    if (typeof sessionStorage === 'undefined') {
      return null;
    }

    sessionStorage.removeItem(key);
  }

  function get(getKey = key) {
    return getSafe(getKey);
  }

  function getMany(getKeys) {
    return getKeys.reduce((acc, k) => ({ ...acc, [k]: getSafe(k) }), {});
  }

  function getSafe(getKey) {
    if (typeof sessionStorage === 'undefined') {
      return null;
    }
    const storedValue = sessionStorage.getItem(getKey) || 'null';
    try {
      return JSON.parse(storedValue);
    } catch (err) {
      console.error(err);
    }
    return storedValue;
  }

  function saveValueToSessionStorage(key, value) {
    if (typeof sessionStorage === 'undefined') {
      return null;
    }
    return sessionStorage.setItem(key, JSON.stringify(value));
  }
  
  init();

  return {
    set: newValue => saveValueToSessionStorage(key, newValue),
    get: getKey => get(getKey),
    getMany: keys => getMany(keys),
    remove,
  };
};

export default useSessionStorage;
