import get from 'lodash/get';
import { CancelAccountDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.CANCEL_ACCOUNT) {
      makeRequest({
        type: types.CANCEL_ACCOUNT,
        mutation: CancelAccountDocument,
        variables: {
          orgId: action.orgId || get(state, 'user.org.id'),
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        if (get(res, 'error') || get(res, 'errors')) {
          Toast.fire(
            res.error ||
              'Failed to cancel account, please contact MarketMuse support',
            'error',
          );
          if (typeof action.callbackFail === 'function') {
            action.callbackFail();
          }
        } else {
          if (typeof action.callback === 'function') {
            action.callback();
          }
        }
      });
    }

    return next(action);
  };
