import {
  InventoryVersion,
  VersionStatusEnum,
  SiteLevel,
  Site,
} from '@marketmuse/config/types/papi';

interface SiteInterface {
  invId?: Site['invId'];
  level: Site['level'];
  [x: string]: any;
}
interface VersionInterface {
  failed?: InventoryVersion['failed'];
  status?: InventoryVersion['status'];
  visible?: InventoryVersion['visible'];
  [x: string]: any;
}

export const checkSiteViableInventory = (
  site: SiteInterface,
  versions: VersionInterface[],
) => {
  const hasValidVersion = versions.find(
    version =>
      version?.failed === 0 &&
      version?.visible === 1 &&
      version?.status === VersionStatusEnum.FINISHED,
  );

  const hasValidSiteLevel = [SiteLevel.PREMIUM, SiteLevel.CLASSIC].includes(
    site.level,
  );

  if (site.invId && hasValidVersion && hasValidSiteLevel) {
    return true;
  }

  return false;
};
