/**
 * Things to record:
 * - ids
 */

import Event from '../../Event';

class PlanDeleted extends Event {

  record({ ids } = {}) {
    super.record('PlanDeleted', {
      category: 'Plans',
      mainValue: `Deleted plans ${ids}`,
      newName: 'inventory--plans--deleted',
      planIds: ids,
    });
  }

}

export default new PlanDeleted();
