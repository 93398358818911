import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Box } from '../../Box';

export interface IPropsLayout {
  className?: string | Array<string | string[]>;
  children: ReactNode;
}

const TableLayout = ({ className, children }: IPropsLayout) => {
  return (
    <Box
      className={twMerge([
        'flex',
        'flex-grow',
        'flex-col',
        'h-full',
        'min-w-full',
        'overflow-y-scroll',
        className,
      ])}
    >
      {children}
    </Box>
  );
};

export default TableLayout;
