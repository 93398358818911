/**
 * Things to record:
 * - report type
 * - report topic
 * - report url (for optimize)
 */

import Event from '../../Event';

class ReportCreated extends Event {
  record({ topic, reportType, url, org }) {
    super.record('ReportCreated', {
      category: 'Reports',
      mainValue: `${reportType} report on ${topic}`,
      newName: 'reports--report-created',
      topic,
      reportType,
      url,
      org,
    });
  }
}

export default new ReportCreated();
