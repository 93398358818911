import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { recurlyPlansInfo } from '@marketmuse/config/configs';
import * as types from '../../../../../config/types';
import Box from '../../../../../components/Box';
import AddUserFormHeader, {
  addUserFormHeaderPropType,
} from '../../components/AddUserFormHeader';
import CreditCardForm from '../../../SubscriptionUpdate/CreditCardForm';
import Toast from '../../../../../components/Toast';
import isPaymentTestModeActive from '../../../../../utils/isPaymentTestModeActive';
import { getUserData } from '../../../../../actions/authenticateActions';
import { createUserSubscription } from '../../../../../actions/subscriptionActions';

const BoxStyled = styled(Box).attrs({
  boxVariant: 'flex',
  width: '100%',
  flexFlow: 'column nowrap',
  alignSelf: 'flex-start',
  maxWidth: '500px',
})``;

const BillingInfoStep = ({
  isCheckingAccount,
  hasAccount,
  planName,
  setStep,
  setAvailableSteps,
  isDisabled,
  createUser,
  setSubscription,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(
    state => state.loadings[types.CREATE_USER_SUBSCRIPTION],
  );
  const user = useSelector(state => state.user);

  const handleSubmit = useCallback(
    recurlyToken => {
      if (isDisabled) {
        Toast.fire('Invalid user information', 'error');
        setStep('userInfoStep');
        setAvailableSteps(['userInfoStep']);
        return;
      }
      const codeMode = isPaymentTestModeActive() ? 'codeTest' : 'code';
      const planCode = recurlyPlansInfo?.[planName]?.monthly?.[codeMode];

      dispatch(
        createUserSubscription({
          planCode: planCode,
          user: createUser,
          callbackError: ({ error }) => {
            if (error === 'Forbidden plan') {
              dispatch(getUserData({ saveUserData: true }));
            }
          },
          callback: ({ sub }) => {
            setSubscription(sub);
            setStep('success');
          },
        }),
      );
    },
    [setStep, setAvailableSteps, planName, createUser, isDisabled, dispatch],
  );

  return (
    <>
      <AddUserFormHeader
        {...rest}
        isDisabled={isDisabled}
        planName={planName}
        isCheckingAccount={isCheckingAccount}
        hasAccount={hasAccount}
      />
      {!isCheckingAccount && hasAccount && (
        <BoxStyled>
          <CreditCardForm
            user={user}
            onSubmit={handleSubmit}
            ctaText="Create user"
            ctaLoading={isLoading}
          />
        </BoxStyled>
      )}
    </>
  );
};

BillingInfoStep.propTypes = {
  ...addUserFormHeaderPropType,
  setStep: PropTypes.func.isRequired,
  setAvailableSteps: PropTypes.func.isRequired,
  createUser: PropTypes.shape({}),
  setSubscription: PropTypes.func.isRequired,
};

export default memo(BillingInfoStep);
