import React from 'react';
import { get, isEmpty, isNil } from 'lodash';

import { SiteLevel } from '@marketmuse/config/types/papi';

import IconSvg from '../../components/IconSvg/IconSvg';
import IconAppStatus from '../../containers/Sidebar/components/IconAppStatus';

import * as types from '../../config/types';
import {
  getApplicationsConfig,
  APPLICATIONS_CONFIG_FLAT,
} from '../../config/getApplicationsConfig';

import isPageActive from '../isPageActive';
import hasAccess from '../hasAccess';
import isAppDataCached from '../applications/isAppDataCached';

export default (WrappedComponent, props) => {
  // keep id's in array to preserve order
  const apps = props.list.filter(Boolean);
  const appData = getApplicationsConfig();

  const appsOrdered = apps
    .map(appId => ({ ...appData[appId] }))
    .filter(app => (app.locked ? app.showWhenLocked : true))
    .map(app => {
      // 'allow' each application to be navigable
      // restriction enforced on route, and applications level to prevent gaming
      return {
        ...app,
        ...(props.level !== SiteLevel.APPSUMO ? { locked: false } : {}),
      };
    })
    .sort((a, b) => a.locked - b.locked);

  const renderAppBanner = appId => {
    if (!hasAccess(appId, props.level)) {
      return null;
    }
    const toolConfigKey = get(props, `apps['${appId}']`);
    const toolData = isNil(toolConfigKey)
      ? null
      : get(props, `apps['${toolConfigKey}'].data`, {});

    const toolDataCached =
      !isNil(toolData) &&
      !isEmpty(toolData) &&
      isAppDataCached(
        toolData,
        get(APPLICATIONS_CONFIG_FLAT, `['${appId}'].dataRequirements`),
      );

    const toolLoading =
      props.loadings[`${types.GET_APP_DATA}-${appId}`] ||
      props.loadings[`${types.GET_APP_DATA}-all`];

    if (toolLoading || toolDataCached) {
      return <IconAppStatus status={toolDataCached ? 'complete' : 'loading'} />;
    }
    return '';
  };

  return (
    <>
      {appsOrdered.map(app => {
        const iconSize = app?.iconSize ?? 28;
        return (
          <WrappedComponent
            key={app.id}
            collapsed={props.collapsed}
            commonProps={props}
            collapsedIconOnly={!!props.collapsedIconOnly}
            icon={
              <IconSvg
                name={app.iconId}
                size={iconSize}
                style={{ fontSize: iconSize }}
              />
            }
            title={app.title}
            dataTrack={`sidebar--apps--${app.title}`}
            active={isPageActive(app.path)}
            badge={renderAppBanner(app.id)}
            locked={app.locked}
            hidden={app.hidden}
            onClick={() =>
              app.locked && props.onLockedClick
                ? props.onLockedClick()
                : props.navigate(app.path)
            }
          />
        );
      })}
    </>
  );
};
