import React from 'react';
import cx from 'classnames';
import { ClerkProvider as ClerkProviderComponent } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';
import logoImageUrl from '../assets/logo-marketmuse-sparkles.png';
import { REACT_APP } from '../config/env';

const fontFamily = [
  'InterVariable',
  'Inter',
  'Roboto',
  'Arial',
  'sans-serif',
].join(', ');

const elements = {
  alternativeMethodsBlockButton: cx(['px-4', 'py-2']),
  buttonArrowIcon: cx(['hidden']),
  header: cx(['items-start', 'text-left']),
  headerBackIcon: cx(['mr-3']),
  headerTitle: cx(['text-2xl', 'font-bold', 'text-gray-900']),
  headerSubtitle: cx(['font-normal', 'text-base', 'mt-2', 'text-gray-900']),
  formButtonPrimary: cx([
    'bg-blue-400',
    'text-white',
    'font-bold',
    'min-h-[32px]',
    'focus:shadow-none',
    'before:bg-transparent',
  ]),
  formFieldErrorText: cx(['text-left']),
  formFieldInfoText: cx(['text-left']),
  formFieldSuccessText: cx(['text-left']),
  formFieldInputShowPasswordButton: cx(['p-1', 'mr-1']),
  formFieldAction: cx([
    'text-blue-400',
    'font-bold',
    'hover:text-blue-500',
    'no-underline',
  ]),
  footerActionLink: cx([
    'text-blue-400',
    'font-bold',
    'hover:text-blue-500',
    'no-underline',
  ]),
  logoBox: cx(['w-[52px]', 'h-[49px]']),
  logoImage: cx(['w-[52px]', 'h-[49px]']),
  otpCodeFieldInput: cx(['box-content', 'px-1']),
};

export function ClerkProvider({ children }) {
  const navigate = useNavigate();
  return (
    <ClerkProviderComponent
      publishableKey={REACT_APP.CLERK_KEY}
      routerPush={pathname => {
        navigate(pathname, { replace: false });
      }}
      routerReplace={(...args) => {
        navigate(pathname, { replace: true });
      }}
      localization={{
        signIn: {
          start: {
            title: 'Welcome to MarketMuse',
            subtitle: 'Log in to MarketMuse',
          },
        },
        signUp: {
          start: {
            title: 'Sign up',
            actionLink: 'Log in',
            subtitle:
              'Enter your email address and create a password to get started.',
          },
        },
      }}
      appearance={{
        layout: {
          showOptionalFields: false,
          logoImageUrl: logoImageUrl,
        },
        variables: {
          colorPrimary: '#359ff4',
          colorTextOnPrimaryBackground: '#fff',
          fontFamily,
          fontFamilyButtons: fontFamily,
        },
        elements,
      }}
    >
      {children}
    </ClerkProviderComponent>
  );
}
