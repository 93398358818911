import { isEmpty } from 'lodash';
import { stripUrl } from '@marketmuse/utilities';
import {
  StrictTypedTypePolicies,
  SiteLevel,
  Invoice,
} from '@marketmuse/config/types/papi';
import { accountPrices } from './reactiveVars';

const storeDerivedPrice = (chargedInvoice?: Invoice, accountCode?: string) => {
  if (!accountCode) {
    return;
  }
  const amount = chargedInvoice?.lineItems
    ?.filter(lineItem => {
      return lineItem.origin === 'plan';
    })
    .reduce((acc, lineItem) => acc + lineItem.amount, 0);

  accountPrices({
    ...accountPrices(),
    [accountCode]: amount ? amount * 100 : null,
  });
};

const subscriptionRenewalInvoicePreviewPolicy: StrictTypedTypePolicies['Query']['fields']['subscriptionRenewalInvoicePreview'] =
  {
    merge(_, incoming, { readField }) {
      const accountCode = readField(
        'code',
        incoming?.chargeInvoice?.account,
      ) as string;

      if (incoming) {
        storeDerivedPrice(incoming?.chargeInvoice, accountCode);
      }
      return incoming;
    },
  };

const urlNormalizationPolicy = {
  fields: {
    url: {
      merge(existing, incoming) {
        if (!isEmpty(incoming)) {
          return stripUrl(incoming);
        }
        if (!isEmpty(existing)) {
          return stripUrl(existing);
        }
        return existing;
      },
      read(existing) {
        if (!isEmpty(existing)) {
          return stripUrl(existing);
        }
        return existing;
      },
    },
  },
};

const sitePolicy: StrictTypedTypePolicies['Site'] = {
  fields: {
    level: {
      merge(existing, incoming) {
        if (!isEmpty(incoming)) {
          return incoming;
        }
        if (!isEmpty(existing)) {
          return existing;
        }

        return existing || SiteLevel.NONE;
      },
      read(existing) {
        if (isEmpty(existing)) {
          return SiteLevel.NONE;
        }
        return existing;
      },
    },
  },
};

const typePolicies: StrictTypedTypePolicies = {
  OrganicSerpItem: urlNormalizationPolicy,
  OrganicSerpItemPageKgData: urlNormalizationPolicy,
  OrganicSerpItemPage: urlNormalizationPolicy,
  OrganicSerpItemWithIntent: urlNormalizationPolicy,
  UserProvidedPage: urlNormalizationPolicy,
  WebsiteSerpItemPagesKgData: urlNormalizationPolicy,
  Site: sitePolicy,
  Query: {
    fields: {
      subscriptionRenewalInvoicePreview:
        subscriptionRenewalInvoicePreviewPolicy,
    },
  },
};

export default typePolicies;
