import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  PermissionResource,
  PermissionAction,
} from '@marketmuse/config/types/papi';

import { PAPI } from '@marketmuse/config/types';
import { Icon, SUPPORTED_ICON } from '@marketmuse/components';

import withTailwindCompatibility from '../../../hocs/withTailwindCompatibility';
import IconSvg from '../../../components/IconSvg';
import Popper from '../../../components/Tooltip/Popper';

import { toggleDynamic } from '../../../actions/miscActions';

import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';
import useHasAccess from '../../../hooks/useHasAccess';

import sidebarAppsWrapper from '../../../utils/wrappers/sidebarAppsWrapper';
import isPageActive from '../../../utils/isPageActive';

import {
  Separator,
  SidebarButton,
  SidebarPaddingLR,
} from '../components/shared';
import QuickStartLinks from '../components/QuickStartLinks';
import SidebarItem from '../components/SidebarItem';
import { AppResearchStatus } from '../components/AppResearchStatus';
import IconAppStatus from '../components/IconAppStatus';

const PATHS = {
  projects: {
    route: 'inventory/projects',
  },
  planning: {
    route: 'inventory/planning',
  },
  writing: {
    route: 'inventory/content',
  },
  briefing: {
    route: 'inventory/briefs',
  },
};

const IconWrapped = withTailwindCompatibility({
  Component: Icon,
  style: { height: '100%' },
});

export const abbreviateNumber = value => {
  if (value === null || value === 0) {
    return void 0;
  }
  const b = value.toPrecision(2).split('e');
  const k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 9) / 3);
  const c = k < 1 ? value.toFixed() : (value / Math.pow(10, k * 3)).toFixed(1);
  const d = c < 0 ? c : Math.abs(c);
  const e = d + ['', 'K', 'M'][k];
  return e;
};

const MainSidebarScreen = ({
  collapsed,
  isAdmin,
  setActiveScreen,
  canOrderItems,
  invAllAccess,
}) => {
  const navigate = useSuiteNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const apps = useSelector(state => state.apps);
  const loadings = useSelector(state => state.loadings);
  const level = useSelector(state => state.filter?.site?.level);
  const showUpgrade = useSelector(
    state =>
      state.payment?.subscription?.subscriptionPlan?.userCanUpgradePackage,
  );
  const hasDocumentsAccess = useHasAccess({
    resource: PermissionResource.DOCUMENTS,
    action: PermissionAction.ACCESS_DATA,
  });
  const hasProjectsAccess = useHasAccess({
    resource: PermissionResource.PROJECTS,
    action: PermissionAction.ACCESS_DATA,
  });
  const pageTopicsCount =
    useSelector(state => state.misc?.counts?.pageTopicsAll) ?? 0;
  const optimizeStatus = useSelector(state => state?.appOptimize?.ui?.status);

  const topicsCount = useSelector(state => state.misc?.counts?.topicsAll) ?? 0;
  const pagesCount = useSelector(state => state.misc?.counts?.pagesAll) ?? 0;
  const planningCount = useSelector(state => state.misc?.counts?.plansAll) ?? 0;
  const projectsCount =
    useSelector(state => state.misc?.counts?.projectsAll) ?? 0;
  const briefingCountAggr =
    useSelector(state => state.misc?.counts?.briefsAll) ?? 0;
  const writingCount =
    useSelector(state => state.misc?.counts?.projectsWithWriting) ?? 0;
  const isPowerUpsPageActive = useSelector(
    state => state.misc?.POWER_UP_PAGE_ACTIVE,
  );

  const navigateTo = (screen, currentScreen) => {
    if (!isPageActive(PATHS[screen].route)) {
      navigate(PATHS[screen].route);
    }

    if (!currentScreen) {
      setActiveScreen(screen);
    }
  };

  const {
    isAdminActive,
    isDocumentsActive,
    isPTInventoryActive,
    isPInventoryActive,
    isTInventoryActive,
    isProjectsActive,
    isPlanningActive,
    isBriefingActive,
    isWritingActive,
    isResearchActive,
    isOptimizeActive,
    isTagsActive,
  } = useMemo(
    () => ({
      isAdminActive: isPageActive('/admin'),
      isDocumentsActive: isPageActive('/documents'),
      isPTInventoryActive: isPageActive('/inventory/all'),
      isPInventoryActive: isPageActive('/inventory/pages'),
      isTInventoryActive: isPageActive('/inventory/topics'),
      isProjectsActive: isPageActive('/inventory/projects'),
      isPlanningActive: isPageActive('/inventory/planning'),
      isBriefingActive: isPageActive('/inventory/briefs'),
      isWritingActive: isPageActive('/inventory/content'),
      isResearchActive: isPageActive('/apps/research'),
      isOptimizeActive: isPageActive('/apps/optimize'),
      isTagsActive: isPageActive('/tags'),
    }),
    [pathname],
  );

  return (
    <>
      <SidebarPaddingLR>
        {isAdmin && (
          <SidebarItem
            header
            title="Admin"
            active={isAdminActive}
            collapsed={collapsed}
            onClick={() => {
              setActiveScreen('admin');
            }}
            badge={<IconSvg name="arrow-right" size="16" />}
          />
        )}

        {isAdmin && <Separator />}

        <QuickStartLinks collapsed={collapsed} />

        {invAllAccess && (
          <>
            <SidebarItem subheader title="Inventory" collapsed={collapsed} />
            <SidebarItem
              title="All"
              color="grey35"
              icon={<IconSvg name="inventory" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--inventory--page-topics"
              active={isPTInventoryActive}
              onClick={() => navigate('inventory/all')}
              label={abbreviateNumber(pageTopicsCount)}
              labelOrder={3}
            />
            <SidebarItem
              title="Pages"
              color="grey35"
              icon={<IconSvg name="pages" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--inventory--pages"
              active={isPInventoryActive}
              onClick={() => navigate('inventory/pages')}
              label={abbreviateNumber(pagesCount)}
              labelOrder={3}
            />
            <SidebarItem
              title="Topics"
              color="grey35"
              icon={<IconSvg name="topics" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--inventory--topics"
              active={isTInventoryActive}
              onClick={() => navigate('inventory/topics')}
              label={abbreviateNumber(topicsCount)}
              labelOrder={3}
            />
            <Separator />
          </>
        )}

        {hasDocumentsAccess && (
          <>
            <Popper
              trigger="mouseenter focus"
              tippyTheme="dark"
              placement={'right-end'}
              html={
                <SidebarItem
                  title="All Documents in Organization"
                  color="grey35"
                  icon={<IconWrapped name={SUPPORTED_ICON.documents} />}
                  collapsed={collapsed}
                  dataTrack="sidebar--documents-org"
                  onClick={() => navigate('documents', { omitSiteId: true })}
                  active={false}
                  labelOrder={3}
                />
              }
            >
              <SidebarItem
                title="Documents"
                color="grey35"
                icon={<IconWrapped name={SUPPORTED_ICON.documents} />}
                collapsed={collapsed}
                dataTrack="sidebar--documents-site"
                active={isDocumentsActive}
                onClick={() => navigate('documents')}
                labelOrder={3}
              />
            </Popper>

            <SidebarItem
              title="Tags"
              color="grey35"
              icon={<IconWrapped name={SUPPORTED_ICON.plans} />}
              collapsed={collapsed}
              dataTrack="sidebar--tags"
              active={isTagsActive}
              onClick={() => navigate('tags')}
              labelOrder={3}
            />
            <Separator />
          </>
        )}

        {hasProjectsAccess && (
          <>
            <SidebarItem subheader title="Projects" collapsed={collapsed} />

            <SidebarItem
              title="All Projects"
              color="grey35"
              icon={<IconSvg name="inventory" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--projects-all"
              active={isProjectsActive}
              onClick={() => navigateTo('projects', true)}
              label={abbreviateNumber(projectsCount)}
              labelOrder={3}
            />

            <SidebarItem
              title="Plans"
              color="grey35"
              icon={<IconSvg name="plans" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--planning"
              active={isPlanningActive}
              onClick={() => navigateTo('planning')}
              label={abbreviateNumber(planningCount)}
              labelOrder={3}
              badge={<IconSvg name="arrow-right" size="16" />}
            />

            {canOrderItems && (
              <>
                <SidebarItem
                  title="Briefs"
                  color="grey35"
                  icon={<IconSvg name="briefs" size="16" />}
                  collapsed={collapsed}
                  dataTrack="sidebar--briefing"
                  active={isBriefingActive}
                  onClick={() => navigateTo('briefing')}
                  label={abbreviateNumber(briefingCountAggr)}
                  labelOrder={3}
                  badge={<IconSvg name="arrow-right" size="16" />}
                />
              </>
            )}
            <SidebarItem
              title="Saved Writing"
              color="grey35"
              icon={<IconSvg name="writing" size="16" />}
              collapsed={collapsed}
              dataTrack="sidebar--writing"
              active={isWritingActive}
              onClick={() => navigateTo('writing', true)}
              label={abbreviateNumber(writingCount)}
              labelOrder={3}
            />
            <Separator />
          </>
        )}

        {/* APPLICATIONS */}
        <SidebarItem subheader title="Applications" collapsed={collapsed} />

        <SidebarItem
          title="Research"
          titleStyle={{ whiteSpace: 'nowrap' }}
          active={isResearchActive}
          icon={<IconSvg name="research" size="28" box="16" />}
          collapsed={collapsed}
          dataTrack={`sidebar--apps--research`}
          onClick={() => navigate('apps/research/topic-navigator/topic-model')}
          badge={<AppResearchStatus />}
        />
        <SidebarItem
          title="Optimize"
          titleStyle={{ whiteSpace: 'nowrap' }}
          active={isOptimizeActive}
          icon={<IconSvg name="optimize" size="16" box="16" />}
          collapsed={collapsed}
          dataTrack={`sidebar--apps--optimize`}
          onClick={() => navigate('apps/optimize')}
          badge={<IconAppStatus status={optimizeStatus} />}
        />

        {sidebarAppsWrapper(SidebarItem, {
          list: [PAPI.PermissionResource.NEWSROOM],
          apps,
          collapsed,
          isAdmin,
          level,
          loadings,
          collapsedIconOnly: false,
          navigate,
        })}
      </SidebarPaddingLR>
      <SidebarPaddingLR>
        <Separator />
        <SidebarItem
          title="Power-ups"
          color="grey35"
          activeColor="white"
          icon={
            <IconSvg
              name="mm-sparkles"
              size="16"
              color={isPowerUpsPageActive ? 'yellowBase' : 'yellowDark1'}
            />
          }
          collapsed={collapsed}
          dataTrack="sidebar--power-ups-page"
          active={isPowerUpsPageActive}
          onClick={() => navigate('power-ups')}
        />
        {showUpgrade && !isAdmin && !collapsed && (
          <SidebarButton
            admin={isAdmin}
            style={{
              marginTop: 10,
              backgroundColor: '#359ff4',
              marginBottom: '12px',
            }}
            onClick={() =>
              dispatch(
                toggleDynamic({
                  id: 'subscriptionManagement',
                  status: true,
                  data: { initialScreen: 'plans' },
                }),
              )
            }
            data-mms--plan-management="upgradeNow"
          >
            Upgrade Now!
          </SidebarButton>
        )}
      </SidebarPaddingLR>
    </>
  );
};

MainSidebarScreen.propTypes = {
  isAdmin: PropTypes.bool,
  collapsed: PropTypes.bool,
  labelLoading: PropTypes.bool,
  inTransition: PropTypes.bool,
};

export default memo(MainSidebarScreen);
