import get from 'lodash/get';
import { PurchaseAppRunsDocument as PURCHASE_APP_RUNS } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';
import { updateCredits, setAggregateStats } from '../../actions/siteActions';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.PURCHASE_APP_RUNS) {
      Toast.fire("We're Updating your Account.");
      makeRequest({
        type: types.PURCHASE_APP_RUNS,
        mutation: PURCHASE_APP_RUNS,
        variables: {
          siteId: get(state, 'filter.site.id'),
          num: action.num,
        },
      }).then(res => {
        if (res.error) {
          Toast.fire(res.error || 'Unable to complete purchase', 'error');
        } else {
          Toast.fire('Thank you for your purchase', 'success');
          const newRuns = get(res, 'purchaseAppRuns', []);
          const briefCreditsData = get(newRuns, 'briefCreditsData');

          // update brief credits
          dispatch(
            updateCredits({
              briefCredits: get(briefCreditsData, 'briefCredits'),
              siteId: get(state, 'filter.site.id'),
            }),
          );

          // update app runs
          dispatch(
            setAggregateStats({
              data: { appRuns: newRuns },
            }),
          );

          if (typeof action.callback === 'function') {
            action.callback(newRuns);
          }
        }
      });
    }

    return next(action);
  };
