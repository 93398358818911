import { SiteAggregateBriefsDocument } from '@marketmuse/data-papi';
import { setAggregateStats } from '../../actions/siteActions';
import * as types from '../../config/types';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.GET_BRIEF_STATS) {
      makeRequest({
        type: types.GET_BRIEF_STATS,
        mutation: SiteAggregateBriefsDocument,
        variables: {
          siteId: state?.filter?.site?.id,
        },
        options: {
          isQuery: true,
        },
      }).then(res => {
        const data = res?.siteAggregateStats ?? {};
        dispatch(setAggregateStats({ data }));
      });
    }

    return next(action);
  };
