import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import set from 'lodash/set';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from '@marketmuse/react-beautiful-dnd';

import Cell, { insertSide } from './Cell';
import IconSvg from './../../IconSvg/IconSvg';
import HeaderButton from './HeaderButton';
import Toggle from './../../Radio/Toggle';

const PINNED_LEFT = 'PINNED_LEFT';
const PINNED_RIGHT = 'PINNED_RIGHT';
const UNPINNED = 'UNPINNED';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px 0;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  width: max-content;
`;

const HeaderButtonStyled = styled(HeaderButton)`
  /** width won't work on some icons for some reason */
  svg {
    transform: scale(0.75);
  }
`;

const ToggleStyled = styled(Toggle)`
  transform: scale(0.85);
`;

const HeaderItemWrapper = styled.div`
  user-select: none;
  color: ${p => p.theme.mmxBlack};
  &:hover {
    background-color: ${p => p.theme.colors.grey05};
  }
  ${p =>
    !p.isVisible &&
    `
    color: ${p.theme.colors.grey50};
  `}
`;

const Zones = styled.div``;

const HeaderItem = ({
  type,
  column,
  columnIndex,
  reorder,
  pin,
  hide,
  onColumnPinned,
  onColumnHidden,
  columnsComputed,
}) => {
  const onColumnHiddenFn = useCallback(
    column => {
      if (typeof onColumnHidden === 'function') onColumnHidden(column);
      if (typeof column.onHide === 'function') column.onHide();
    },
    [onColumnHidden],
  );

  const onColumnPinnedFn = useCallback(() => {
    if (typeof onColumnPinned === 'function') onColumnPinned(column);
    if (typeof column.onPin === 'function') column.onPin();
  }, [onColumnPinned]);

  return (
    <Draggable
      type={type}
      key={column.id}
      draggableId={column.id}
      index={columnIndex}
      isDragDisabled={!reorder}
    >
      {(dragProvided, dragSnapshot) => {
        const { header } = column;

        // get provided layout
        let headerParsed =
          typeof header === 'function'
            ? header({ hideSortingIcons: true })
            : header;

        // if no layout is provided, provide one
        if (typeof headerParsed !== 'object') {
          headerParsed = { center: { component: headerParsed } };
        }

        // add pin button
        if (pin) {
          headerParsed = insertSide(headerParsed, 'right', {
            component: (
              <HeaderButtonStyled
                active={!!columnsComputed[column.id].pinned}
                onClick={() => onColumnPinnedFn(column)}
                data-mms--datatable-configurations="pin"
              >
                <IconSvg size={16} name="pin" />
              </HeaderButtonStyled>
            ),
          });
        }

        // add hide button
        if (hide) {
          headerParsed = insertSide(headerParsed, 'right', {
            component: (
              <ToggleStyled
                active={!columnsComputed[column.id].hidden}
                onClick={() => onColumnHiddenFn(column)}
              />
            ),
          });
        }

        // render drag icon
        if (reorder) {
          headerParsed = insertSide(headerParsed, 'left', {
            style: { marginRight: 8 },
            component: (
              <HeaderButtonStyled
                style={{ pointerEvents: 'none' }}
                data-mms--datatable-configurations="dragger"
              >
                <IconSvg size={12} name="drag" />
              </HeaderButtonStyled>
            ),
          });
        }

        // style adjustment - column align left
        set(headerParsed, 'center.align', 'left');

        return (
          <HeaderItemWrapper
            ref={dragProvided.innerRef}
            {...dragProvided.draggableProps}
            {...(reorder ? dragProvided.dragHandleProps : {})}
            style={dragProvided.draggableProps.style}
            isVisible={!columnsComputed[column.id].hidden}
          >
            <Cell layout={headerParsed} />
          </HeaderItemWrapper>
        );
      }}
    </Draggable>
  );
};

const HeaderItems = ({ type, columns, droppableProps, columnItemProps }) => {
  return (
    <Droppable type={type} droppableId={type} {...droppableProps}>
      {dropProvided => (
        <div ref={dropProvided.innerRef} {...dropProvided.droppableProps}>
          {columns.map((column, columnIndex) => (
            <HeaderItem
              key={column?.id}
              type={type}
              column={column}
              columnIndex={columnIndex}
              lastColumn={columnIndex === columns.length - 1}
              {...columnItemProps}
            />
          ))}
          {dropProvided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const ColumnSettings = ({
  style,
  columns,
  columnsComputed,
  hide,
  hideFromSettings,
  pin,
  reorder,
  onColumnHidden,
  onColumnPinned,
  onColumnReorder,
}) => {
  const columnItemProps = {
    reorder,
    hide,
    pin,
    onColumnHidden,
    onColumnPinned,
    columnsComputed,
  };

  const droppableProps = {
    isDragDisabled: !reorder,
    isDropDisabled: !reorder,
  };

  const columnItemsProps = {
    columnItemProps,
    droppableProps,
  };

  const columnsSorted = columns.sort(
    (a, b) => columnsComputed[a.id].flexOrder - columnsComputed[b.id].flexOrder,
  );
  const columnsSortedFiltered = columnsSorted.filter(
    c => !hideFromSettings.includes(c.id),
  );

  const columnLists = useMemo(
    () => ({
      [PINNED_LEFT]: columnsSortedFiltered.filter(
        c => columnsComputed[c.id].pinnedLeft,
      ),
      [UNPINNED]: columnsSortedFiltered.filter(
        c => !columnsComputed[c.id].pinned,
      ),
      [PINNED_RIGHT]: columnsSortedFiltered.filter(
        c => columnsComputed[c.id].pinnedRight,
      ),
    }),
    [columnsSortedFiltered, columnsComputed],
  );

  return (
    <Wrapper style={style}>
      <DragDropContext
        isDragDisabled={!reorder}
        onDragEnd={({ source, destination, type } = {}) => {
          // invalid drop location
          if (!destination) return;

          // if the columns have not been ordered before
          let newOrder = columnsSorted.map(c => c.id).slice();
          if (newOrder.length === 0) newOrder = columns.map(c => c.id);

          // indexes provided by the library are based
          // on the filtered list - find the real index
          // of items in the unfiltered list
          const sourceItem = columnLists[type][source.index];
          const destinationItem = columnLists[type][destination.index];
          const sourceIndex = newOrder.findIndex(h => h === sourceItem.id);
          const destinationIndex = newOrder.findIndex(
            h => h === destinationItem.id,
          );

          // insert item to its new index
          const [removed] = newOrder.splice(sourceIndex, 1);
          newOrder.splice(destinationIndex, 0, removed);

          // callback
          if (typeof onColumnReorder === 'function') {
            onColumnReorder(newOrder);
          }
        }}
      >
        <Zones>
          <HeaderItems
            type={PINNED_LEFT}
            columns={columnLists[PINNED_LEFT]}
            {...columnItemsProps}
          />
          <HeaderItems
            type={UNPINNED}
            columns={columnLists[UNPINNED]}
            {...columnItemsProps}
          />
          <HeaderItems
            type={PINNED_RIGHT}
            columns={columnLists[PINNED_RIGHT]}
            {...columnItemsProps}
          />
        </Zones>
      </DragDropContext>
    </Wrapper>
  );
};

ColumnSettings.propTypes = {
  style: PropTypes.object,
  columns: PropTypes.array,
  columnsComputed: PropTypes.object,
  // type of column settings bar
  hide: PropTypes.bool,
  // list of column ids that shouldn't be shown in settings lists
  hideFromSettings: PropTypes.array,
  pin: PropTypes.bool,
  reorder: PropTypes.bool,
  // callbacks
  onColumnHidden: PropTypes.func,
  onColumnPinned: PropTypes.func,
  onColumnReorder: PropTypes.func,
};

ColumnSettings.defaultProps = {
  hideFromSettings: [],
};

export default memo(ColumnSettings);
