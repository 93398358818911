import { useCallback, useState, useMemo } from 'react';

import dayjs from 'dayjs';
import { useAddFromResearchMutation } from '@marketmuse/data-papi';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { RESEARCH_ACTIONS } from '@marketmuse/data-state/appResearch';
import { plansActionTypes } from '@marketmuse/data-state/plans';

import {
  AddToInventoryHookResult,
  AddToInventoryHandlerParams,
} from '../types';
import useToaster from './useToaster';

const useAddToInventory = ({
  canAddToInventory,
  hasViableSiteInventory,
  siteId,
}: {
  canAddToInventory: boolean;
  hasViableSiteInventory: boolean;
  siteId: string;
}): AddToInventoryHookResult => {
  const dispatch = useStoreDispatch();
  const [addingOrigin, setAddingOrigin] = useState(null);
  const [addingItems, setAddingItems] = useState([]);
  const { addedToInventoryToast, inventoryNotReady, premiumInventoryToast } =
    useToaster();

  const [inventoryItemsAddMutation, { loading }] = useAddFromResearchMutation({
    onError: () => {
      setAddingItems([]);
      setAddingOrigin(void 0);
    },
    onCompleted: data => {
      const projects = data?.inventoryItemsAdd?.projects;

      setAddingOrigin(void 0);
      setAddingItems([]);

      if (projects && projects[0]?.plan?.id) {
        addedToInventoryToast(data.inventoryItemsAdd.projects[0].plan.id);
        dispatch(RESEARCH_ACTIONS.researchTaskInventoryItemAdded(addingItems));

        dispatch({ type: plansActionTypes.PLANS_FETCH });
      }
    },
  });

  const handler = useCallback(
    ({ items, origin }: AddToInventoryHandlerParams) => {
      if (!canAddToInventory) {
        premiumInventoryToast();
        return;
      }

      if (!hasViableSiteInventory) {
        inventoryNotReady();
        return;
      }

      setAddingOrigin(origin);
      setAddingItems(items);

      const planTitle = dayjs().format('MMM-D-YYYY').toLowerCase();
      const inventoryItems = items.map(item => ({
        term: item.term,
        projectData: { planTitle },
      }));

      inventoryItemsAddMutation({
        variables: {
          siteId,
          items: inventoryItems,
          tagId: planTitle,
          tagInput: {
            title: planTitle,
            terms: { add: inventoryItems.map(item => item.term) },
          },
        },
      });
    },
    [
      canAddToInventory,
      hasViableSiteInventory,
      inventoryItemsAddMutation,
      inventoryNotReady,
      premiumInventoryToast,
      siteId,
    ],
  );

  const loadingItems = useMemo(
    () =>
      addingItems.reduce((acc, item) => ({ ...acc, [item.term]: true }), {}),
    [addingItems],
  );

  return { handler, addingOrigin, loading, loadingItems };
};

export default useAddToInventory;
