import { createSlice, PayloadAction, original } from '@reduxjs/toolkit';
import { findIndex, get } from 'lodash';
import { Plan } from '@marketmuse/config/types/papi';
import { PLANS_REFRESH, PLANS_DELETED } from './plans.actionTypes';

import { initialState } from './plans.initialState';

type PlanUpsertAction = PayloadAction<{
  siteId: string;
  plan: Plan;
}>;

export const plansSlice = createSlice({
  name: 'plans',
  initialState: initialState,
  reducers: {
    upsert: (state, action: PlanUpsertAction) => {
      const { siteId, plan: planUpdated } = action.payload;

      const plans = state?.[siteId] ? original(state[siteId]) : {};
      const plansData: Plan[] = get(plans, 'data') || [];

      const existingIndex = findIndex(
        plansData,
        plan => plan?.id === planUpdated.id,
      );

      if (existingIndex !== -1) {
        plansData[existingIndex] = planUpdated;

        state[siteId] = {
          data: plansData,
        };
      } else {
        const nextPlans = [...plansData, planUpdated];
        state[siteId] = {
          data: nextPlans,
        };
      }
    },
    clear: () => {
      return {};
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        action => action.type === PLANS_REFRESH,
        (
          state,
          action: PayloadAction<{
            siteId: string;
            plans: Array<Plan>;
          }>,
        ) => {
          const { siteId, plans } = action.payload;
          state[siteId] = {
            data: plans,
          };
        },
      )
      .addMatcher(
        action => action.type === PLANS_DELETED,
        (
          state,
          action: PayloadAction<{
            siteId: string;
            planIds: string[];
          }>,
        ) => {
          const { siteId, planIds } = action.payload;
          const lastPlans = state?.[siteId]
            ? original(state[siteId])?.data
            : [];

          const nextPlans = lastPlans.filter(
            plan => planIds.indexOf(plan.id) === -1,
          );

          state[siteId] = {
            data: nextPlans,
          };
        },
      );
  },
});

export const plansReducer = plansSlice.reducer;
export const plansActions = plansSlice.actions;
