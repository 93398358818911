import { Navigate } from 'react-router-dom';
import { serializeAccessibleSites } from '../../utils/misc';
import getSignInPathDefault from '../../utils/getSignInPathDefault';

export const SiteCatchall = () => {
  const sites = useStoreSelector(state => state?.user.org.sites);
  const site = serializeAccessibleSites(sites)[0];

  return <Navigate to={getSignInPathDefault(site.level)} />;
};
