import { updateUserData } from '../../actions/authenticateActions';
import { ONBOARDING_CHECKLIST_LIST } from '../../config/onboarding/onboardingChecklist';
import { ONBOARDING_STEPS } from '../../config/onboarding/onboardingSteps';
import * as types from '../../config/types';

export default () => ({ dispatch, getState }) => next => action => {
  const state = getState();

  if (action.type === types.CHECK_USER_ONBOARDING_PROGRESS) {
    const { onboardingProgress, onboardingStep } = state?.user || {};

    // list of active tasks
    const checklist = Object.values(ONBOARDING_CHECKLIST_LIST || {}).map(o => o.id);
    // list of completed tasks, against active
    const completed = Object.keys(onboardingProgress || {}).filter(
      completed => ONBOARDING_CHECKLIST_LIST?.[completed]
    );

    const inChecklist = onboardingStep === ONBOARDING_STEPS.checklist;
    const isCompleted = checklist.length === completed.length;
    if (inChecklist && isCompleted) {
      dispatch(
        updateUserData({
          preventSuccessToast: true,
          fields: {
            onboardingStep: ONBOARDING_STEPS.coupon,
          },
        })
      );
    }
  }

  return next(action);
};
