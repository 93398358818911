import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleDynamic } from '../../../actions/miscActions';
import { VIEWS, VIEWS_CONFIG } from '../../../config/payment/settingsModal';

import Portal from '../../../components/Portal';
import Modal, { ModalBody, ModalHeader } from '../../../components/Modal';
import IconSvg from '../../../components/IconSvg';

import UpdateBillingInfo from '../UpdateBillingInfo/UpdateBillingInfo';
import AddUsers from './screens/AddUsers';

import CreditBalance from './screens/CreditBalance';
import QueriesBalance from './screens/QueriesBalance';
import Sidebar from './Sidebar';

const PaymentSettingsModal = () => {
  const dispatch = useDispatch();
  const activeView = useSelector(
    state => state.misc['paymentSettingsModal-data']?.activeScreen,
  );

  const closeModal = () => {
    dispatch(
      toggleDynamic({
        id: 'paymentSettingsModal',
        status: false,
        data: null,
      }),
    );
  };

  return (
    <Portal>
      <Modal
        position="complete"
        hideClose
        backdropStyle={{
          // TODO: MMS-290
          zIndex: 60000,
        }}
        sidebar={<Sidebar activeView={activeView} />}
      >
        <ModalHeader
          title={VIEWS_CONFIG[activeView]?.title}
          close={closeModal}
          hasBorderBottom
          icon={
            VIEWS_CONFIG[activeView]?.icon ? (
              <IconSvg name={VIEWS_CONFIG[activeView]?.icon} size="16" />
            ) : null
          }
        ></ModalHeader>
        <ModalBody>
          {activeView === VIEWS.card && <UpdateBillingInfo showCurrentCard />}
          {activeView === VIEWS.users && <AddUsers />}
          {activeView === VIEWS.credits && <CreditBalance />}
          {activeView === VIEWS.queries && <QueriesBalance />}
        </ModalBody>
      </Modal>
    </Portal>
  );
};

export default memo(PaymentSettingsModal);
