import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, pickBy } from 'lodash';
import styled from 'styled-components';

import { validateEmail as validateEmailAction } from '../../../../actions/miscActions';

import * as types from '../../../../config/types';

import useFields from '../../../../hooks/useFields';
import * as FIELDS from '../../../../hooks/useFields/config';
import BillingInfoStep from './AddUserScreens/BillingInfoStep';
import UserInfoStep from './AddUserScreens/UserInfoStep';
import SuccessScreen from './AddUserScreens/SuccessScreen';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  padding: 0;
`;

const initialNewUserState = { email: '', firstName: '', lastName: '' };

const AddUsers = () => {
  const dispatch = useDispatch();

  const isCheckingAccount = useSelector(
    state =>
      state.loadings?.[types.GET_USER_DATA] ||
      state.loadings?.[types.RECURLY_GET_ACCOUNT],
  );
  const hasAccount = useSelector(
    state => !!state?.payment?.account && state?.payment?.account !== 404,
  );

  const [planName, setPlanName] = useState();
  const [currentStep, setStep] = useState('userInfoStep');
  const [availableSteps, setAvailableSteps] = useState(['userInfoStep']);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState();

  const [
    formFields,
    ,
    [valueState, setValueState, setFormState],
    [errors, , setAsyncErrors],
  ] = useFields({
    fields: FIELDS.addUserFields({ disabled: !hasAccount }),
    initialState: initialNewUserState,
  });

  const isDisabled = useMemo(
    () =>
      !planName ||
      !valueState.email ||
      Boolean(Object.keys(errors).length) ||
      loading,
    [valueState, errors, loading, planName],
  );

  const goToNextStep = useCallback(
    step => {
      if (!availableSteps.includes(step)) {
        setAvailableSteps([...availableSteps, step]);
      }
      setStep(step);
    },
    [availableSteps],
  );

  const validateEmail = useCallback(
    email => {
      setLoading(true);
      dispatch(
        validateEmailAction({
          email,
          callback: res => {
            setAsyncErrors(s => pickBy({ ...s, email: res.error }, e => !!e));
            setLoading(false);
          },
        }),
      );
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (value, name) => {
      setValueState({ name, value });
      if (name === 'email' && !isEmpty(value)) {
        validateEmail(value);
      }
    },
    [setValueState],
  );

  const addAnotherUser = () => {
    setFormState({});
    setStep('userInfoStep');
    setAvailableSteps(['userInfoStep']);
  };

  const headerAttributes = {
    planName,
    setPlanName,
    currentStep,
    availableSteps,
    goToNextStep,
    isCheckingAccount,
    hasAccount,
    isDisabled,
  };

  return (
    <Wrapper>
      {currentStep === 'userInfoStep' && (
        <UserInfoStep
          {...headerAttributes}
          formFields={formFields}
          valueState={valueState}
          errors={errors}
          handleChange={handleChange}
          loading={loading}
        />
      )}
      {currentStep === 'billingInfoStep' && (
        <BillingInfoStep
          {...headerAttributes}
          setStep={setStep}
          setAvailableSteps={setAvailableSteps}
          createUser={valueState}
          setSubscription={setSubscription}
        />
      )}
      {currentStep === 'success' && (
        <SuccessScreen
          subscription={subscription}
          email={valueState.email}
          addAnotherUser={addAnotherUser}
        />
      )}
    </Wrapper>
  );
};

export default AddUsers;
