import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SiteLevel } from '@marketmuse/config/types/papi';
import { PAPI } from '@marketmuse/config/types';

import ModalSidebar, {
  ModalSidebarListItem,
  ModalSidebarHeading,
  ModalSidebarList,
  ModalSidebarItem,
  ModalSidebarClickable,
} from '../../../components/Modal/ModalSidebar';
import IconSvg from '../../../components/IconSvg';
import useHasAccess from '../../../hooks/useHasAccess';

import { toggleDynamic } from '../../../actions/miscActions';
import { VIEWS, VIEWS_CONFIG } from '../../../config/payment/settingsModal';

const SidebarListItem = ({ activeView, id, onClick }) => (
  <ModalSidebarListItem
    onClick={() => onClick(id)}
    active={activeView === id}
    title={VIEWS_CONFIG[id]?.title}
    id={id}
  >
    {VIEWS_CONFIG[id]?.title}
  </ModalSidebarListItem>
);

const Sidebar = ({ activeView }) => {
  const dispatch = useDispatch();
  const sites = useSelector(state => state.user?.org?.sites);
  const level = useSelector(state => state.filter?.site?.level);
  const isShownCredits = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_CREDITS,
  });
  const canBuyUsers = useMemo(() => {
    const sitesList = sites || [];
    const hasPremium = !!sitesList.find(
      ({ level }) => level === SiteLevel.PREMIUM,
    );
    const hasCanBuyUserOption = sitesList.find(({ level }) =>
      [SiteLevel.STANDARD, SiteLevel.CLASSIC, SiteLevel.APPSUMO].includes(
        level,
      ),
    );
    // premium levels have unlimited free users
    // if at least one level that can buy users for an org - show "Add User"
    return !hasPremium && hasCanBuyUserOption;
  }, [sites]);

  const numAppRunsLimit = useSelector(
    state => state.misc?.counts?.appRuns?.limit,
  );
  const isShownQueries =
    [SiteLevel.FREE, SiteLevel.STANDARD, SiteLevel.APPSUMO].includes(level) &&
    !!numAppRunsLimit;
  const setActiveView = id =>
    dispatch(
      toggleDynamic({
        id: 'paymentSettingsModal',
        status: true,
        data: { activeScreen: id },
      }),
    );

  return (
    <ModalSidebar>
      {isShownCredits && (
        <>
          <ModalSidebarHeading>Your Credits</ModalSidebarHeading>
          <ModalSidebarList>
            <SidebarListItem
              id={VIEWS.credits}
              activeView={activeView}
              onClick={setActiveView}
            />
          </ModalSidebarList>
        </>
      )}
      {isShownQueries && (
        <>
          <ModalSidebarHeading>Your Queries</ModalSidebarHeading>
          <ModalSidebarList>
            <SidebarListItem
              id={VIEWS.queries}
              activeView={activeView}
              onClick={setActiveView}
            />
          </ModalSidebarList>
        </>
      )}

      <ModalSidebarList as={'div'}>
        <ModalSidebarItem as={'div'}>
          <ModalSidebarClickable
            id={VIEWS.card}
            title={VIEWS_CONFIG[VIEWS.card]?.title}
            active={activeView === VIEWS.card}
            onClick={() => setActiveView(VIEWS.card)}
          >
            <IconSvg
              style={{ marginRight: 16 }}
              name="payment-card"
              size="16"
            />
            {VIEWS_CONFIG[VIEWS.card]?.title}
          </ModalSidebarClickable>
        </ModalSidebarItem>
      </ModalSidebarList>

      {canBuyUsers && (
        <ModalSidebarList as={'div'}>
          <ModalSidebarItem as={'div'}>
            <ModalSidebarClickable
              id={VIEWS.users}
              title={VIEWS_CONFIG[VIEWS.users]?.title}
              active={activeView === VIEWS.users}
              onClick={() => setActiveView(VIEWS.users)}
            >
              <IconSvg style={{ marginRight: 16 }} name="avatar" size="16" />
              {VIEWS_CONFIG[VIEWS.users]?.title}
            </ModalSidebarClickable>
          </ModalSidebarItem>
        </ModalSidebarList>
      )}
    </ModalSidebar>
  );
};

export default Sidebar;
