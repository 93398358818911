import get from 'lodash/get';

import { PurchaseCreditsDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import isPaymentTestModeActive from '../../utils/isPaymentTestModeActive';

import * as types from '../../config/types';
import { updateCredits } from '../../actions/siteActions';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.PURCHASE_CREDITS) {
      const siteId = get(state, 'filter.site.id');
      Toast.fire("We're Updating your Account.");
      makeRequest({
        type: types.PURCHASE_CREDITS,
        mutation: PurchaseCreditsDocument,
        variables: {
          siteId,
          num: action.num,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              UseRecurlyTestCode: isPaymentTestModeActive(),
            },
          },
        },
      }).then(res => {
        if (get(res, 'error')) {
          Toast.fire(res.error || 'Failed to purchase credits', 'error');
        } else {
          Toast.fire('Purchase successful', 'success');
          const purchase = get(res, 'purchaseCredits');
          const briefCredits = get(res, 'purchaseCredits.site.briefCredits');
          dispatch(
            updateCredits({
              siteId,
              briefCredits,
            }),
          );
          if (typeof action.callback === 'function') {
            action.callback(purchase);
          }
        }
      });
    }

    return next(action);
  };
