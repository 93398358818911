import { checkSiteViableInventory } from '@marketmuse/utilities';
import { PAPI } from '@marketmuse/config/types';
import { validatePermission } from '@marketmuse/utilities';
import { AppUiStatus } from '@marketmuse/config/types/applications';
import {
  AppQueryNetworkKeys,
  AppQueryNetworkStatus,
  ReduxListener,
  ResearchDataInput,
  RootState,
} from '../../../types';
import { researchTaskInitialize, researchTaskSiteChange } from '../../actions';

import { networkSlice, uiSlice } from '../../slices';
import {
  getAdwordsKeywords,
  getAdwordsQuestions,
  getSerpData,
} from '../../thunks';
import validateAppParams from '../../../utils/validateAppParams';

const setup = (startListening: ReduxListener) => {
  const networkActions = networkSlice.actions;
  const uiActions = uiSlice.actions;

  startListening({
    actionCreator: researchTaskSiteChange,
    effect: async (action, { cancelActiveListeners }) => {
      cancelActiveListeners();
    },
  });

  startListening({
    actionCreator: researchTaskInitialize,
    effect: async (action, { dispatch, getState }) => {
      const payload: ResearchDataInput = action.payload;
      const state: RootState = getState();
      const site = state?.filter?.site;
      const versions = state?.filter?.versions;
      const permissions = state.permissions;
      const isParamsValid = validateAppParams<ResearchDataInput>(payload);
      const isSiteInventoryViable = checkSiteViableInventory(site, versions);
      const appStatus = state.appResearch.ui.status;

      const queueKeys = [
        AppQueryNetworkKeys.knowledgeGraph,
        AppQueryNetworkKeys.bulkAdwordsKeywords,
        AppQueryNetworkKeys.serpPages,
        AppQueryNetworkKeys.serpUserIntentData,
        AppQueryNetworkKeys.websiteHeatmap,
      ];
      const completeKeys = [];

      const canAccessConnect = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_CONNECT,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });
      const canAccessKeywords = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_KEYWORDS,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });
      const canAccessQuestions = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_QUESTIONS,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });
      const canAccessReflect = validatePermission({
        permissions,
        permission: {
          resource: PAPI.PermissionResource.RESEARCH_REFLECT,
          action: PAPI.PermissionAction.ACCESS_DATA,
        },
      });

      if (canAccessQuestions) {
        queueKeys.push(AppQueryNetworkKeys.serpQuestions);
      } else {
        completeKeys.push(AppQueryNetworkKeys.serpQuestions);
      }

      if (canAccessConnect) {
        queueKeys.push(AppQueryNetworkKeys.linkingRecommendations);
      } else {
        completeKeys.push(AppQueryNetworkKeys.linkingRecommendations);
      }

      if (isSiteInventoryViable && canAccessReflect) {
        queueKeys.push(AppQueryNetworkKeys.inventoryTopicsKeywords);
        queueKeys.push(AppQueryNetworkKeys.inventoryTopicsKG);
        queueKeys.push(AppQueryNetworkKeys.inventoryTopicsBulkKeywords);
        queueKeys.push(AppQueryNetworkKeys.inventoryTopicsQuestions);
        queueKeys.push(AppQueryNetworkKeys.inventoryTopicsSerpQuestions);
      } else {
        completeKeys.push(AppQueryNetworkKeys.inventoryTopicsKeywords);
        completeKeys.push(AppQueryNetworkKeys.inventoryTopicsKG);
        completeKeys.push(AppQueryNetworkKeys.inventoryTopicsBulkKeywords);
        completeKeys.push(AppQueryNetworkKeys.inventoryTopicsQuestions);
        completeKeys.push(AppQueryNetworkKeys.inventoryTopicsSerpQuestions);
      }

      if (isParamsValid) {
        dispatch(uiActions.statusUpdate(AppUiStatus.loading));
        dispatch(
          networkActions.setMany({
            keys: queueKeys,
            status: AppQueryNetworkStatus.queued,
          }),
        );

        const requests = [dispatch(getSerpData(payload))];

        if (canAccessQuestions) {
          requests.unshift(dispatch(getAdwordsQuestions(payload)));
        } else {
          completeKeys.push(AppQueryNetworkKeys.adwordsQuestions);
        }

        if (canAccessKeywords) {
          requests.unshift(dispatch(getAdwordsKeywords(payload)));
        } else {
          completeKeys.push(AppQueryNetworkKeys.adwordsKeywords);
        }

        dispatch(
          networkActions.setMany({
            keys: completeKeys,
            status: AppQueryNetworkStatus.complete,
          }),
        );

        await Promise.all(requests);
      } else {
        console.log('app run prevented', {
          params: payload,
          isParamsValid,
          isSiteInventoryViable,
          appStatus,
        });
      }
    },
  });
};

export default setup;
