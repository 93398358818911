import { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { PAPI } from '@marketmuse/config/types';
import Button from '../../../components/Button';
import useHasAccess from '../../../hooks/useHasAccess';
import checkAppRunLimit from '../../../utils/applications/checkAppRunLimit';

import Loader from '../../../components/Loader/Loader';

const ButtonStyled = styled(Button)`
  margin-left: 12px;
  margin-right: 12px;
  height: 32px;
  padding: 0 18px;
  gap: 4px;
`;

interface IAppRunTriggerProps {
  canRun: boolean;
  canRunText?: string;
  loading?: boolean;
  onRun: () => void;
  onBuyAppRuns: () => void;
}

const AppRunTrigger: FC<IAppRunTriggerProps> = ({
  onRun,
  onBuyAppRuns,
  canRun,
  canRunText,
  loading = false,
}) => {
  const appRuns = useStoreSelector(state => state?.misc?.counts?.appRuns);
  const canBuyQueries = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_APP_RUNS,
  });
  const hasAvailableAppRuns = checkAppRunLimit({ counts: { appRuns } });

  const text = useMemo(() => {
    if (!hasAvailableAppRuns) {
      return canBuyQueries ? 'Get More Queries' : 'Out of Queries';
    } else {
      return canRunText || 'Run';
    }
  }, [canRunText, canBuyQueries, hasAvailableAppRuns]);

  const clickable = useMemo(() => {
    let value = false;
    if (canRun) {
      value = true;
    }

    if (canRun && hasAvailableAppRuns) {
      value = true;
    }

    if (!hasAvailableAppRuns && !canBuyQueries) {
      value = false;
    }

    return value;
  }, [hasAvailableAppRuns, canBuyQueries, canRun]);

  const onClick = useCallback(() => {
    if (hasAvailableAppRuns) {
      onRun();
    } else if (canBuyQueries) {
      onBuyAppRuns();
    }
  }, [canBuyQueries, hasAvailableAppRuns, onRun, onBuyAppRuns]);

  return (
    <ButtonStyled
      data-mms--app-run-trigger
      onClick={() => onClick()}
      primary={hasAvailableAppRuns ? true : void 0}
      danger={!hasAvailableAppRuns ? true : void 0}
      disabled={!clickable}
      iconRight={loading ? <Loader color="white" /> : null}
    >
      {text}
    </ButtonStyled>
  );
};
export default AppRunTrigger;
