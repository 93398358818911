import styled from 'styled-components';

export const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled.div`
  color: ${p => p.theme.colors.grey85};
  ${p => p.theme.type.heading3};
  font-weight: bold;
`;

export const Available = styled.div`
  color: ${p => p.theme.colors.grey85};
  font-size: 8pt;
  margin-left: 12px;
`;

export const ChooseSubscriptionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 50%;
  border-left: 1px solid ${p => p.theme.colors.grey10};
  background-color: ${p => p.theme.colors.grey05};
  padding: ${p => `0px 6% 0px 6%`};
`;

export const PaymentFormHeading = styled.p`
  ${p => p.theme.type.heading2};
  margin-top: 0;
  margin-bottom: ${p => p.theme.spacing.small};
  font-size: 24px;
  line-height: 29px;
`;
