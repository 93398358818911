import { uniq } from 'lodash';
import { PlanName, PLAN_NAME } from '../../types/recurly';
import { plansInfo } from './plansInfo';

export const plansCodes: Record<PlanName, string[]> = {
  user: [plansInfo[PLAN_NAME.USER].monthly.code],
  appsumoUser: [plansInfo[PLAN_NAME.APP_SUMO_USER].monthly.code],
  standard: [
    'optimize',
    'optimize-annual',
    'standard',
    'standard-annual',
    'standard-7200-monthly-q42022',
    'standard-6000-annual-q42022',
    'standard-149-monthly-q12023',
    'standard-1500-annual-q12023',
    plansInfo[PLAN_NAME.STANDARD].monthly.code,
    plansInfo[PLAN_NAME.STANDARD].monthly.codeTest,
    plansInfo[PLAN_NAME.STANDARD].annual.code,
    plansInfo[PLAN_NAME.STANDARD].annual.codeTest,
  ],
  standardTrial: uniq([
    plansInfo[PLAN_NAME.STANDARD_TRIAL].weekly.code,
    plansInfo[PLAN_NAME.STANDARD_TRIAL].weekly.codeTest,
  ]),
  classic: ['pro', 'pro-annual', 'mmplus', 'mmplus-annual'],
};
