import React, { useMemo, useEffect } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';
import { MISC_SIMPLE_KEYS } from '@marketmuse/data-state/types';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import { ErrorBoundary } from '@marketmuse/components';

import useIdentityProvider from '../../providers/IdentityProvider/useIdentityProvider';
import useIdentitySuite from '../../hooks/auth/useIdentitySuite';
import ScreenLoader from '../../components/ScreenLoader';
import { LayoutProtected } from '../../containers/Layout/LayoutProtected';

export const Private = () => {
  const userLoaded = useStoreSelector(
    state => state.misc?.[MISC_SIMPLE_KEYS.userLoaded],
  );
  const initialPath = useStoreSelector(
    state => state.misc?.[MISC_SIMPLE_KEYS.initialPath],
  );
  const logoutInitiated = useStoreSelector(
    state => state.misc[MISC_SIMPLE_KEYS.initiateLogout],
  );
  const { siteId, orgId } = useIdentitySuite();
  const { isSignedIn, isLoading } = useIdentityProvider();
  const navigate = useNavigate();

  const showProtected = useMemo(() => {
    const failureConditions = [
      logoutInitiated,
      !orgId,
      !siteId,
      !isSignedIn,
      !userLoaded,
      !initialPath,
    ];

    if (failureConditions.filter(Boolean).length > 0) {
      return false;
    }

    return true;
  }, [initialPath, isSignedIn, logoutInitiated, orgId, siteId, userLoaded]);

  useEffect(() => {
    if (isLoading || userLoaded || showProtected) {
      return;
    }

    if (isSignedIn) {
      navigate('/post-login');
    } else {
      navigate('/login');
    }
  }, [userLoaded, showProtected, isSignedIn, navigate, isLoading]);

  if (!userLoaded || !showProtected || isLoading) {
    return <ScreenLoader.Auth />;
  }

  return (
    <LayoutProtected>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutProtected>
  );
};
