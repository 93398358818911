import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from './../../Clickable/Clickable';
import ActionButton from './ActionButton';

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 14px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 12;
  top: calc(100% + 8px);
  left: 0;
  background-color: white;
  border-radius: 4px;
  border: 1px solid ${p => p.theme.colors.grey15};
  box-shadow: ${p => p.theme.moonShadow};
  width: max-content;
`;

const DropdownBackdrop = styled(Clickable)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
`;

const ColumnSettingsBarButton = ({
  icon,
  title,
  active,
  disabled,
  onClick,
  dropdownContent,
}) => {
  const [dropdownOpen, dropdownOpenSet] = useState(false);

  return (
    <Wrapper>
      <ActionButton
        active={active || dropdownOpen}
        disabled={disabled}
        data-mms--action-bar={title}
        onClick={e => {
          if (typeof onClick === 'function') {
            onClick((e, dropdownOpen, dropdownOpenSet));
          } else {
            dropdownOpenSet(!dropdownOpen);
          }
          if (e && e.target) e.target.blur();
        }}
      >
        {!!icon && <IconWrapper>{icon}</IconWrapper>}
        {!!icon && !!title && <div style={{ width: 6 }} />}
        {!!title && title}
      </ActionButton>
      {dropdownOpen && (
        <>
          <DropdownBackdrop onClick={() => dropdownOpenSet(false)} />
          <Dropdown>{dropdownContent}</Dropdown>
        </>
      )}
    </Wrapper>
  );
};

const barButtonTypesObj = {
  // buttons with `order` will appear before the ones without,
  // and then be sorted based on the value of the order.
  order: PropTypes.number,
  icon: PropTypes.any,
  title: PropTypes.string,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  dropdownContent: PropTypes.any,
};

export const barButtonTypes = PropTypes.shape(barButtonTypesObj);

ColumnSettingsBarButton.propTypes = {
  ...barButtonTypesObj,
};

export default memo(ColumnSettingsBarButton);
