import React from 'react';
import cx from 'classnames';
import { kebabCase } from 'lodash';
import { Icon, Tooltip, POPPER_THEME } from '@marketmuse/components';
import { PAPI } from '@marketmuse/config/types';
import { pluralize } from '@marketmuse/utilities';
import handleEventBlock from '../../../utils/handleEventBlock';

const makeTooltipContent = (termsCount: number, planTitle: string) => {
  return [
    'View',
    termsCount,
    pluralize('topic', termsCount),
    'in',
    planTitle,
  ].join(' ');
};

function PlanListItem({
  displayTopicByPlansLink,
  isActive,
  onClick,
  onSetPlanDelete,
  onViewPlanTerms,
  plan,
}: {
  displayTopicByPlansLink: boolean;
  isActive: boolean;
  onClick: (planId: string) => void;
  onSetPlanDelete: (plan: PAPI.Plan) => void;
  onViewPlanTerms: ({
    title,
    terms,
  }: {
    title: string;
    terms: Array<string>;
  }) => void;
  plan: PAPI.Plan;
}) {
  const planTitle = `#${kebabCase(plan.title)}`;
  const terms = plan.projects.map(project => project.topic).filter(Boolean);
  const termsCount = terms.length;

  return (
    <button
      onClick={() => onClick(plan.id)}
      className={cx([
        'flex',
        'flex-row',
        'font-bold',
        'group',
        'truncate',
        'flex-nowrap',
        'w-full',
        'text-left',
        'px-5',
        'py-3',
        'h-10',
        'relative',
        'hover:bg-black',
        'hover:text-blue-400',
        'transition-colors',
        isActive && ['bg-black', 'text-blue-400'],
      ])}
    >
      <span className={cx(['grow', 'block', 'truncate'])}>{planTitle}</span>
      <span
        className={cx([
          'absolute',
          'right-0',
          'opacity-0',
          'group-hover:opacity-100',
          'transition-opacity',
          'shadow-md',
          'bg-black',
          'rounded-lg',
          'h-8',
          'top-1',
          'p-2',
          'flex',
          'flex-row',
          'items-center',
          'gap-2',
        ])}
      >
        {displayTopicByPlansLink && termsCount !== 0 && (
          <Tooltip
            content={makeTooltipContent(termsCount, planTitle)}
            placement="right"
            theme={POPPER_THEME.dark}
            delay={{
              open: 350,
              close: 100,
            }}
          >
            <span
              className={cx([
                'flex',
                'cursor-pointer',
                'text-gray-200',
                'hover:text-blue-400',
                'transition-colors',
              ])}
              onClick={event => {
                handleEventBlock(event);
                onViewPlanTerms({ title: planTitle, terms });
              }}
              data-mms--plan-item="view-terms"
            >
              <Icon name="arrow-right" size="16" />
            </span>
          </Tooltip>
        )}
        <span
          data-track-plan-item--delete
          data-mms--plan-item="delete-plan"
          className={cx([
            'flex',
            'cursor-pointer',
            'text-gray-200',
            'hover:text-red-400',
            'transition-colors',
          ])}
          onClick={event => {
            handleEventBlock(event);
            onSetPlanDelete(plan);
          }}
        >
          <Icon name="delete" size="16" />
        </span>
      </span>
    </button>
  );
}

export default PlanListItem;
