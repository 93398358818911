import styled from 'styled-components';

import IconCheck from 'react-feather/dist/icons/check';

export default styled(IconCheck).attrs(p => ({
  active: p.active ? 'active' : undefined,
}))`
  color: ${p => (p.active ? p.theme.mmxBlue : p.theme.colors.grey15)};
  width: 22px;
`;
