import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Clickable from './../../Clickable/Clickable';
import Tooltip from './../../Tooltip/Tooltip/Tooltip';

export const iconStyle = css`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  color: ${p => p.theme.colors.grey50};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled(Clickable)`
  ${iconStyle}

  &:hover{
    cursor: pointer;
    color: ${p => p.theme.colors.black};
  }
  &:focus{
    color: ${p => p.theme.colors.grey60};
  }

  ${p => p.active && `
    color: ${p.theme.colors.blue};
    &:hover,
    &:focus {
      cursor: pointer;
      color: ${p.theme.colors.blueDark};
    }
  `}

  & > * {
    width: 22px;
  }
`;

const HeaderButton = ({ tooltip, children, active, onClick, ...props }) => (
  <Tooltip title={tooltip} disabled={!tooltip}>
    <Wrapper
      {...props}
      active={active}
      onClick={e => {
        // release focus
        e.target.blur();
        if (typeof onClick === 'function') {
          onClick(e)
        }
      }}
    >
      {children}
    </Wrapper>
  </Tooltip>
);

HeaderButton.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.any,
  active: PropTypes.bool,
};

export default HeaderButton;
