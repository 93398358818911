import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const getInventoryVersion = ({ siteId, options, callback }) => ({
  type: types.GET_INVENTORY_VERSION,
  siteId,
  options,
  callback,
});

export const getInventoryVersions = ({ siteId, callback, startTime }) => ({
  type: types.GET_INVENTORY_VERSIONS,
  siteId,
  callback,
  startTime,
});

export const getInventorySubdomains = ({ domain, callback }) => ({
  type: types.GET_INVENTORY_SUBDOMAINS,
  domain,
  callback,
});

// SEO CALL
export const seoKnowledgeGraphAndVariants = ({
  term,
  url,
  skipVariants,
  fetchSerpPages,
  callback,
}) => ({
  type: types.GET_KEYWORD_DETAILS_KG,
  term,
  url,
  skipVariants,
  fetchSerpPages,
  callback,
});

// CREATES PROJECTS + INVENTORY ITEMS
export const inventoryItemsAdd = ({
  items,
  siteId,
  callback,
  preventSuccessToast,
  sourceItemName,
}) => ({
  type: types.INVENTORY_ITEMS_ADD,
  callback,
  siteId,
  items,
  preventSuccessToast,
  sourceItemName,
});

// UPDATES INV-DATA
export const inventoryItemsUpdate = ({
  pages,
  topics,
  resources,
  noFeedback,
  callback,
  undoCallback,
  updateType,
}) => ({
  type: types.INVENTORY_ITEMS_UPDATE,
  pages,
  topics,
  resources,
  noFeedback,
  callback,
  undoCallback,
  updateType,
});

// CREATES NEW ORGS
export const inventoryCreateOrg = ({ fields, noErrorToast, callback }) => ({
  type: types.CREATE_INVENTORY_ORG,
  fields,
  noErrorToast,
  callback,
});

// SITE MODEL
export const inventoryCreate = ({ orgId, params, options, callback }) => ({
  type: types.INVENTORY_CREATE,
  orgId,
  params,
  options,
  callback,
});

export const inventoryUpdate = ({
  updateSiteId,
  fields = {},
  callback,
  keys = [],
  customToast = false,
}) => ({
  type: types.INVENTORY_UPDATE,
  updateSiteId,
  fields,
  callback,
  keys,
  customToast,
});

export const updateActiveInventory = (args = {}) => ({
  type: ACTION_TYPES.ACTIVE_INVENTORY_UPDATE,
  ...args,
});

export const updateUserInventory = (args = {}) => ({
  type: types.USER_INVENTORY_UPDATE,
  ...args,
});

export const setInventoryVersions = inventoryVersions => ({
  type: types.SAVE_INVENTORY_VERSIONS_TO_STATE,
  inventoryVersions,
});

// PLAN MODEL
export const getPlan = ({ id, callback }) => ({
  type: types.GET_PLAN,
  id,
  callback,
});
