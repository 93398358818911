import { SiteLevel } from '@marketmuse/config/types/papi';

import * as types from '../../config/types';
import {
  updateUserData,
  checkUserOnboardingProgress,
} from '../../actions/authenticateActions';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.UPDATE_USER_ONBOARDING_PROGRESS) {
      const { level } = state?.filter?.site || {};
      const { onboardingProgress } = state?.user || {};
      const { progress } = action;

      if (level === SiteLevel.FREE && !onboardingProgress?.[progress]) {
        dispatch(
          updateUserData({
            preventSuccessToast: true,
            fields: {
              onboardingProgress: {
                ...onboardingProgress,
                ...{ [progress]: true },
              },
            },
            callback: res => {
              dispatch(checkUserOnboardingProgress());
            },
          }),
        );
      }
    }

    return next(action);
  };
