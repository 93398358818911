import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAPI } from '@marketmuse/config/types';

import PriceCard from '../../../../components/PriceCard';
import Clicker from '../../../../components/Clicker';
import Button from '../../../../components/Button';
import CheckoutSection from '../../../../components/CheckoutSection';

import * as types from '../../../../config/types';
import { purchaseAppRuns } from '../../../../actions/toolsActions';
import { toggleDynamic } from '../../../../actions/miscActions';
import SimpleGrid from '../../../../components/SimpleGrid';
import { TitleWrapper, Title } from '../../shared';
import { formatPlural } from '../../../../utils/pluralRules';
import useHasAccess from '../../../../hooks/useHasAccess';

const PRICING_OPTIONS = [
  { value: 10, price: 1 },
  { value: 20, price: 2 },
  { value: 30, price: 3 },
];

const QueriesPurchaseForm = () => {
  const dispatch = useDispatch();

  const availableCredits =
    useSelector(state => state.filter?.site?.briefCredits) ?? 0;
  const isPurchasing = useSelector(
    state => state?.loadings?.[types.PURCHASE_APP_RUNS],
  );
  const canBuyCredits = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_CREDITS,
  });

  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState(0);
  const insufficientFunds = useMemo(
    () => price > availableCredits,
    [price, availableCredits],
  );

  useEffect(() => setPrice(total / 10), [total]);

  return (
    <div>
      <TitleWrapper>
        <Title>Get Extra Queries</Title>
      </TitleWrapper>

      {/* price cards */}
      <SimpleGrid.Container gap={'32px'} columns={6} padding="20px 0 0 0">
        {PRICING_OPTIONS.map(({ value, price }) => (
          <PriceCard
            key={value}
            icon="+"
            title={`${value} Queries`}
            price={`${price} Credit`}
            onClick={() => setTotal(total => total + value)}
          />
        ))}
      </SimpleGrid.Container>

      <CheckoutSection
        total={`${price} Credit${formatPlural('', price, {
          one: '',
          other: 's',
        })}`}
        totalNum={total}
        available={`${availableCredits} Available`}
        items={[
          <>
            <Clicker
              value={total}
              onIncrement={() => setTotal(total => total + 10)}
              onDecrement={() => setTotal(total => total - 10)}
              disableDecrement={total <= 0}
              totalStyle={{ fontSize: '8.5pt', fontWeight: 'normal' }}
              style={{ marginRight: 8, width: 80 }}
            />
            Queries
          </>,
        ]}
        priceCta={
          insufficientFunds &&
          canBuyCredits && (
            <Button
              primary
              style={{ marginTop: -2, marginBottom: -2 }}
              onClick={() => {
                dispatch(
                  toggleDynamic({
                    id: 'paymentSettingsModal',
                    status: true,
                    data: {
                      activeScreen: 'payment-modal--credits',
                      lastCall: new Date(),
                    },
                  }),
                );
              }}
            >
              Buy More Credits
            </Button>
          )
        }
        isDisabledSubmitButton={isPurchasing || insufficientFunds || !price}
        submitAction={purchaseAppRuns}
        type="query"
        isPurchasing={isPurchasing}
      />
    </div>
  );
};

export default memo(QueriesPurchaseForm);
