import * as types from '../config/types';

export const createUserSubscription = ({
  planCode,
  user,
  callback,
  callbackError,
}) => ({
  type: types.CREATE_USER_SUBSCRIPTION,
  planCode,
  user,
  callback,
  callbackError,
});
