import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


import Title from './shared/Title';
import TitleLarge from './shared/TitleLarge';
import Text from './shared/Text';
import Heading from './shared/Heading';
import Clickable from './../Clickable/Clickable';

const Wrapper = styled.div`
  padding: 22px;
  border: 1px solid ${p => p.theme.mmxGreyE9};
  border-radius: 4px;
  display: inline-block;
  min-width: 320px;
`;

const ButtonStyled = styled(Clickable)`
  display: inline-block;
  color: ${p => p.theme.mmxBlue};
  font-weight: bold;
  font-size: 13px;
  background-color: ${p => p.theme.colors.blueLight2};
  border-radius: 4px;
  padding: 12px;
  margin-top: 22px;
  &:hover {
    background-color: rgba(129, 198, 254, 0.3);
  }
`;

const CreditCard = ({
  style,
  className,
  type,
  lastFour,
  name,
  cta,
  ctaTitle,
}) => (
  <Wrapper
    style={style}
    className={className}
  >
    <Title style={{ marginBottom: 4 }}>{type}</Title>
    <Text style={{ marginBottom: 16 }}>ending in <b>{lastFour}</b></Text>
    <Heading style={{ marginBottom: 8 }}>Owner</Heading>
    <TitleLarge>{name}</TitleLarge>
    {cta && ctaTitle && (
      <ButtonStyled onClick={cta}>
        {ctaTitle}
      </ButtonStyled>
    )}
  </Wrapper>
);

CreditCard.propTypes = {
  type: PropTypes.string, // Master, Visa etc.
  name: PropTypes.string,
  lastFour: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.bool,
  cta: PropTypes.func,
  ctaTitle: PropTypes.string,
};

export default CreditCard;
