import styled, { css } from 'styled-components';
import { DEFAULT_COL_WIDTH } from '../utils/shared';

/**
 * Note:
 * Cells are wrapped within ../Components/Cell,
 * layout styles are applied there
 */

const common = css`
  min-width: 0;
  font-size: 14px;
  background-color: inherit;
  position: relative;
  z-index: 2;
  width: ${p => `${p.width || DEFAULT_COL_WIDTH}px`};
  flex: ${p => `${p.width || DEFAULT_COL_WIDTH} 0 auto`};
  order: ${p => p.computed.flexOrder};

  /** for fixed width cols are sticky cols, disable flex grow */
  // todo is here for header issue
  ${p => (p.fixedWidth || p.computed.pinned) && `flex: 0 0 auto;`}

  /** hidden columns */
  ${p => p.computed.hidden && `
    display: none;
  `}

  /** pinning stuff */

  ${p => p.computed.pinned && `
    position: sticky;
    z-index: 3;
  `}

  ${p => p.computed.pinnedLeft && `left: ${p.computed.leftOffset}px;`}
  ${p => p.computed.pinnedRight && `right: ${p.computed.rightOffset}px;`}

  ${p => p.computed.pinnedLeftEdge && `
    border-right: 2px solid ${p.theme.colors.grey15};
  `}

  ${p => p.computed.pinnedRightEdge && `
    border-left: 2px solid ${p.theme.colors.grey15};
  `}
`;

export const TDHead = styled.div`
  ${common};

  font-size: 11px;
  font-weight: 700;
  color: ${p => p.theme.colors.grey50};
  text-transform: uppercase;
  letter-spacing: 1px;

  /** borders */

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }
`;

export const TDBody = styled.div`
  ${common};
`;
