import React, { useMemo, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Table from './Skeleton/Table';
import ColumnSettingsBar, { barActionTypes } from './Components/ColumnSettingsBar';
import validateColumns from './utils/validateColumns';
import calculateTotalWidth from './utils/calculateTotalWidth';
import useSelectColumn from './utils/useSelectColumn';
import useComputedColumns from './utils/useComputedColumns';

// Custom api for column configuration
//
// > id: string (required)
//
// > header: value or layout or Function({ loading }) => value or layout
// To render header cells using layout, configuration at ./Components/Cell
//
// > cell: value or layout or Function({ loading, row, index, value }) => value or layout
// To render cells using layout, configuration at ./Components/Cell
//
// > headerStyle: Object or Function({ ... }) => Object
// Styles that'll be applied to the ./Components/Cell for header as style prop
// If a function is passed, it'll take the same arguments as header
//
// > cellStyle: Object or Function({ ... }) => Object
// Styles that'll be applied to the ./Components/Cell for cells as style prop
// If a function is passed, it'll take the same arguments as cell
//
// > width: integer (default 150)
// Provided width will be used as `flex: <width> 1 auto` with flex-grow
// set to the given value, allowing column to grow proportionally to their
// widths. Note, for sticky columns, flex-grow will be disabled.
//
// > fixedWidth: boolean (default false)
// Disables flex-grow when set to true. Columns will not grow and be at a
// fixed value of given width. Use this in conjunction with the `width` prop
//
// > disableLoading: Boolean
// By default, when a table is in a loading state, a loading bar will render
// in every cell instead of the cell contents. If you need to handle the loading
// state for a specific cell, use this to disable this behaviour
//
// > hideable: Boolean
// Should the column be hideable ? If set to true, a clickable hide icon will be
// rendered on the column header
//
// > onHide: function()
// Triggers when column is hidden. This gets called for the column it's defined in,
// so it could be used to trigger actions specific to the column but to handle the
// hidden states, the generic `onColumnHidden` prop should be used.
//
// > pinnable: Boolean
// Should the column be pinnable ? If set to true, a clickable pin icon will be
// rendered on the column header
//
// > onPin: function()
// Triggers when column is hidden. This gets called for the column it's defined in,
// so it could be used to trigger actions specific to the column but to handle the
// hidden states, the generic `onColumnPinned` prop should be used.
//
// > showOnHover: Boolean
// Show cell contents only when the row is hovered on


const DataTableComponent = ({
  data: _data,
  columns: _columns,
  columnsPinned: _columnsPinned,
  columnsOrder: _columnsOrder = [],
  columnsSelected: _columnsSelected,
  columnsHidden,
  onColumnHidden,
  onColumnPinned,
  onColumnReorder,
  style,
  hiddenHeader,
  hideFromSettings,
  hasStickyHeader,
  renderOnlyContents,
  loading,
  loadingArrayLength,
  onRowClick,
  onRowContextMenu,
  rowClickDisabled,
  barActions,
  selectable,
  selectableBulk,
  onSelectionChange,
  tbBodyStyle,
  tbHeaderStyle,
  onComputedWidthChange,
  getSubRows,
  expandedRows,
  sort,
}) => {

  // data should be empty array to fill rows with loading
  const data = useMemo(() => loading ? Array(loadingArrayLength).fill({}) : _data, [_data, loading]);

  const {
    columns,
    columnsPinned,
    columnsOrder,
    selection
  } = useSelectColumn({
    data,
    selectable,
    selectableBulk,
    onSelectionChange,
    columns: _columns,
    columnsPinned: _columnsPinned,
    columnsOrder: _columnsOrder,
    columnsHidden,
  })

  validateColumns(columns);

  // calculate orders, offsets, edges, pinned, etc.
  const columnsComputed = useComputedColumns(
    columns,
    columnsOrder,
    columnsPinned,
    columnsHidden,
  );
  // does the table have sticky columns ?
  const hasStickyColumns = useMemo(() => !isEmpty(columnsPinned), [columnsPinned]);
  const totalTableWidth = useMemo(() => calculateTotalWidth(columns, { columnsHidden }), [columns, columnsHidden]);

  // update computed width
  useEffect(() => {
    if (typeof onComputedWidthChange !== 'function') return;
    onComputedWidthChange(totalTableWidth);
  }, [
    onComputedWidthChange,
    totalTableWidth,
  ])

  return (
    <>
      <ColumnSettingsBar
        columns={columns}
        columnsComputed={columnsComputed}
        barActions={barActions}
        onColumnHidden={onColumnHidden}
        onColumnPinned={onColumnPinned}
        onColumnReorder={onColumnReorder}
        hideFromSettings={hideFromSettings}
      />
      <Table
        data={data}
        columns={columns}
        columnsComputed={columnsComputed}
        selection={selection}
        style={style}
        loading={loading}
        totalTableWidth={totalTableWidth}
        hiddenHeader={hiddenHeader}
        hasStickyHeader={hasStickyHeader}
        hasStickyColumns={hasStickyColumns}
        renderOnlyContents={renderOnlyContents}
        onRowClick={onRowClick}
        onRowContextMenu={onRowContextMenu}
        rowClickDisabled={rowClickDisabled}
        tbBodyStyle={tbBodyStyle}
        tbHeaderStyle={tbHeaderStyle}
        getSubRows={getSubRows}
        expandedRows={expandedRows}
        sort={sort}
      />
    </>
  )
};

DataTableComponent.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,

  // style attributes that'll be applied
  // to the mask layer wrapping the table
  style: PropTypes.object,
  sort: PropTypes.object,

  // props that will be passed to tb body and tb header
  tbBodyStyle: PropTypes.object,
  tbHeaderStyle: PropTypes.object,

  // this table does not have a header
  hiddenHeader: PropTypes.bool,

  // should table header be sticky
  hasStickyHeader: PropTypes.bool,

  // render only table contents, do not wrap it
  // within an outer layers of divs
  renderOnlyContents: PropTypes.bool,

  // table loading state
  loading: PropTypes.bool,
  // table loading state
  loadingArrayLength: PropTypes.number,

  // controls rows clickability. the function receives this row instance:
  onRowClick: PropTypes.func,

  // controls rows right-clickability (context menu)
  onRowContextMenu: PropTypes.func,

  // disable row click (renders like a regular row)
  // accepts either boolean or object. when `true`, disables clickability
  // of all the rows. provide object ({ <row_id>: bool }) to disable
  // clickability for individual rows
  rowClickDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),

  // add check boxes to the left most column
  selectable: PropTypes.bool,

  // renders a select box on header cell that selects / deselects
  // all items on the table. if false, the header will be hidden
  selectableBulk: PropTypes.bool,

  // function that will be triggered upon selection change, and
  // it will receive a list of the selected items. use this only
  // to update the state - changes causing memoized tableUiConfig to
  // recompute will cause it to fire
  onSelectionChange: PropTypes.func,

  // dictionary of hidden columns, { id: boolean }
  columnsHidden: PropTypes.object,

  // triggered when a column is hidden. receives the column object
  onColumnHidden: PropTypes.func,

  // columns that are pinned (sticky), { id: 'left' | 'right' | boolean (true = leaft) }
  columnsPinned: PropTypes.object,

  // triggered when a column is hidden. receives the column object
  onColumnPinned: PropTypes.func,

  // list of column ids in correct order.
  columnsOrder: PropTypes.array,

  // list of column ids that shouldn't be shown in settings lists
  hideFromSettings: PropTypes.array,

  // ability to reorder rows
  // triggered when a column gets dropped
  onColumnReorder: PropTypes.func,

  // to add custom actions on the column settings bar
  // note that when this option is passed, the column bar will
  // always be visible, otherwise the visibility will depend
  // on other column setting actions passed to the table
  barActions: barActionTypes,

  // the total with of this table is calculated using the column
  // configuration and various other configurations. this function
  // will be triggered once this value is calculated, and if it changes
  onComputedWidthChange: PropTypes.func,

  // function (row: object) => object[]
  // If the row has sub rows, this function should return the data for the sub rows.
  // Note: this config option assumes the subrows are identical to the parent row, and
  // shares the exact same column configuration. If there is ever a need to render a
  // different table (or a completely different React component for sub rows), adding
  // a different config option (ie. `getChildren`) would be more appropriate.
  getSubRows: PropTypes.func,

  // ID's of the rows that are expanded - ie. `{ row123: true, ... }`
  // If the row has sub rows, they will only be rendered if the ID of
  // the row is present in this dictionary and is set to a truthy value.
  expandedRows: PropTypes.object,
};

DataTableComponent.defaultProps = {
  style: {},
  hiddenHeader: false,
  hasStickyHeader: true,
  renderOnlyContents: false,
  loading: false,
  loadingArrayLength: 20,
  selectable: false,
  selectableBulk: true,
  hideFromSettings: [],
  getSubRows: null,
  expandedRows: {},
}

export default memo(DataTableComponent);
