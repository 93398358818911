import * as React from 'react';
import { pick } from 'lodash';

import { Box } from '../../Box';
import { FloatingUiChildProps, Popover, POPPER_THEME } from '../../Popper';
import { useTableContext } from '../useTableContext';
import TableFiltersTrigger from '../components/TableFiltersTrigger';

export interface IPropsFilters extends Partial<FloatingUiChildProps> {
  children?: JSX.Element;
  disabled?: boolean;
}

const popoverClassName = [
  'relative',
  'flex ',
  'flex-row ',
  'flex-wrap ',
  'gap-4',
  'bg-white ',
  'p-4',
  'max-h-[60vh]',
  'z-20 ',
  'w-screen ',
  'max-w-sm ',
  'bg-white',
  'rounded-lg',
  'shadow-lg',
  'overflow-auto',
];

export const TableFilters = React.forwardRef<HTMLButtonElement, IPropsFilters>(
  ({ children, disabled, ...rest }, ref) => {
    const table = useTableContext();
    const props = pick(rest, [
      'aria-controls',
      'aria-expanded',
      'aria-haspopup',
      'onClick',
      'onKeyDown',
      'onKeyUp',
      'onMouseDown',
      'onPointerDown',
    ]);
    const columnsWithFilters = table
      .getHeaderGroups()
      .map(headerGroup =>
        headerGroup.headers.filter(
          cell =>
            cell.column.getCanFilter() &&
            cell.column.columnDef.meta?.filter?.component,
        ),
      )
      .flat();

    return (
      <Popover
        popOnHover={false}
        theme={POPPER_THEME.light}
        showArrow={false}
        disabled={disabled}
        placement={'bottom-end'}
        render={() => (
          <Box className={popoverClassName}>
            {columnsWithFilters.map(cell => (
              <Box className={['min-w-full']} key={cell.column?.id}>
                {cell.column.columnDef.meta.filter?.component({
                  column: cell.column,
                  table,
                })}
              </Box>
            ))}
          </Box>
        )}
      >
        {children ? (
          React.cloneElement(children, {
            ...props,
            ...(children?.props || {}),
            ref,
          })
        ) : (
          <TableFiltersTrigger ref={ref} />
        )}
      </Popover>
    );
  },
);
