import { get } from 'lodash';
import { UserDeleteDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import { saveOrgData } from '../../actions/authenticateActions';

import * as types from '../../config/types';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.DELETE_USER) {
      makeRequest({
        type: types.DELETE_USER,
        mutation: UserDeleteDocument,
        variables: {
          userId: action.userId || null,
        },
        keys: [`${types.DELETE_USER}-${action.userId}`],
      }).then(res => {
        const userData = get(res, 'userDelete') || {};

        const error = res?.error || userData?.error?.message || userData?.error;
        if (error) {
          Toast.fire(error || 'Failed to delete profile', 'error');
          if (action.callback) action.callback();
        }

        const userOrg = get(state, 'user.org', {});
        const users = get(userOrg, 'users', []).filter(
          ({ id }) => id !== action.userId,
        );
        const org = {
          ...userOrg,
          users,
        };
        dispatch(saveOrgData(org));

        if (!action.preventSuccessToast)
          Toast.fire(userData?.message || 'Saved');
        if (!error && action.callback) action.callback(userData);
      });
    }

    return next(action);
  };
