import { adminReducer } from './lib/admin';
import { appOptimizeReducer } from './lib/appOptimize';
import { appResearchReducer } from './lib/appResearch';
import { documentsTablesReducer } from './lib/documentsTables';
import { loadingsReducer } from './lib/loadings';
import { miscReducer } from './lib/misc';
import { paymentReducer } from './lib/payment';
import { permissionsReducer } from './lib/permissions';
import { plansReducer } from './lib/plans';
import { savedViewsReducer } from './lib/savedViews';

export * as ACTION_TYPES from './lib/actionTypes';
export * as createStore from './lib/store';
export { default as ProviderMock } from './lib/store/components/ProviderMock';

export type { RootState } from './lib/types';
export { MISC_SIMPLE_KEYS } from './lib/types';

export const REDUCERS = {
  adminReducer,
  appOptimizeReducer,
  appResearchReducer,
  documentsTablesReducer,
  loadingsReducer,
  miscReducer,
  paymentReducer,
  permissionsReducer,
  plansReducer,
  savedViewsReducer,
};
