/**
 * Super class extended by tracking agents. Use this as an interface to
 * enforce agents to implement necessary classes, and for some common
 * functionality.
 */

import { get, isNil } from 'lodash';

class Agent {
  canInit(user, child) {
    if (!this.isUserAvailable(user)) {
      console.warn(
        'Failed attempt to initialize tracking agent ' +
          `${get(child, 'constructor.name')}: ` +
          'User information not available',
      );
      return false;
    }
    return true;
  }

  isUserAvailable(user) {
    return !isNil(get(user, 'id')) && !isNil(get(user, 'email'));
  }

  missingImplementation(method) {
    throw new Error(
      `Missing implementation for '${method}' method ` +
        'in one of the track agent classes.',
    );
  }

  // methods that must be implemented by agent classes
  init() {
    this.missingImplementation('init');
  }
  track() {
    this.missingImplementation('track');
  }
  clear() {
    this.missingImplementation('clear');
  }
  isReady() {
    this.missingImplementation('isReady');
  }
  page() {
    // optional
  }
  update() {
    // optional
  }
}

export default Agent;
