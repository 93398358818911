import Event from '../../Event';

class SubscribeToPlan extends Event {
  record({ plan, variant, coupon, invoice }) {
    // https://developers.google.com/tag-manager/ecommerce-ga4
    super.record('SubscribeToPlan', {
      category: 'Payment',
      newName: 'payment--subscribe-to-plan',
      mainValue: `payment--subscribe-to-plan:${variant}`,
      subscriptionDetails: {
        coupon,
        transaction_id: invoice,
        item_name: plan,
        item_id: variant,
        quantity: 1,
      },
    });
  }
}

export default new SubscribeToPlan();
