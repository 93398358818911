import { plansActionTypes } from '@marketmuse/data-state/plans';

export const getPlans = ({ skipAggregateRefresh, siteId }) => ({
  type: plansActionTypes.PLANS_FETCH,
  skipAggregateRefresh,
  siteId,
});

export const savePlan = ({ siteId, plan }) => ({
  type: plansActionTypes.PLAN_SAVE,
  payload: {
    siteId,
    plan,
  },
});

export const refreshPlans = ({ siteId, plans }) => ({
  type: plansActionTypes.PLANS_REFRESH,
  payload: {
    siteId,
    plans,
  },
});

export const deletePlans = ({ planIds, callback }) => ({
  type: plansActionTypes.PLANS_DELETE,
  planIds,
  callback,
});

export const plansDeleted = ({ planIds, siteId }) => ({
  type: plansActionTypes.PLANS_DELETED,
  payload: {
    planIds,
    siteId,
  },
});
