import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from '../Clickable';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  height: 32px;
  background-color: ${p => p.theme.input.default.backgroundColor};
  border-radius: 3px;
`;

const TotalCounterNumber = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.colors.grey85};
  font-weight: bold;
  font-size: 11pt;
  user-select: none;
`;

const TotalCounterButton = styled(Clickable)`
  width: 12px;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.mmxDarkGrey};
  font-weight: bold;
  user-select: none;
  ${p => p.disabled && `
    opacity: 0.2;
    &:hover { cursor: default !important; }
  `}
`;

const Clicker = props => (
  <Wrapper
    style={props.style}
    className={props.className}
  >
    <TotalCounterButton
      children="-"
      onClick={props.onDecrement}
      disabled={props.disableDecrement}
    />
    <TotalCounterNumber style={props.totalStyle}>
      {props.value}
    </TotalCounterNumber>
    <TotalCounterButton
      children="+"
      onClick={props.onIncrement}
      disabled={props.disableIncrement}
    />
  </Wrapper>
);

Clicker.propTypes = {
  value: PropTypes.any,
  disableIncrement: PropTypes.bool,
  disableDecrement: PropTypes.bool,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  style: PropTypes.object,
  totalStyle: PropTypes.object,
  className: PropTypes.string,
};

export default Clicker;
