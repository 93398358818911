import get from 'lodash/get';

import { SubscriptionUserCreateDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';
import { getUserData, saveUserData } from '../../actions/authenticateActions';
import { getRecurlySubscriptions } from '../../actions/recurlyActions';
import subscribeToPlan from '../../models/tracker/events/conversion/SubscribeToPlan';
import isPaymentTestModeActive from '../../utils/isPaymentTestModeActive';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.CREATE_USER_SUBSCRIPTION) {
      makeRequest({
        type: types.CREATE_USER_SUBSCRIPTION,
        mutation: SubscriptionUserCreateDocument,
        variables: {
          planCode: action.planCode,
          userInput: action.user,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              UseRecurlyTestCode: isPaymentTestModeActive(),
            },
          },
        },
      }).then(res => {
        if (get(res, 'error')) {
          Toast.fire(res.error || 'Failed to subscribe', 'error');

          if (typeof action.callbackError === 'function') {
            action.callbackError(res);
          }
        } else {
          const { sub, org } = get(res, 'createUserSubscription');
          subscribeToPlan.record({
            plan: action.planCode,
          });

          dispatch(
            getUserData({
              callback: userData => {
                dispatch(saveUserData(userData));
              },
            }),
          );

          dispatch(
            getRecurlySubscriptions({
              accountCode: get(state, 'user.org.recurlyAccountCode'),
              accountCodeTest: get(state, 'user.org.recurlyAccountCodeTest'),
            }),
          );

          if (typeof action.callback === 'function') {
            action.callback({ sub, org });
          }
        }
      });
    }

    return next(action);
  };
