import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useRecurlyGetCreditInvoicesQuery } from '@marketmuse/data-papi';

import isPaymentTestModeActive from '../../../../utils/isPaymentTestModeActive';
import {
  formatPlural,
  SUBSCRIPTION_TYPES_PLURAL_MAP,
} from '../../../../utils/pluralRules';
import DataTable from '../../../../components/DataTable';
import Dates from '../../../../components/Dates';
import { HOW_IT_WORKS_TYPES as TYPES } from '../constants';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${p => p.theme.type.heading3};
  margin-bottom: ${p => p.theme.spacing.medium};
`;

const TableWrapper = styled.div`
  max-height: 200px;
  height: 100%;
  overflow: hidden;
  display: flex;
  border-left: 1px solid ${p => p.theme.colors.grey15};
  border-right: 1px solid ${p => p.theme.colors.grey15};
  border-bottom: 1px solid ${p => p.theme.colors.grey15};
  div.mms--table-mask {
    max-height: 200px;
    > div {
      width: 100%;
    }
  }
`;

const MessageWrapper = styled.div`
  ${p => p.theme.type.textLarge}
  display: inline;
`;

const RecurlyBillingHistory = ({ type }) => {
  const siteId = useSelector(state => state.filter?.site?.id);
  const level = useSelector(state => state.filter?.site?.level);
  const useTestCode = isPaymentTestModeActive();
  const orgAccountCode = useSelector(
    state => state.user?.org?.recurlyAccountCode,
  );
  const orgAccountCodeTest = useSelector(
    state => state.user?.org?.recurlyAccountCodeTest,
  );

  const { data, loading } = useRecurlyGetCreditInvoicesQuery({
    skip: isEmpty(orgAccountCode) && isEmpty(orgAccountCodeTest),
    fetchPolicy: 'no-cache',
    variables: {
      siteId,
    },
    context: {
      headers: {
        UseRecurlyTestCode: useTestCode,
      },
    },
  });

  const creditInvoices = useMemo(() => {
    return (
      data?.recurlyGetCreditInvoices
        ?.map(({ lineItems }) => lineItems)
        .flat() || []
    );
  }, [data?.recurlyGetCreditInvoices]);

  const columns = useMemo(
    () => [
      {
        id: 'resource',
        header: 'resource',
        cell: ({ row }) => ({
          center: {
            component: (
              <div>
                {row.quantity}{' '}
                {formatPlural(
                  SUBSCRIPTION_TYPES_PLURAL_MAP[type].pluralString,
                  row.amount,
                  SUBSCRIPTION_TYPES_PLURAL_MAP[type].pluralMap,
                )}
              </div>
            ),
          },
        }),
      },
      {
        id: 'cost',
        header: 'cost',
        cell: ({ row }) => ({
          center: {
            component: <div>${row.amount}</div>,
          },
        }),
      },
      {
        id: 'issued',
        header: 'issued',
        cell: ({ row }) => ({
          center: {
            component: <Dates end={row.createdAt} dateFormat="MM/DD/YY" />,
          },
        }),
      },
      {
        id: 'expires',
        header: 'expires',
        cell: ({ row }) =>
          row.expiration
            ? {
                center: {
                  component: (
                    <Dates end={row.expiration} dateFormat="MM/DD/YY" />
                  ),
                },
              }
            : 'Never',
      },
    ],
    [],
  );

  const noIssuanceMessage =
    TYPES[type]?.emptyIssuanceMessage?.[level] ??
    TYPES[type]?.emptyIssuanceMessage.default;

  return (
    <div>
      <Header>Issuance</Header>
      {loading || creditInvoices?.length ? (
        <TableWrapper>
          <DataTable
            data={creditInvoices}
            columns={columns}
            loading={loading}
            onRowClick={() => {}}
          />
        </TableWrapper>
      ) : (
        <MessageWrapper>{noIssuanceMessage}</MessageWrapper>
      )}
    </div>
  );
};

RecurlyBillingHistory.propTypes = {
  type: PropTypes.oneOf(Object.keys(SUBSCRIPTION_TYPES_PLURAL_MAP)),
};

export default memo(RecurlyBillingHistory);
