import type {
  Breadcrumb,
  BreadcrumbHint,
  ErrorEvent,
  EventHint,
} from '@sentry/types';
import { get } from 'lodash';
import { parseDomain } from '@marketmuse/utilities';

export const ignoreErrors = [
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
];

export const denyUrls = [
  /api-v2\.mutinyhq\.io/i,
  /user-data.mutinycdn\.com/i,
  /grsm\.io/i,
  /partnerlinks\.io/i,
  /cdn\.linkedin\.oribi\.io/i,
  /content\.analytics\.marketmuse\.com/i,
  /data\.analytics\.marketmuse\.com/i,
  /optmnstr\.ms/i,
  /cdn\.madkudu\.com/i,
  // clarity
  /www\.clarity\.ms/i,
  /a\.clarity\.ms/i,
  /b\.clarity\.ms/i,
  /i\.clarity\.ms/i,
  /d\.clarity\.ms/i,
  /h\.clarity\.ms/i,
  /clarity\.ms/i,
  // full story
  /rs\.fullstory\.com/i,
  /edge\.fullstory\.com/i,
  // Facebook blocked
  /graph\.facebook\.com/i,
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions/i,
  /^chrome:\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i,
  /webappstoolbarba\.texthelp\.com/i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net/i,
  /google-analytics\.com/i,
  /stats\.g.doubleclick\.net/i,
  // Parative
  /vfs73ghowd.execute-api.us-west-2.amazonaws.com/i,
];
export const beforeBreadcrumb = (
  breadcrumb: Breadcrumb,
  hint?: BreadcrumbHint,
) => {
  if (
    [
      breadcrumb?.category === 'ui.click',
      breadcrumb?.message?.includes('LOG:GQL'),
      breadcrumb?.message?.includes('ChunkLoadError'),
      breadcrumb?.message?.includes('Out of retry attempts'),
      breadcrumb?.message?.includes(
        'is not a valid ObjectId, it must be a 12-byte input',
      ),
    ].some(Boolean)
  ) {
    return null;
  }

  if (['xhr', 'fetch'].includes(breadcrumb.category)) {
    const xhrDomain = parseDomain(get(breadcrumb, 'data.url'));

    if (denyUrls.find(url => url.test(xhrDomain))) {
      return null;
    }

    if (breadcrumb.data?.url?.includes('jwt')) {
      breadcrumb.data.url = xhrDomain;
    }
  }

  return breadcrumb;
};

export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  const error = hint.originalException;

  if (
    [
      event?.request?.headers['User-Agent'].includes('HeadlessChrome'),
      error?.message?.match(/ChunkLoadError/i),
      error?.message?.match(/Out of retry attempts/i),
      error?.stack?.match(/parative/i),
    ].some(Boolean)
  ) {
    return null;
  }

  return event;
};
