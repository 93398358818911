import { isNil } from 'lodash';
import { FC } from 'react';
import { Box, Text } from '@marketmuse/components';
import { ADD_TO_INVENTORY_ORIGIN } from '@marketmuse/research-data';
import { ActionPopper } from '../ActionPopper';
import { AddToInventoryCircle } from '../AddToInventoryCircle';
import { CellTopicProps } from './types';

const CellTopic: FC<CellTopicProps> = ({ item, actions, permissions }) => {
  const { term, volume, existsInInventory } = item;
  const {
    openCalendly,
    addToInventory: { handler: addToInventoryFn, loadingItems },
  } = actions;
  const { canAddToInventory } = permissions;

  if (isNil(term)) {
    return <span />;
  }

  return (
    <Box className={['flex', 'items-center', 'gap-x-2']}>
      <Text className={'font-semibold'}>{term || ''}</Text>
      <ActionPopper
        dataId={'add-to-inventory-circle'}
        title="Analyze your coverage against this topic with Premium."
        content={
          <>
            Upgrading gives you access to personalized insights on your content
            to help you plan better, analyze clusters and prioritize the right
            topics.{' '}
            <a
              href="https://marketmuse-1.wistia.com/medias/06jhz49718"
              target="_blank"
              rel="noreferrer"
            >
              <strong className="whitespace-nowrap">Learn more.</strong>
            </a>
          </>
        }
        popoverProps={{ disabled: canAddToInventory }}
        buttonProps={{
          text: 'Schedule Time',
          onClick: () => openCalendly(),
        }}
      >
        <AddToInventoryCircle
          term={term}
          existsInInventory={existsInInventory}
          loading={loadingItems[term as never]}
          disabled={!canAddToInventory}
          onClick={term => {
            if (canAddToInventory) {
              addToInventoryFn({
                items: [{ term, volume }],
                origin: ADD_TO_INVENTORY_ORIGIN.topicCircle,
              });
            }
          }}
        />
      </ActionPopper>
    </Box>
  );
};

export default CellTopic;
