import { useEffect } from 'react';

export default columns => {

  useEffect(() => {
    let checked = {};

    // columns should be an array
    if (!Array.isArray(columns)) {
      throw new Error(`Columns should be an array, got ${typeof columns} instead`)
    }

    // columns should not be empty
    if (columns.length === 0) {
      throw new Error('Columns cannot be empty')
    }
    
    columns.forEach(column => {
      const id = column.id;

      // check for ids
      if (!id) {
        throw new Error(`Column has no ID: ${JSON.stringify(column)}`)
      }

      // check for duplicate ids
      if (checked[id]) {
        throw new Error(`Duplicate column ID "${id}"`)
      }

      // set checked id
      checked[id] = true;
    })

  }, [columns])

}
