import md5 from 'md5';
import { errorsToString } from '@marketmuse/utilities';
import env from '../config/env';

export const initExternalLibs = () => {
  env.CDN_CSS.map(css => {
    const element = document.createElement('link');
    element.href = css;
    element.rel = 'stylesheet';
    element.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(element);
    return css;
  });

  env.CDN_JS.filter(Boolean).map(link => {
    const element = document.createElement('script');
    element.src = link;
    document.getElementsByTagName('body')[0].appendChild(element);
    return link;
  });
};

export const randomNumber = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const stripWWW = url => (url || '').replace(/www\./gi, '');
export const stripHttp = url => (url || '').replace(/(^\w+:|^)\/\//, '');
export const stripSlash = url => {
  let nextUrl = url;
  if (!url) {
    return url;
  }

  if (url.substr(-1) === '/') {
    nextUrl = url.substr(0, url.length - 1);
  }
  if (url.indexOf('/') === 0) {
    nextUrl = url.substr(1, url.length);
  }
  return nextUrl;
};

export const stripUrl = url => stripSlash(stripWWW(stripHttp(url)));

export const parseErrorResponse = (res = {}) => {
  let error = null;
  if (res.message) {
    error = res.message || '';
    error = error.replace('GraphQL error: ', '');
  } else {
    try {
      error = errorsToString(res.errors || []);
    } catch (e) {
      /** do nothing */
    }
  }
  return error || 'Unknown Error';
};

export const hash = (str = '') => md5((str || '').trim().toLowerCase());

export const serializeAccessibleSites = (sites = []) => {
  return sites
    .filter(site => site?.visible)
    .map(site => ({ ...site, title: site?.title || site?.domain }))
    .sort((a, b) => a.title.localeCompare(b.title));
};

export const run = (fn, ...args) => {
  if (typeof fn === 'function') {
    fn(...args);
  }
};

export const offsetToPage = (offset, limit) =>
  Math.floor(Math.max(1, offset) / limit);
