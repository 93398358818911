// onboarding data keys
// - provides base of onboarding data
export const ONBOARDING_DATA_KEYS = {
  naturalLanguage: 'naturalLanguage',
  cryptoCurrency: 'cryptoCurrency',
  startingABlog: 'startingABlog',
  homeBaking: 'homeBaking',
  softwareAntivirus: 'softwareAntivirus',
  manhattanRestaurants: 'manhattanRestaurants',
  smbWebinar: 'smbWebinar',
  budgetingMethods: 'budgetingMethods',
  mattressComparison: 'mattressComparison',
  travelSeatingChoices: 'travelSeatingChoices',
  tableTopGaming: 'tableTopGaming',

  breadRecipe: 'breadRecipe',
  localVets: 'tableTopGaming',
  bestWashingMachine: 'bestWashingMachine',
  bestPriceAirpods: 'bestPriceAirpods',
  growTomatoes: 'growTomatoes',
};
