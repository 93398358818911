import React, { memo } from 'react';
import styled, { withTheme } from 'styled-components';

import SimpleGrid from '../../../../components/SimpleGrid';
import IconSvg from '../../../../components/IconSvg';

const BlockWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 20px 0 0 0;
  border-radius: ${p => p.theme.borderRadius};
  border: 1px solid ${p => p.theme.colors.grey15};
  position: relative;
  box-shadow: ${p => p.theme.boxShadowLow};
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: 4px;
    max-height: ${p => p.theme.spacing.xSmall};
    left: -1px;
    top: -1px;
    border-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${p => p.color && (p.theme.colors[p.color] || p.color)};
  }
`;
const Wrapper = styled.div`
  width: 100%;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    ${p => p.theme.type.heading1}
    font-weight: bold;
    font-size: 54px;
    line-height: 56px;
    margin: ${p => p.theme.spacing.xSmall} 0;
  }
  div {
    margin-bottom: ${p => p.theme.spacing.xSmall};
    ${p => p.theme.type.textSmall}
    &.description {
      font-style: italic;
      color: ${p => p.theme.colors.grey70};
    }
  }
`;

const SummaryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${p => p.theme.type.heading3};
  span {
    margin-right: ${p => p.theme.spacing.xSmall};
  }
`;

const SummaryBlock = ({ firstColumn, secondColumn, icon, title }) => (
  <Wrapper>
    <SummaryTitle>
      {icon?.name && <IconSvg name={icon.name} color={icon.color} size="16" />}
      <div>{title}</div>
    </SummaryTitle>
    <BlockWrapper color={icon.color}>
      <SimpleGrid.Container gap={'32px'} columns={2} padding="20px">
        <SimpleGrid.Item span={{ initial: 2, sm: 1 }}>
          <ItemContent>{firstColumn}</ItemContent>
        </SimpleGrid.Item>
        {secondColumn && (
          <SimpleGrid.Item span={{ initial: 2, sm: 1 }}>
            <ItemContent>{secondColumn}</ItemContent>
          </SimpleGrid.Item>
        )}
      </SimpleGrid.Container>
    </BlockWrapper>
  </Wrapper>
);

export default memo(withTheme(SummaryBlock));
