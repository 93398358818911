import get from 'lodash/get';
import { GetInventoryVersionsDocument } from '@marketmuse/data-papi';

import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const siteId = action.siteId;
    if (action.type === types.GET_INVENTORY_VERSIONS && siteId) {
      // Earliest invVersion
      const startTime = action.startTime || new Date('2019-03-01');

      makeRequest({
        type: types.GET_INVENTORY_VERSIONS,
        mutation: GetInventoryVersionsDocument,
        options: { isQuery: true },
        variables: {
          siteId,
          startTime: startTime.toISOString().slice(0, -5),
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        const data = get(res, 'inventoryVersions');

        if (data && typeof action.callback === 'function') {
          action.callback(data);
        }
      });
    }

    return next(action);
  };
