import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { SiteLevel } from '@marketmuse/config/types/papi';
import { PAPI } from '@marketmuse/config/types';

import SimpleGrid from '../../../../components/SimpleGrid';
import useHasAccess from '../../../../hooks/useHasAccess';

import HowItWorks from '../components/HowItWorks';
import QueriesPurchaseForm from '../components/QueriesPurchaseForm';
import QueriesSummary from '../components/QueriesSummary';

const QueriesBalance = () => {
  const level = useSelector(state => state.filter?.site?.level);
  const canBuyQueries = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_APP_RUNS,
  });
  const showUpgradeButton =
    [SiteLevel.STANDARD, SiteLevel.FREE].includes(level) || !level;
  return (
    <SimpleGrid.Container gap={'32px'} columns={2} padding="0">
      <SimpleGrid.Item span={{ initial: 2, md: 1 }}>
        <QueriesSummary showUpgradeButton={showUpgradeButton} />
      </SimpleGrid.Item>
      <SimpleGrid.Item span={{ initial: 2, md: 1 }}>
        {canBuyQueries && <QueriesPurchaseForm />}
      </SimpleGrid.Item>
      <SimpleGrid.Item span={{ initial: 2 }}>
        <HowItWorks type="query" />
      </SimpleGrid.Item>
    </SimpleGrid.Container>
  );
};

export default memo(QueriesBalance);
