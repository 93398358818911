import styled from 'styled-components';

export const Table = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
  width: max-content;

  ${p => p.hasStickyColumns
    ? `width: ${p.totalTableWidth}px; min-width: 100%;`
    : ''}
`;
