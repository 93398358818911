import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Clickable from './../../Clickable/Clickable';

const Wrapper = styled(Clickable)`
  ${p => p.theme.truncate}
  padding: 6px 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 4px;
  background-color: white;
  ${p => p.disabled && `
    color: ${p.theme.colors.grey15};
  `}
  ${p => !p.disabled && `
    color: ${p.theme.colors.blueDark1};
    &:hover { background-color: ${p.theme.colors.blueLight2}; }
  `}
`;

const CellCTA = ({ withResting, children, style, disabled, onClick }) => (
  <Wrapper
    disabled={disabled}
    className={withResting ? 'mms--dt-resting' : ''}
    style={style}
    onClick={e => {
      e.stopPropagation();
      e.preventDefault();
      if (typeof onClick === 'function') onClick(e);
    }}
  >
    {children}
  </Wrapper>
);

CellCTA.propTypes = {
  // buttons will look faded unless row has hover
  // https://www.figma.com/file/ltEMItVh1cmC8aGVPLpWAK/feature--plan-item-views?node-id=214%3A0
  withResting: PropTypes.bool,

  children: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CellCTA.defaultProps = {
  withResting: true,
}

export default CellCTA;
