import React from 'react';
import PropTypes from 'prop-types';
import { TRBody, TRBodyClickable } from './styleTR';

const TRBodyComponent = ({
  row,
  children,
  onRowClick,
  onRowContextMenu,
  hiddenHeader,
  rowClickDisabled,
  loading,
  isSubRow,
  isLastSubRow,
  index
}) => {

  let isCurrentRowClickDisabled = false;
  if (typeof rowClickDisabled === 'object') {
    isCurrentRowClickDisabled = !!rowClickDisabled[row.id]
  } else {
    isCurrentRowClickDisabled = !!rowClickDisabled;
  }

  const isClickable = (
    typeof onRowClick === 'function' &&
    !isCurrentRowClickDisabled &&
    !loading
  );

  const isContextable = (
    typeof onRowContextMenu === 'function' &&
    !isCurrentRowClickDisabled &&
    !loading
  );
  
  const UseComponent = isClickable
    ? TRBodyClickable
    : TRBody;
  
  return (
    <UseComponent
      data-mms--datatable-row={index}
      isSubRow={isSubRow}
      isLastSubRow={isLastSubRow}
      hiddenHeader={hiddenHeader}
      onContextMenu={!isContextable ? null : event => {
        event.nativeEvent.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
        onRowContextMenu({ event, row })
      }}
      onClick={!isClickable ? null : e => {
        // blur after click
        e.currentTarget.blur();
        onRowClick({ row })
      }}
    >
      {children}
    </UseComponent>
  );
};

TRBodyComponent.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  isSubRow: PropTypes.bool,
  isLastSubRow: PropTypes.bool,
  row: PropTypes.object, // <- raw row data
  rowProps: PropTypes.object, // <- output of prepareRow
  hiddenHeader: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowContextMenu: PropTypes.func,
  rowClickDisabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
};

export default TRBodyComponent;
