import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { SiteLevel } from '@marketmuse/config/types/papi';
import {
  useStoreSelector as useSelector,
  useStoreDispatch as useDispatch,
} from '@marketmuse/data-state/hooks';

import * as miscActions from '../../../actions/miscActions';
import Banner from './Banner';

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: ${p => (p.showOverflow ? 'auto' : 'hidden')};
`;

const POWER_UP_PAGE_ACTIVE = 'POWER_UP_PAGE_ACTIVE';

const PowerUps = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const site = useSelector(state => state.filter.site);
  const sites = useSelector(state => state?.user?.org?.sites);

  const isOneAppSumoSite = useMemo(() => {
    if (Array.isArray(sites) && sites.length === 1) {
      return sites[0].level === SiteLevel.APPSUMO && !sites[0].cancelled;
    }

    return false;
  }, [sites]);

  const showBanner = useMemo(
    () => ![SiteLevel.PREMIUM, SiteLevel.APPSUMO].includes(site.level),
    [site],
  );

  const onUpgrade = useCallback(() => {
    dispatch(
      miscActions.toggleDynamic({
        id: 'subscriptionManagement',
        status: true,
        data: {
          initialScreen: isOneAppSumoSite ? 'paymentsUpdate' : 'plans',
          isNewSubscription: isOneAppSumoSite,
          isNewSite: isOneAppSumoSite,
        },
      }),
    );
  }, [dispatch, isOneAppSumoSite]);

  const url = useMemo(() => {
    const org = user.org;
    const params = {
      userId: user?.id,
      userEmail: user?.email,
      userSFDCId: user?.sfdcContactId,
      userFullName: user?.fullName,
      userFirstName: user?.firstName,
      userLastName: user?.lastName,
      siteDomain: site?.domain,
      siteId: site?.id,
      siteLevel: site?.level,
      siteBriefCredits: site?.briefCredits,
      orgId: org?.id,
      orgName: org?.name,
      orgSFDCId: org.sfdcAccountId,
    };
    const cleanParams = Object.entries(params).reduce((acc, entry) => {
      const [key, value] = entry;
      if (value) {
        return { ...acc, [key]: value };
      } else {
        return acc;
      }
    }, {});
    const queryParams = new URLSearchParams(cleanParams).toString();

    const pageSource = 'https://www.marketmuse.com/power-ups-page/';
    return `${pageSource}?${queryParams}`;
  }, [user, site]);

  useEffect(() => {
    dispatch(miscActions.setSimple({ key: POWER_UP_PAGE_ACTIVE, value: true }));

    return () => {
      dispatch(
        miscActions.setSimple({ key: POWER_UP_PAGE_ACTIVE, value: false }),
      );
    };
  }, [dispatch]);

  return (
    <Wrapper showOverflow={showBanner}>
      {showBanner && <Banner onClick={onUpgrade} />}
      <Iframe title="Power ups" src={url} />
      {showBanner && <Banner onClick={onUpgrade} />}
    </Wrapper>
  );
};

export const Component = PowerUps;

export default PowerUps;
