import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Text, toast } from '@marketmuse/components';
import { makeSuitePath } from '@marketmuse/utilities';

const useToaster = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const addedToInventoryToast = useCallback(
    (planId: string) => {
      const url = makeSuitePath({
        pathname,
        route: 'inventory/planning',
        suffix: planId,
      });

      toast.success(
        <>
          <Text>Items have been added to your inventory.</Text>
          <Button size="xs" variant="blue400" onClick={() => navigate(url)}>
            View Inventory
          </Button>
        </>,
      );
    },
    [navigate, pathname],
  );

  const inventoryNotReady = () => {
    toast.neutral(
      <Text>
        Sorry about this, but your inventory isn't yet ready for use.
      </Text>,
    );
  };

  const premiumInventoryToast = () => {
    toast.neutral(
      <Text>
        Inventory is only available to Premium Users. To gain access to your own
        inventory and personalized insights, subscribe to our Premium plan.
      </Text>,
    );
  };

  const premiumFeatureToast = () => {
    toast.neutral(
      <>
        <Text className="font-bold">This is a premium feature</Text>
        <Text as="p">
          Get access to more workflows, briefs, and content. Talk to a
          strategist today to unlock even more power from MarketMuse.
        </Text>
      </>,
    );
  };

  const exportSuccessfulToast = useCallback(() => {
    toast.success(
      <Text>Export successful. Your download will begin shortly.</Text>,
    );
  }, []);

  return {
    addedToInventoryToast,
    inventoryNotReady,
    exportSuccessfulToast,
    premiumInventoryToast,
    premiumFeatureToast,
  };
};

export default useToaster;
