import React, { memo, useCallback } from 'react';
import { lowerCase, upperCase } from 'lodash';

import { getCountryEnum } from '@marketmuse/utilities';
import { useStoreDispatch } from '@marketmuse/data-state/hooks';
import { toggleDynamic } from '../../../../actions/miscActions';

import PageHeader from '../../../../components/Page/PageHeader';
import IconSvg from '../../../../components/IconSvg';
import { AppAutocomplete } from '../../../../components/AppAutocomplete';
import SimpleTextInput from '../../../../components/SimpleTextInput';
import SimpleSiteInput from '../../../../components/SimpleSiteInput';

import { VIEWS as PAYMENT_SETTINGS_VIEWS } from '../../../../config/payment/settingsModal';

import AppRunTrigger from '../AppRunTrigger';
import { OmnibarInputs, OmnibarProps } from './types';

const Omnibar = ({
  app,
  canSubmit,
  children,
  iconName,
  loading,
  onSubmit,
  setValues,
  title,
  values,
  style,
  canSubmitText,
}: OmnibarProps) => {
  const dispatch = useStoreDispatch();

  const setData = useCallback(
    (data: OmnibarInputs) => {
      setValues({
        ...values,
        ...data,
      });
    },
    [setValues, values],
  );

  const setValue = useCallback(
    (key, value) => {
      setValues({
        ...values,
        [key]: value,
      });
    },
    [setValues, values],
  );

  const handlePress = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && canSubmit) {
        onSubmit();
      }
    },
    [canSubmit, onSubmit],
  );

  return (
    <PageHeader
      icon={<IconSvg name={iconName} size={16} />}
      title={title}
      headerProps={{
        'data-mms--app-header': app,
      }}
      style={style}
    >
      {[
        {
          style: { flexGrow: 1, flexShrink: 1, maxWidth: 480 },
          children: (
            <AppAutocomplete
              key="appAutocomplete"
              fullWidth
              offset={-8}
              padding={10}
              activeCountry={lowerCase(values.country)}
              activeLanguage={lowerCase(values.language)}
              setCountryLanguage={({ country, language }) =>
                setValues({
                  country: getCountryEnum(country),
                  language: upperCase(language),
                })
              }
              setValue={value => setValue('term', value)}
              popperProps={{
                flex: true,
                style: { height: '100%' },
                items: [],
              }}
              onSubmit={({ data }) =>
                setData({
                  term: data.topic,
                  url: data.url,
                  country: getCountryEnum(data.country),
                  language: upperCase(data.language),
                })
              }
              inputComponent={SimpleTextInput}
              style={{ width: '100%' }}
              isClosed={true}
              inputProps={{
                name: 'term',
                placeholder: 'Enter a topic',
                value: values.term || '',
                onChange: event => setValue('term', event.target.value),
                onKeyPress: handlePress,
                style: {
                  marginLeft: 18,
                  width: 'calc(100% - 18px)',
                },
              }}
            />
          ),
        },
        {
          style: { flexGrow: 1, flexShrink: 1, maxWidth: 400 },
          children: (
            <SimpleSiteInput
              offset={-2}
              padding={10}
              setValue={value => setValue('url', value)}
              style={{ width: '100%', marginLeft: 12 }}
              inputComponent={SimpleTextInput}
              inputProps={{
                'data-mms--page-header-input': 'url',
                placeholder: 'Enter URL or search by title / URL (optional)',
                value: values.url || '',
                onChange: event => setValue('url', event.target.value),
                onKeyPress: handlePress,
              }}
            />
          ),
        },
        {
          style: { paddingRight: 20 },
          children: (
            <AppRunTrigger
              key="runApp"
              onRun={onSubmit}
              canRun={canSubmit}
              canRunText={canSubmitText}
              loading={loading}
              onBuyAppRuns={() =>
                dispatch(
                  toggleDynamic({
                    id: 'paymentSettingsModal',
                    status: true,
                    data: { activeScreen: PAYMENT_SETTINGS_VIEWS.queries },
                  }),
                )
              }
            />
          ),
        },
        ...(children ? children : []),
      ]}
    </PageHeader>
  );
};

export default memo(Omnibar);
