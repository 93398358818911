import cx from 'classnames';
import { CustomFilterProps } from '@tanstack/react-table';

import { Box } from '../../Box';

// Define a default UI for filtering
export function FilterList({ column }: CustomFilterProps) {
  const id = column.id;
  const htmlId = `table-filter--column-${id}`;
  const filterValue = column.getFilterValue() || [];
  const title = column.columnDef?.meta?.filter?.title;
  const selectionMethod = column.columnDef?.meta?.filter?.selection;

  return (
    <Box className={['flex', 'flex-col', 'gap-2']}>
      <label
        className={cx(['block', 'text-base', 'font-bold', 'text-gray-700'])}
        htmlFor={htmlId}
      >
        {title || `Filter ${id as string}`}
      </label>
      <select
        id={htmlId}
        className={cx([
          'shadow-sm',
          'focus:ring-blue-400',
          'focus:border-blue-400',
          'block',
          'w-full',
          'sm:text-sm',
          'border-gray-400',
          'rounded',
        ])}
        onChange={event => {
          const options = Array.from(event.target.options)
            .filter(option => option.selected)
            .map(option => option.value);
          column.setFilterValue(options);
        }}
        {...(selectionMethod === 'multiple' && {
          multiple: true,
          value: filterValue,
        })}
        {...(selectionMethod === 'single' && {
          value: filterValue[0],
        })}
      >
        {selectionMethod === 'single' && (
          <option key="empty" value="">
            Select...
          </option>
        )}

        {(column.columnDef?.meta?.filter?.options || []).map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Box>
  );
}
