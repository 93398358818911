import get from 'lodash/get';
import { recurlyPlansInfo as plansInfoDefault } from '@marketmuse/config/configs';
import { RECURLY } from '@marketmuse/config/types';
import { formatPlural } from '../pluralRules';

const planNameDefault = RECURLY.PLAN_NAME.STANDARD;
const planIntervalDefault = 'monthly';

const getPlanDetails = ({
  planName = planNameDefault,
  planInterval = planIntervalDefault,
}) => {
  return (
    get(plansInfoDefault, `[${planName}][${planInterval}].planDetails`) || {}
  );
};

export const formatCouponNote = ({
  duration,
  discountPrice,
  discountDuration,
  discountDurationUnit,
  planPrice,
  planInterval,
}) => {
  const durationText = formatPlural(discountDurationUnit, discountDuration);

  if (duration === 'single_use') {
    const firstTerm = planInterval === 'monthly' ? 'month' : 'year';
    return `You will be charged $${discountPrice} for the first ${firstTerm}, then $${planPrice} on every renewal afterwards, unless you cancel it.`;
  } else if (duration === 'temporal') {
    if (planInterval === 'monthly') {
      return `You will be charged $${discountPrice} monthly for the first ${discountDuration} ${durationText}, then $${planPrice} on every renewal afterwards, unless you cancel it.`;
    } else {
      return `You will be charged $${discountPrice} for the first ${discountDuration} ${durationText}, then $${planPrice} on every renewal afterwards, unless you cancel it.`;
    }
  } else if (duration === 'forever') {
    const termAdverb = planInterval === 'monthly' ? 'monthly' : 'annually';
    return `You will be charged $${discountPrice} ${termAdverb} until you cancel your subscription. You can cancel at any time.`;
  }
};

export default getPlanDetails;
