import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table } from './styleTable';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  min-width: 100%;
  background-color: white;

  ${p => p.hiddenHeader && `
    border-top: 1px solid ${p.theme.colors.grey15};
  `}

  ${p => (p.hasStickyColumns || p.hasStickyHeader) && `
    overflow: auto;
    height: 100%;
  `}
`;

const TableComponent = ({
  data,
  columns,
  columnsComputed,
  style,
  hiddenHeader,
  hasStickyHeader,
  hasStickyColumns,
  renderOnlyContents,
  loading,
  onRowClick,
  onRowContextMenu,
  rowClickDisabled,
  totalTableWidth,
  tbBodyStyle,
  tbHeaderStyle,
  getSubRows,
  expandedRows,
  sort,
  selection
}) => {
  // render only table contents
  if (renderOnlyContents) {
    return (
      <>
        <TableHeader
          data={data}
          columns={columns}
          loading={loading}
          tbHeaderStyle={tbHeaderStyle}
          hasStickyHeader={hasStickyHeader}
          columnsComputed={columnsComputed}
          sort={sort}
        />
        <TableBody
          data={data}
          columns={columns}
          loading={loading}
          tbBodyStyle={tbBodyStyle}
          selection={selection}
          hiddenHeader={hiddenHeader}
          onRowClick={onRowClick}
          onRowContextMenu={onRowContextMenu}
          rowClickDisabled={rowClickDisabled}
          columnsComputed={columnsComputed}
          getSubRows={getSubRows}
          expandedRows={expandedRows}
        />
      </>
    );
  }

  return (
    <Wrapper
      style={style}
      className="mms--table-mask"
      hasStickyHeader={hasStickyHeader}
      hasStickyColumns={hasStickyColumns}
      hiddenHeader={hiddenHeader}
    >
        <Table
          hasStickyHeader={hasStickyHeader}
          hasStickyColumns={hasStickyColumns}
          totalTableWidth={totalTableWidth}
        >
          {!hiddenHeader && <TableHeader
            data={data}
            columns={columns}
            loading={loading}
            tbHeaderStyle={tbHeaderStyle}
            hasStickyHeader={hasStickyHeader}
            columnsComputed={columnsComputed}
            sort={sort}
          />}
          <TableBody
            data={data}
            columns={columns}
            loading={loading}
            selection={selection}
            tbBodyStyle={tbBodyStyle}
            hiddenHeader={hiddenHeader}
            onRowClick={onRowClick}
            onRowContextMenu={onRowContextMenu}
            rowClickDisabled={rowClickDisabled}
            columnsComputed={columnsComputed}
            getSubRows={getSubRows}
            expandedRows={expandedRows}
          />
        </Table>
    </Wrapper>
  );
};

TableComponent.propTypes = {
  rows: PropTypes.array,
  headerGroups: PropTypes.object,
  getTableProps: PropTypes.func,
  getTableBodyProps: PropTypes.func,
  totalTableWidth: PropTypes.number,
  prepareRow: PropTypes.func,
  style: PropTypes.object,
  hiddenHeader: PropTypes.bool,
  hasStickyHeader: PropTypes.bool,
  hasStickyColumns: PropTypes.bool,
  renderOnlyContents: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowContextMenu: PropTypes.func,
  rowClickDisabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.object ]),
  getSubRows: PropTypes.func,
  expandedRows: PropTypes.object,
  selection: PropTypes.object,
};

export default memo(TableComponent);
