import styled, { css } from 'styled-components';
import Clickable from './../../Clickable/Clickable';

const common = css`
  display: flex;
  flex: 1 0 auto;
  min-width: 0;
  background-color: white;
  border-top: 1px solid ${p => p.theme.colors.grey15};

  &:last-child {
    border-bottom: 1px solid ${p => p.theme.colors.grey15};
  }
`;

export const TRHead = styled.div`
  ${common};

  border-top: none;
  &:first-child {
    border-top: 1px solid ${p => p.theme.colors.grey15};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const trBodyCommon = css`
  ${p => p.hiddenHeader && `
    &:first-child { border-top: none; }
  `}

  /**
   * items with resting state enabled should have a
   * transparent bg and faded color when not hovered
   */
  &:not(:hover) {
    .mms--dt-resting {
      background-color: transparent !important;
      color: ${p => p.theme.colors.grey15} !important;
    }
  }

  /**
   * items to be shown only when hovered on the row
   */
  &:not(:hover) {
    .mms--td-showOnhover {
      visibility: hidden;
    }
  }

  /**
   * Subrows
   */
  ${p => p.isSubRow && ``}
  ${p => p.isLastSubRow && `
    border-bottom: 2px solid ${p.theme.colors.grey25};
  `}
`;

export const TRBody = styled.div`
  ${common};
  ${trBodyCommon};
`;

export const TRBodyClickable = styled(Clickable)`
  ${common};
  ${trBodyCommon};

  &:hover,
  &:focus {
    position: relative;
    background-color: rgb(250, 250, 250);
    box-shadow: ${p => p.theme.moonShadow};
  }
`;
