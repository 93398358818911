import { get } from 'lodash';

import { AddMemberDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import makeRequest from '../../utils/makeRequest';
import { saveOrgData } from '../../actions/authenticateActions';
import * as types from '../../config/types';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.ADD_MEMBER) {
      const { fields, orgId } = action;

      makeRequest({
        type: types.ADD_MEMBER,
        mutation: AddMemberDocument,
        variables: {
          orgId: orgId || get(state, 'user.org.id'),
          fields: fields,
        },
      }).then(res => {
        const error = res.error || !get(res, 'addMember');

        if (error) {
          Toast.fire(error || 'Unknown error', 'error');
        } else {
          const org = get(res, 'addMember.org');
          if (org?.id) {
            // update state if updated org is users own org
            if (org.id === get(state, 'user.org.id')) {
              dispatch(saveOrgData(org));
            }
            Toast.fire(`User successfully added.`);
            if (action.callback) action.callback(org);
          }
        }
      });
    }

    return next(action);
  };
