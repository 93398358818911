import styled, { css } from 'styled-components';

const common = css``;

export const THead = styled.div`
  ${common};

  border-bottom: 1px solid ${p => p.theme.colors.grey15};
  margin-bottom: -1px;

  ${p => p.hasStickyHeader && `
    position: sticky;
    z-index: 9;
    top: 0;
  `}
`;

export const TBody = styled.div`
  ${common};
`;
