import get from 'lodash/get';
import { GetInventoryVersionDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';
import makeRequest from '../../utils/makeRequest';
import * as types from '../../config/types';

export default () =>
  ({ getState }) =>
  next =>
  action => {
    const state = getState();

    const siteId = action.siteId || get(state, 'filter.site.id');
    if (siteId && action.type === types.GET_INVENTORY_VERSION) {
      makeRequest({
        type: types.GET_INVENTORY_VERSION,
        mutation: GetInventoryVersionDocument,
        options: { isQuery: true },
        variables: {
          siteId,
        },
        apolloOptions: {
          fetchPolicy: 'no-cache',
        },
      }).then(res => {
        if (res.error || res.errors) {
          Toast.fire(res.error || 'Failed to get info', 'error');
        } else {
          const data = get(res, 'inventoryVersion');
          if (typeof action.callback === 'function') {
            action.callback(data);
          }
        }
      });
    }

    return next(action);
  };
