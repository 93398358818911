import { Suspense } from 'react';
import { lazyLoad } from '@marketmuse/utilities';

import ScreenLoader from '../components/ScreenLoader';
import { NoMatch } from './_components/NoMatch';

const Organizations = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'Organizations' */ '../containers/Admin/Orgs/Organizations'
    ),
);

const OrganizationSingle = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'OrgCard' */ '../containers/Admin/Orgs/Organization'
    ),
);

const DetailsScreen = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'DetailScreen' */ '../containers/Inventory/DetailsScreen'
    ),
);

const FormBuilder = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'FormBuilder' */ '../containers/Admin/FormBuilder/FormBuilder'
    ),
);

const FormBuilderPreview = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'FormBuilderPreview' */ '../containers/Admin/FormBuilder/FormBuilderPreview'
    ),
);

const ReportsManager = lazyLoad(
  () =>
    import(
      /* webpackChunkName: 'ReportsManager' */ '../containers/Admin/ReportsManager/ReportsManager'
    ),
);

export const routes = [
  {
    path: 'orgs',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <Organizations />
      </Suspense>
    ),
  },
  {
    path: 'orgs/:orgId',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <OrganizationSingle />
      </Suspense>
    ),
  },
  {
    path: 'form-builder',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <FormBuilder />
      </Suspense>
    ),
  },
  {
    path: 'form-builder/preview/:reportId',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <FormBuilderPreview />
      </Suspense>
    ),
  },
  {
    path: 'reports-manager/:reportType?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <ReportsManager />
      </Suspense>
    ),
  },
  {
    path: ':routeDomain/:entityType/:briefId/:briefScreen?',
    element: (
      <Suspense fallback={<ScreenLoader.Light unwrapped={true} />}>
        <DetailsScreen showManagerControls />
      </Suspense>
    ),
  },

  {
    path: '*',
    element: <NoMatch />,
  },
];
