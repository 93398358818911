import React from 'react';
import PropTypes from 'prop-types';

import { THead } from './styleTB';

const THeadComponent = ({ style, children, hasStickyHeader }) => (
  <THead
    style={style}
    hasStickyHeader={hasStickyHeader}
    className="mms--table-header"
  >
    {children}
  </THead>
);

THeadComponent.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,

  // should header be sticky
  hasStickyHeader: PropTypes.bool,

};

export default THeadComponent;
