import * as types from '../config/types';

export const createReport = ({
  reportType,
  term,
  url,
  selfServe,
  siteId,
  callback,
}) => ({
  type: types.CREATE_REPORT,
  reportType,
  term,
  url,
  selfServe,
  siteId,
  callback,
});

export const generateReport = args => ({
  type: types.GENERATE_REPORT,
  ...args,
});

export const readReport = ({ id, options, callback }) => ({
  type: types.READ_REPORT,
  id,
  options,
  callback,
});

export const readReports = args => ({
  type: types.READ_REPORTS,
  ...args,
});

export const readReportsOverTime = args => ({
  type: types.READ_REPORTS_OVER_TIME,
  ...args,
});
export const updateReport = ({ id, data, keys, callback }) => ({
  type: types.UPDATE_REPORT,
  id,
  data,
  keys,
  callback,
});

export const updateReportFormData = ({
  id,
  reportType,
  data,
  options,
  returnParams,
  callback,
}) => ({
  type: types.UPDATE_REPORT_FORM_DATA,
  id,
  reportType,
  data,
  options,
  returnParams,
  callback,
});

export const deleteReport = ({ reportId, callback }) => ({
  type: types.DELETE_REPORT,
  reportId,
  callback,
});

// reports-api application logic
export const addSubheading = ({ id, value, callback }) => ({
  type: types.ADD_SUBHEADING,
  briefId: id,
  subheading: value,
  callback,
});

export const getQuestionsData = args => ({
  type: types.GET_QUESTIONS_DATA,
  ...args,
});
