import { memo } from 'react';
import styled from 'styled-components';
import { Badge, Icon, SUPPORTED_ICON, Text, Box } from '@marketmuse/components';
import { useStoreSelector } from '@marketmuse/data-state/hooks';

import Portal from '../../../components/Portal';

// import { addG2Conversion, addRewardfulConversion } from '../utils/trackers';

import Modal, { ModalBody } from '../../../components/Modal';
import { SubscriptionLadder } from './SubscriptionLadder';

const ModalStyled = styled(Modal)`
  position: relative;
  z-index: 2000;
`;

const ModalBodyStyled = styled(ModalBody)`
  width: fit-content;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding: ${p => p.theme.spacing.xLarge};
  display: flex;
  column-gap: 80px;
`;

const standardFeatures = [
  'Unlimited use Optimize and Research',
  'Inventory with 1000 topics',
  'Full application data for deeper insights',
  'Data export feature for easy data transfer',
  '10 Briefs, and 3 Planning or Analysis Documents per month',
  'Add more users, or increase your Inventory size at any time',
];

const TrialExpiredModal = () => {
  const siteId = useStoreSelector(state => state.filter?.site?.id);
  const subscriptionId = useStoreSelector(
    state => state?.payment?.subscription?.id,
  );

  return (
    <Portal>
      <ModalStyled
        closeStyle={{ zIndex: 999 }}
        hideClose={true}
        backdropAttrs={{ style: { zIndex: 999999999 } }}
        useDiv
        style={{ width: 'unset' }}
      >
        <ModalBodyStyled
          onKeyDown={event => event.key === 'Enter' && event.stopPropagation()}
          className="mm-tw"
        >
          <Box className={['flex', 'flex-col', 'justify-center']}>
            <Badge color="red" shade={'light'} size={'base'}>
              <Box className={['flex', 'gap-x-2', 'items-center']} as="span">
                <Icon
                  name={SUPPORTED_ICON.warning_triangle}
                  color="text-red-400"
                />
                <Text>
                  Your free trial has expired. From now on your features will be
                  limited.
                </Text>
              </Box>
            </Badge>
            <Box className={['flex', 'flex-col']}>
              <Text className={['text-6xl', 'font-bold', 'mt-6']}>
                Upgrade to Research.
              </Text>
              <Text className={['text-2xl', 'mt-6']}>
                Continue enjoying access to the following features:
              </Text>
              <Box className={['flex', 'flex-col', 'gap-y-6', 'mt-6']}>
                {standardFeatures.map((features, index) => {
                  return (
                    <Box key={index} className={['flex', 'gap-x-6']}>
                      <Icon
                        name={SUPPORTED_ICON.check_circle}
                        color="text-green-400"
                        boxSize={28}
                      />
                      <Text className={['text-xl']}>{features}</Text>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>

          <SubscriptionLadder
            subscriptionId={subscriptionId}
            siteId={siteId || ''}
            wrapperClassNames={[
              'flex',
              'flex-col',
              'gap-4',
              'w-[320px]',
              'justify-center',
            ]}
            dataTags={{
              ctaUpgrade: {
                'data-mms--standard-trial-paywall-action': 'upgrade',
                'data-mms--trial-paywall-action': 'upgrade',
              },
              ctaDowngrade: {
                'data-mms--standard-trial-paywall-action': 'exit-to-free',
                'data-mms--trial-paywall-action': 'exit-to-free',
              },
            }}
          />
        </ModalBodyStyled>
      </ModalStyled>
    </Portal>
  );
};

export default memo(TrialExpiredModal);
