import { useEffect, useState } from 'react';
import { captureMessage } from '@sentry/react';
import { useSession, useSessionList } from '@clerk/clerk-react';
import { TOKEN_KEYS } from '@marketmuse/config/configs';
import { toast } from '@marketmuse/components';

import useLogout from '../../hooks/auth/useLogout';

const EXPIRED = 'Your session has expired. Please log in again to continue.';
const REVOKED =
  'For security purposes users may have a maximum of 2 concurrent sessions.';

const toastOptions = {
  duration: 20000,
  id: 'auth-error',
};

const useSessionData = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const handleLogout = useLogout();
  const { session, isLoaded, isSignedIn } = useSession();
  const { sessions } = useSessionList();

  useEffect(() => {
    if (isLoaded && isSignedIn && session && !sessionId) {
      sessionStorage.removeItem(TOKEN_KEYS.GHOST);
      sessionStorage.removeItem(TOKEN_KEYS.GUEST);
      setSessionId(session.id);
    }
  }, [isLoaded, isSignedIn, session, sessionId]);

  useEffect(() => {
    let message = '';
    const lastSession = sessions?.find(s => s && s.id === sessionId);
    const isExpired = lastSession?.status === 'expired';
    const isRevoked = lastSession?.status === 'revoked';

    if (!isLoaded || !sessions.length || !lastSession) {
      return;
    }

    if (lastSession && !isSignedIn) {
      setSessionId(null);
      handleLogout();
    }

    if (!isExpired && !isRevoked) {
      return;
    }

    if (isRevoked) {
      message = REVOKED;
    }

    if (isExpired) {
      message = EXPIRED;
    }

    toast.error(message, toastOptions);

    captureMessage(`Session ended - ${lastSession.status}`, {
      level: 'warning',
      fingerprint: ['session-ended-early'],
    });
  }, [isSignedIn, isLoaded, sessions, sessionId, handleLogout]);

  return session?.id;
};

export default useSessionData;
