import get from 'lodash/get';

import { InventoryItemsAddDocument } from '@marketmuse/data-papi';
import Toast from '../../components/Toast';

import * as types from '../../config/types';
import { getPlans } from '../../actions/planActions';
import { setSimple } from '../../actions/miscActions';
import { getAggregateStats, getSite } from '../../actions/siteActions';
import makeRequest from '../../utils/makeRequest';

export default () =>
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState();

    if (action.type === types.INVENTORY_ITEMS_ADD) {
      Toast.fire('Processing request ...');
      const siteId = action.siteId || get(state, 'filter.site.id');

      makeRequest({
        type: types.INVENTORY_ITEMS_ADD,
        mutation: InventoryItemsAddDocument,
        variables: {
          siteId,
          items: action.items,
        },
      })
        .then(res => {
          const error = res.error || res.errors;
          const data = get(res, 'inventoryItemsAdd') || {};

          // uncaught exception
          if (error || data.status === 400) {
            const message = res.errors || res.error;
            let toastMessage = 'Items cannot be added at this moment';

            Toast.fire(message || toastMessage, 'error');

            if (action.callback && typeof action.callback === 'function') {
              return action.callback(data);
            }
          }

          // we got results -- lets check them
          if ([206, 200].includes(data?.status)) {
            if (data.status === 200 && !action.preventSuccessToast) {
              Toast.fire('Items have been added to your inventory');
            }

            if (data.status === 206) {
              Toast.fire(
                'It looks like some items cannot be added at this moment',
                'error',
              );
            }
            dispatch(
              setSimple({ key: 'tableViewRefetchTime', value: new Date() }),
            );

            dispatch(getSite({ siteId }));
            dispatch(getAggregateStats({ siteId }));
            dispatch(getPlans({ siteId, skipAggregateRefresh: true }));

            if (action.callback && typeof action.callback === 'function') {
              return action.callback(data);
            }
          }
        })
        .catch(error => {
          console.error('add items to inventory error', error);
        });
    }

    return next(action);
  };
