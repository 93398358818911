import { isEmpty, get } from 'lodash';
import { isProd, isStaging } from '../../../utils/isEnv';
import { REACT_APP } from '../../../config/env';

import Agent from '../Agent';

class ParativeAgent extends Agent {
  canInit(user) {
    return super.canInit(user);
  }

  track() {
    return false;
  }

  isReady() {
    if ((!isProd() && !isStaging()) || isEmpty(REACT_APP.PARATIVE_API_TOKEN)) {
      return false;
    } else {
      return true;
    }
  }

  initAnonymous() {
    return;
  }

  clear() {
    return;
  }

  init(user) {
    if (!this.canInit(user, this) || !this.isReady()) {
      return;
    }

    const data = {
      apiToken: REACT_APP.PARATIVE_API_TOKEN,
      firstName: get(user, 'firstName') || get(user, 'fullName'),
      lastName: get(user, 'lastName') || '',
      uid: get(user, 'id'),
      organizationID: REACT_APP.PARATIVE_ORG_TOKEN,
      email: (get(user, 'email') || '').toLowerCase(),
      accounts: [
        {
          externalId: get(user, 'org.sfdcAccountId'),
          companyId: get(user, 'org.id'),
          companyName: get(user, 'org.name'),
        },
      ],
    };

    window.parativeConfig = {
      user: data,
    };

    if (get(window, 'Parative.identify')) {
      window.Parative.identify(data);
    }
  }
}
export default ParativeAgent;
