import React, { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ColumnSettings from './ColumnSettings';
import ColumnSettingsBarButton, { barButtonTypes } from './ColumnSettingsBarButton';
import IconSvg from './../../IconSvg/IconSvg';

export const HEIGHT = 30;

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.colors.grey10};
  height: ${HEIGHT}px;
  padding: 0 4px;

  & > div {
    margin-right: 4px;

    &:last-of-type { margin-right: 0; }
  }

  & + .mms--table-mask {
    height: calc(100% - ${HEIGHT}px);
  }
`;

const ColumnSettingsBar = ({
  columns,
  columnsComputed,
  barActions,
  onColumnHidden,
  onColumnPinned,
  onColumnReorder,
  hideFromSettings,
}) => {

  const hasBarActions = useMemo(() => Array.isArray(barActions) && barActions.length > 0, [barActions]);
  const hasHideableColumns = useMemo(() => typeof onColumnHidden === 'function', [onColumnHidden]);
  const hasPinnableColumns = useMemo(() => typeof onColumnPinned === 'function', [onColumnPinned]);
  const hasReorderableColumns = useMemo(() => typeof onColumnReorder === 'function', [onColumnReorder]);
  const shouldShowColumnsBar = (
    hasBarActions ||
    hasHideableColumns ||
    hasPinnableColumns ||
    hasReorderableColumns
  );

  const getColumnSettings = useCallback(props => (
    <ColumnSettings
      {...props}
      columns={columns}
      hideFromSettings={hideFromSettings}
      columnsComputed={columnsComputed}
      onColumnHidden={onColumnHidden}
      onColumnPinned={onColumnPinned}
      onColumnReorder={onColumnReorder}
    />), [
      hideFromSettings,
      columnsComputed,
      onColumnHidden,
      onColumnPinned,
      onColumnReorder,
    ]
  );

  // compose bar actions, add default buttons
  // based on configuration
  const useBarActions = useMemo(() => {
    let actions = [ ...(barActions || []) ];

    // add hide button to bar action config
    // if (shouldShowColumnsBar && hasHideableColumns) {
    //   actions.unshift({
    //     title: 'Hide',
    //     icon: <IconSvg name="hidden" size="16" />,
    //     dropdownContent: getColumnSettings({ hide: true }),
    //   })
    // }

    // add arrange button to bar action config
    if (shouldShowColumnsBar && (hasReorderableColumns || hasPinnableColumns)) {
      actions.unshift({
        title: 'Configure Table',
        icon: <IconSvg name="settings-hex" size="16" />,
        dropdownContent: getColumnSettings({
          reorder: !!hasPinnableColumns,
          pin: !!hasPinnableColumns,
          hide: true
        }),
      })
    }

    return actions
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      }).map((action, actionInd) => action.children
        ? action.children
        : (
          <ColumnSettingsBarButton
            key={action.key || actionInd}
            icon={action.icon}
            active={action.active}
            disabled={action.disabled}
            title={action.title}
            onClick={action.onClick}
            dropdownContent={action.dropdownContent}
          />
        )
      );

  }, [
    getColumnSettings,
    shouldShowColumnsBar,
    hasHideableColumns,
    hasPinnableColumns,
    hasReorderableColumns,
  ]);

  return !shouldShowColumnsBar ? null : (
    <Wrapper>
      {useBarActions}
    </Wrapper>
)};

export const barActionTypes = PropTypes.arrayOf(barButtonTypes);

ColumnSettingsBar.propTypes = {
  columns: PropTypes.array,
  columnsComputed: PropTypes.object,
  barActions: barActionTypes,
  // list of column ids that shouldn't be shown in settings lists
  hideFromSettings: PropTypes.array,
  onColumnHidden: PropTypes.func,
  onColumnPinned: PropTypes.func,
  onColumnReorder: PropTypes.func,
};

export default memo(ColumnSettingsBar);
