import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${p => p.theme.colors.grey50};
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  user-select: none;
`;

const CellNA = ({ text }) => <Wrapper>{ text || 'N/A'}</Wrapper>;
CellNA.propTypes = { text: PropTypes.string };
export default CellNA;
