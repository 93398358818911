/**
 * This is a layout component used for all cells, including
 * the header, so no other style than layouts
 * should be applied
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEqual } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 8px 16px;
  width: 100%;
  height: 100%;
  &:hover {
    .mms--cell--side-item-hideOnhover {
      display: none;
    }
    .mms--cell--row-hideOnhover {
      display: none;
    }
  }
  &:not(:hover) {
    .mms--cell--row-showOnhover {
      display: none;
    }
    .mms--cell--side-item-showOnhover {
      display: none;
    }
  }
`;

const WrapperRow = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const WrapperCell = styled.div`
  white-space: normal;
  word-break: break-word;
  text-align: left;
  display: flex;
  align-items: center;
`;

const WrapperCenter = styled(WrapperCell)`
  flex-grow: 1;
  width: 100%;
  ${p => p.align === 'center' && 'justify-content: center;'}
  ${p => p.align === 'left' && 'justify-content: flex-start;'}
  ${p => p.align === 'right' && 'justify-content: flex-end;'}
`;

const WrapperSide = styled(WrapperCell)``;

const WrapperLeft = styled(WrapperSide)`
  flex-shrink: 0;
  margin-right: 16px;
`;

const WrapperRight = styled(WrapperSide)`
  flex-shrink: 0;
  margin-left: 16px;
`;

// ensure an item is an array
const arr = item => (Array.isArray(item) ? item : [item]);

// items rendered on the sides of layout rows
const SideItem = ({ left, right, items }) => {
  if (!items) return null;

  let Component = null;
  if (left) Component = WrapperLeft;
  if (right) Component = WrapperRight;
  if (!Component) return null;

  return arr(items).map((item, i) => (
    <Component
      key={`side_item_${i}`}
      showOnHover={item.showOnHover}
      style={item.style}
      className={[
        item.showOnHover ? 'mms--cell--side-item-showOnhover' : '',
        item.hideOnHover ? 'mms--cell--side-item-hideOnhover' : '',
      ].join(' ')}
    >
      {item.component}
    </Component>
  ));
};

// items rendered on the center
const CenterItem = ({ item = {} }) => {
  return (
    <WrapperCenter style={item.style} align={item.align}>
      {item.component}
    </WrapperCenter>
  );
};

// Cell component
const Cell = ({ style, layout }) => (
  <Wrapper style={style} data-mms--datatable-cell>
    {arr(layout).map((row, i) => (
      <WrapperRow
        key={`cell${i}`}
        className={[
          row.showOnHover ? 'mms--cell--row-showOnhover' : '',
          row.hideOnHover ? 'mms--cell--row-hideOnhover' : '',
        ].join(' ')}
      >
        <SideItem left items={row.left} />
        <CenterItem item={row.center} />
        <SideItem right items={row.right} />
      </WrapperRow>
    ))}
  </Wrapper>
);

const sideShape = PropTypes.shape({
  style: PropTypes.object,
  component: PropTypes.any,
  showOnHover: PropTypes.bool,
  hideOnHover: PropTypes.bool,
});

const centerShape = PropTypes.shape({
  style: PropTypes.object,
  align: PropTypes.oneOf(['center', 'left', 'right']),
  component: PropTypes.any,
});

const layoutShape = PropTypes.shape({
  showOnHover: PropTypes.bool,
  hideOnHover: PropTypes.bool,
  style: PropTypes.object,
  center: centerShape,
  left: PropTypes.oneOfType([sideShape, PropTypes.arrayOf(sideShape)]),
  right: PropTypes.oneOfType([sideShape, PropTypes.arrayOf(sideShape)]),
});

Cell.propTypes = {
  style: PropTypes.object,
  layout: PropTypes.oneOfType([layoutShape, PropTypes.arrayOf(layoutShape)]),
};

// Helper functions

// layout => [row, rowIndex]
const getActiveRow = layout => {
  if (!Array.isArray(layout)) return [layout, null];
  return [layout[0], 0]; // TODO: support other indexes
};

// layout, newRow, replaceIndex => newLayout
const replaceRow = (layout, newRow, replaceIndex) => {
  if (!Array.isArray(layout)) return newRow;
  const newLayout = layout.slice();
  newLayout.splice(replaceIndex, 1, newRow);
  return newLayout;
};

// insert an item on the right side of the layout
export const insertSide = (layout, side = 'right', sideItem) => {
  const [row, rowIndex] = getActiveRow(layout);

  // side is empty
  if (!row[side]) {
    const newRow = { ...row, [side]: sideItem };
    return replaceRow(layout, newRow, rowIndex);
  }

  // side is not empty
  const useSide = Array.isArray(row[side]) ? row[side] : [row[side]];
  const newRow = { ...row, [side]: useSide.concat(sideItem) };
  return replaceRow(layout, newRow, rowIndex);
};

export default memo(Cell, (prev, next) => {
  return isEqual(prev.style, next.style) && isEqual(prev.layout, next.layout);
});
