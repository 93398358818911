import get from 'lodash/get';
import { ACTION_TYPES } from '@marketmuse/data-state';

import * as types from '../config/types';
import initialState from '../config/initialState';

const filterReducer = (state = initialState.filter, action) => {
  switch (action.type) {
    case types.SET_USER_IDENTITY:
    case ACTION_TYPES.ACTIVE_INVENTORY_SET:
      return { ...state, site: { ...action.site } };

    case types.SAVE_INVENTORY_VERSIONS_TO_STATE:
      return { ...state, versions: [...action.inventoryVersions] };

    case ACTION_TYPES.SIGN_OUT:
      return {
        ...state,
        site: initialState.filter.site,
        versions: initialState.filter.versions,
      };

    case types.UPDATE_CREDITS:
      return {
        ...state,
        site: {
          ...state.site,
          ...(action.briefCredits && { briefCredits: action.briefCredits }),
        },
      };

    // when allowed subdomains changed, sync the changes
    // to the selected site if it is the selected site
    case types.SAVE_ALLOWED_SUBDOMAINS:
      if (get(state, 'site.id') === action.siteId) {
        return {
          ...state,
          site: {
            ...state.site,
            allowedSubdomains: action.subdomains,
          },
        };
      }

      return state;

    case ACTION_TYPES.ACTIVE_INVENTORY_UPDATE:
      if (get(state, 'site.id') !== action.siteId) {
        return state;
      }

      return {
        ...state,
        site: {
          ...state.site,
          ...action.updatedFields,
        },
      };

    default:
      return state;
  }
};

export default filterReducer;
