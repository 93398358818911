import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';

const RecurlyBillingInfoWarnings = ({
  handleSettingsClick,
  isCheckingAccount,
  hasAccount,
  hasBillingInfo,
}) => (
  <>
    {!isCheckingAccount && !hasAccount && (
      <div>
        There are no payment options associated with your account. Please talk
        to a strategist to get started.
      </div>
    )}
    {!isCheckingAccount && hasAccount && !hasBillingInfo && (
      <div>
        Payment method not found, please go to{' '}
        <Button text xLarge inline onClick={() => handleSettingsClick()}>
          settings
        </Button>{' '}
        to add a payment make this purchase.
      </div>
    )}
  </>
);

RecurlyBillingInfoWarnings.propTypes = {
  handleSettingsClick: PropTypes.func.isRequired,
  isCheckingAccount: PropTypes.bool,
  hasAccount: PropTypes.bool,
  hasBillingInfo: PropTypes.bool,
};

export default memo(RecurlyBillingInfoWarnings);
