import { RECURLY, PAPI } from '@marketmuse/config/types';

export const SIGNUP_KEYS = {
  complete: 'complete',

  [RECURLY.PLAN_NAME.STANDARD]: `payment:${RECURLY.PLAN_NAME.STANDARD}`,

  onboardingData: 'onboardingData',

  confirmData: 'confirmData',
  confirmedData: 'confirmedData',
};

export const SIGNUP_STEPS = {
  // should see trial tutorial
  complete: [SIGNUP_KEYS.complete],

  // how to handle user fields in subscription flow
  confirm: [SIGNUP_KEYS.confirmData, SIGNUP_KEYS.confirmedData],

  // should see trial downmarket gate
  payment: [
    SIGNUP_KEYS[RECURLY.PLAN_NAME.STANDARD],
    `payment:${PAPI.SubscriptionPlanType.RESEARCH}`,
    `payment:${PAPI.SubscriptionPlanType.OPTIMIZE}`,
    `payment:${PAPI.SubscriptionPlanType.STRATEGY}`,
  ],

  // should see onboarding data selector
  onboarding: [SIGNUP_KEYS.onboardingData],
};
