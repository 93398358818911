import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { PAPI } from '@marketmuse/config/types';

import Tooltip from '../../../../components/Tooltip/Tooltip';
import Dates from '../../../../components/Dates';
import IconSvg from '../../../../components/IconSvg';
import Button from '../../../../components/Button';

import { toggleDynamic } from '../../../../actions/miscActions';
import useHasAccess from '../../../../hooks/useHasAccess';
import SummaryBlock from './SummaryBlock';

const ButtonStyled = styled(Button)`
  padding: 0;
  width: fit-content;
  font-size: 14px;
`;

const QueriesSummary = ({ showUpgradeButton }) => {
  const dispatch = useDispatch();
  const appRuns = useSelector(state => state.misc?.counts?.appRuns) ?? {};
  const canBuyQueries = useHasAccess({
    resource: PAPI.PermissionResource.SUBSCRIPTION,
    action: PAPI.PermissionAction.PURCHASE_APP_RUNS,
  });
  const UpgradeButton = useMemo(
    () => (
      <ButtonStyled
        text
        large
        iconRight={<IconSvg name="arrow-right" size={12} />}
        onClick={() => {
          dispatch(
            toggleDynamic({
              id: 'subscriptionManagement',
              status: true,
              data: { initialScreen: 'plans' },
            }),
          );
        }}
        data-mms--plan-management="upgradeNow"
      >
        Upgrade account
      </ButtonStyled>
    ),
    [],
  );

  const getSecondColumn = useCallback(
    ({ isExtra, showButton }) => (
      <>
        <h1>{appRuns.extra || 0}</h1>
        <div>
          <strong>{isExtra ? 'Extra ' : ''}Queries</strong>
        </div>
        <div className="description">
          {isExtra ? 'Extra q' : 'Q'}ueries never expire and are purchased
          individually or in bundles.
        </div>
        {showButton && showUpgradeButton && UpgradeButton}
      </>
    ),
    [appRuns, showUpgradeButton, UpgradeButton],
  );

  const firstColumn = useMemo(
    () =>
      appRuns.limit ? (
        <>
          <h1>{Math.max(0, appRuns.limit - appRuns.current)}</h1>
          <div>
            <strong>Monthly Queries</strong>
          </div>
          <div className="description">
            Monthly query count refreshes to <strong>{appRuns.limit}</strong>{' '}
            total on{' '}
            <Tooltip title={dayjs(appRuns.nextReset).format('HH:mm')}>
              <strong>
                <Dates end={dayjs(appRuns.nextReset)} dateFormat="MM/DD/YY" />
              </strong>
            </Tooltip>
          </div>
          {showUpgradeButton && UpgradeButton}
        </>
      ) : (
        getSecondColumn({ showButton: true })
      ),
    [appRuns],
  );

  const secondColumn = useMemo(
    () =>
      appRuns.limit && canBuyQueries
        ? getSecondColumn({ isExtra: true })
        : null,
    [appRuns, canBuyQueries],
  );

  return (
    <SummaryBlock
      icon={{ name: 'queries', color: 'greenBase' }}
      title="Queries summary"
      firstColumn={firstColumn}
      secondColumn={secondColumn}
    />
  );
};

QueriesSummary.propTypes = {
  showUpgradeButton: PropTypes.bool,
};

export default memo(QueriesSummary);
