import { isNil, isEmpty } from 'lodash';

import { DEFAULT_COL_WIDTH} from './shared'

export default (columns = [], { before, after, filter, columnsHidden } = {}) => {
  let sliced = columns;
  // filter columns arr when needed
  if (typeof filter === 'function') sliced = sliced.filter(filter);
  if (!isEmpty(columnsHidden)) sliced = sliced.filter(c => !columnsHidden[c.id]);
  // slice columns arr when needed
  if (!isNil(after)) { sliced = columns.slice(after + 1); }
  if (!isNil(before)) { sliced = columns.slice(0, before); }
  // sum up and return widths
  return sliced.reduce((acc, c) => acc + (c.width || DEFAULT_COL_WIDTH), 0)
};
