import { useMemo } from 'react';
import calculateTotalWidth from './calculateTotalWidth';

const getPinnedFactory = columnsPinned => column => {
  if (columnsPinned[column.id] === 'left' || columnsPinned[column.id] === true) return 'left';
  if (columnsPinned[column.id] === 'right') return 'right';
  return null;
}

const isPinnedEdge = (columnsPinned, columns, index, dir) => {
  const getPinned = getPinnedFactory(columnsPinned);
  const column = columns[index];
  const next = columns[index + (dir === 'left' ? 1 : -1)]
  // wrong category
  if (getPinned(column) !== dir) return false;
  // last pin-left to the right
  // first pin-right to the left
  // should not consider as 'edge'
  if (!next) return false;
  // next column is same pin, this is the edge
  if (getPinned(next) !== dir) return true;
  // base case
  return false;
}

// columns sorted and attached order, taking
// pinned columns into account. safe to use
// for left / right width calculations and
// with flex `order` property
export default (columns, columnsOrder = [], columnsPinned = {}, columnsHidden = {}) => useMemo(() => {
  const getPinned = getPinnedFactory(columnsPinned);

  // filter out hidden columns for offset calculations
  const filterHidden = c => !columnsHidden[c.id];

  const columnsWithOrder = columns.map((column, index) => {

    // use flex order property to reorder columns
    const indexOf = columnsOrder.indexOf(column.id);
    let order = indexOf === -1 ? index : indexOf;

    // pinning left or right should push columns left or right, but
    // the ordering pin columns should order within themself based
    // on the existing order configuration. to achieve this, just sum up
    // the order with a large positive or negative number
    if (getPinned(column) === 'left') order -= 9999;
    if (getPinned(column) === 'right') order += 9999;

    return Object.assign({}, column, { order })
  }).sort((a, b) => {
    return a.order - b.order;
  });

  const columnsVisibleWithOrder = columnsWithOrder.filter(filterHidden);
  const columnsHiddenWithOrder = columnsWithOrder.filter(c => !filterHidden(c));

  // create computation object
  const columnsComputed = columnsVisibleWithOrder.reduce((acc, column, index) => Object.assign({}, acc, {
    [column.id]: {
      hidden: false,
      pinned: getPinned(column) !== null,
      pinnedLeft: getPinned(column) === 'left',
      pinnedRight: getPinned(column) === 'right',
      pinnedLeftEdge: isPinnedEdge(columnsPinned, columnsVisibleWithOrder, index, 'left'),
      pinnedRightEdge: isPinnedEdge(columnsPinned, columnsVisibleWithOrder, index, 'right'),
      leftOffset: getPinned(column) === 'left' ? calculateTotalWidth(columnsVisibleWithOrder, { before: index }) : null,
      rightOffset: getPinned(column) === 'right' ? calculateTotalWidth(columnsVisibleWithOrder, { after: index }) : null,
      flexOrder: column.order,
    }
  }), {})

  // attach some config for hidden columns
  columnsHiddenWithOrder.forEach(column => {
    columnsComputed[column.id] = {
      hidden: true,
      pinned: false,
      pinnedLeft: false,
      pinnedRight: false,
      pinnedLeftEdge: false,
      pinnedRightEdge: false,
      leftOffset: null,
      rightOffset: null,
      flexOrder: column.order,
    }
  })

  return columnsComputed;

}, [
  columns,
  columnsOrder,
  columnsPinned,
  columnsHidden,
])
