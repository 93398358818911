import React, { memo, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import Cell from '../Components/Cell';
import { SELECTION_COL_ID } from '../utils/shared';
import TBBody from './TBBody'; // <tbody>
import TRBody from './TRBody'; // <tr>
import TDBody from './TDBody'; // <td>
import LoadingBlock from './../../LoadingBlock/LoadingBlock';

const TableBody = ({
  data,
  columns,
  hiddenHeader,
  loading,
  onRowClick,
  onRowContextMenu,
  rowClickDisabled,
  columnsComputed,
  tbBodyStyle,
  getSubRows,
  expandedRows,
  selection,
}) => {
  const renderRow = useCallback(
    (row, index, { isSubRow, isLastSubRow } = {}) => {
      const selectedRow = selection?.[row.key];
      return (
        <TRBody
          index={index}
          key={`${index}-${row.key || row.id}`}
          row={row}
          hiddenHeader={hiddenHeader}
          onRowClick={onRowClick}
          onRowContextMenu={onRowContextMenu}
          rowClickDisabled={rowClickDisabled}
          loading={loading}
          isSubRow={isSubRow}
          isLastSubRow={isLastSubRow}
        >
          {columns.map(
            ({
              id,
              cell,
              cellStyle,
              width,
              fixedWidth,
              disableLoading,
              tdStyle,
              showOnHover,
            }, colIndex) => {
              let cellStyleParsed;
              let cellParsed;

              // render loading instead of cell contents
              if (loading && !disableLoading) {
                cellParsed = {
                  center: { component: <LoadingBlock /> },
                };
              } else {
                // args to call factory functions
                const fnArgs = { row, index, loading };
                if (id === SELECTION_COL_ID) {
                  fnArgs.selectedRow = selectedRow;
                }
                // get provided cell config
                cellParsed = typeof cell === 'function' ? cell(fnArgs) : cell;

                // if `cell` is omitted, render default value in plain text
                if (!cellParsed) cellParsed = '-';

                // if no layout provided for cells, provide one
                if (typeof cellParsed !== 'object') {
                  cellParsed = { center: { component: cellParsed } };
                }

                // get provided styles
                cellStyleParsed =
                  typeof cellStyle === 'function' ? cellStyle(fnArgs) : cellStyle;
              }

              // body row cells
              return (
                <TDBody
                  key={`${index}-${colIndex}-${row.key}-${id}`}
                  width={width}
                  row={row}
                  selectedRow={selectedRow}
                  style={tdStyle}
                  computed={columnsComputed[id]}
                  fixedWidth={fixedWidth}
                  showOnHover={showOnHover}
                >
                  <Cell style={cellStyleParsed} layout={cellParsed} />
                </TDBody>
              );
            }
          )}
        </TRBody>
      );
    },
    [
      hiddenHeader,
      onRowClick,
      onRowContextMenu,
      rowClickDisabled,
      loading,
      columnsComputed,
    ]
  );
  const tableBody = useMemo(
    () =>
      (data || []).map((row, index) => {
        let subRows;
        if (!loading) {
          // does this row have subrows
          subRows = typeof getSubRows === 'function' ? getSubRows(row) : null;
        }
        // render the original row
        const originalRow = renderRow(row, index);
        // if this row is not expanded, return only the original row
        if (!expandedRows[row.id]) return originalRow;

        // render the row, and subrows (if any)
        return (
          <>
            {originalRow}
            {!loading &&
              Array.isArray(subRows) &&
              subRows.map((subRow, subRowIndex) =>
                renderRow(subRow, `${index}-${subRowIndex}`, {
                  isSubRow: true,
                  isLastSubRow: subRowIndex === subRows.length - 1,
                })
              )}
          </>
        );
      }),
    [loading, getSubRows, renderRow]
  );

  return <TBBody style={tbBodyStyle}>{tableBody}</TBBody>;
};

TableBody.propTypes = {
  rows: PropTypes.array,
  prepareRow: PropTypes.func,
  hiddenHeader: PropTypes.bool,
  tableBodyProps: PropTypes.object,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  onRowContextMenu: PropTypes.func,
  onFormDataChange: PropTypes.func,
  rowClickDisabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  getSubRows: PropTypes.func,
  selection: PropTypes.object,
  expandedRows: PropTypes.object,
};

export default memo(TableBody);
