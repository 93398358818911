import { appOptimizeSlices } from '@marketmuse/data-state/appOptimize';
import { appResearchSlices } from '@marketmuse/data-state/appResearch';
import { loadingsInitialState } from '@marketmuse/data-state/loadings';
import { initialState as filterInitialState } from '@marketmuse/data-state/filter';
import { initialState as savedViewsInitialState } from '@marketmuse/data-state/savedViews';

export const tableUiConfig = {
  default: {
    page: 1,
    limit: 20,
    query: '',
  },
};

export default {
  loadings: loadingsInitialState,
  apps: {},
  admin: {},
  user: {},
  guestAccess: {},
  filter: filterInitialState,
  payment: {
    account: {},
    subscriptions: [],
    transactions: [],
  },
  savedViews: savedViewsInitialState,
  tools: { data: {} },
  plans: {},
  settings: {},
  misc: {},
  permissions: [],
  persist: {
    sidebarCollapsed: false,
  },
  appResearch: appResearchSlices.initialState,
  appOptimize: appOptimizeSlices.initialState,
};
