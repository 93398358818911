/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import cx from 'classnames';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '../../Polymorphic';

interface ElementProps {
  className?: string | Array<string | string[]>;
  [key: string]: unknown;
}

interface InputElProps extends ElementProps {
  type?: string;
  placeholder?: string;
}

export interface InputProps {
  name: string;
  label: string;
  inputProps?: InputElProps;
  labelProps?: ElementProps;
}

type InputComponentProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, InputProps>;

type InputComponent = <C extends React.ElementType = 'input'>(
  props: InputComponentProps<C>,
) => React.ReactElement | null;

export const Input: InputComponent = React.memo(
  React.forwardRef(
    <C extends React.ElementType = 'input'>(
      { as, name, label, inputProps, labelProps }: InputComponentProps<C>,
      ref?: PolymorphicRef<C>,
    ) => {
      const Component = as || 'input';
      const { className: _inputClassName, ...restInputProps } =
        inputProps || {};
      const { className: labelClassName, ...restLabelProps } = labelProps || {};

      const inputClassName =
        typeof _inputClassName === 'string'
          ? _inputClassName
          : cx(_inputClassName);

      return (
        <>
          <label
            htmlFor={name}
            className={twMerge(
              'block',
              'text-sm',
              'font-bold',
              'text-gray-700',
              'mb-1',
              labelClassName,
            )}
            {...restLabelProps}
          >
            {label}
          </label>
          <Component
            id={name}
            className={twMerge(
              'shadow-sm',
              'focus:ring-blue-400',
              'focus:border-blue-400',
              'block',
              'w-full',
              'sm:text-sm',
              'border-gray-400',
              'rounded',
              inputClassName,
            )}
            {...restInputProps}
            ref={ref}
          />
        </>
      );
    },
  ),
);
