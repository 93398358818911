import { useMemo } from 'react';
import { get } from 'lodash';

import { sanitizeOrgSlug, checkIsSuperAdmin } from '@marketmuse/utilities';
import { useStoreSelector } from '@marketmuse/data-state/hooks';
import useIdentityProvider from '../../providers/IdentityProvider/useIdentityProvider';

// General auth data for retrieving user info
// This hook should perform no side effects
const useIdentitySuite = () => {
  const { user: idpUser } = useIdentityProvider();

  const userId = useStoreSelector(state => state.user?.id);
  const orgId = useStoreSelector(state => state.user?.org?.id);
  const orgSlug = useStoreSelector(state => state.user?.org?.slug);
  const siteId = useStoreSelector(state => state.filter?.site?.id);
  const siteLevel = useStoreSelector(state => state.filter?.site?.level);

  const isSuperUser = useMemo(() => {
    const isSuperAdmin = checkIsSuperAdmin({ userOrgId: orgId });
    const verificationStatus = get(
      idpUser,
      'emailAddresses[0].verification.status',
    );

    const isEmailVerified = verificationStatus === 'verified';

    return isSuperAdmin && isEmailVerified;
  }, [orgId, idpUser]);

  return {
    isSuperUser,
    orgId: orgId,
    orgSlug: sanitizeOrgSlug(orgSlug),
    siteId: siteId,
    siteLevel: siteLevel,
    userId: idpUser?.id && userId ? userId : void 0,
  };
};

export default useIdentitySuite;
