export { plansCodes as recurlyPlansCodes } from './plansCodes';
export { plansInfo as recurlyPlansInfo } from './plansInfo';
export { default as recurlyBillingCountries } from './billingCountries.json';

export const recurlyFieldsConfig = {
  all: { style: { fontFamily: "'Inter', Roboto, Arial, san-serif" } },
  card: {
    displayIcon: true,
    style: {
      fontSize: '14px',
      lineHeight: '22px',
      fontColor: '#26292d !important',
      placeholder: {
        color: '#8f8b8f !important',
        content: {
          number: 'Card number',
          cvv: 'CVC',
        },
      },
      invalid: {
        fontColor: '#ed6a5a !important',
      },
    },
  },
  number: {
    selector: '#recurly-number',
    format: true,
  },
  month: {
    inputType: 'mobileSelect',
    style: {
      placeholder: {
        content: 'MM',
      },
    },
  },
  year: {
    style: {
      placeholder: {
        content: 'YYYY',
      },
    },
  },
  cvv: {},
};
