import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';

import countries from '../../config/countries';

import hideTooltip from '../../utils/hideTooltip';
import recurly from '../../models/Recurly';

import SimpleTextInput, { SimpleTextInputLabel } from '../SimpleTextInput';
import Popper, { SelectionWrapper } from '../Tooltip/Popper';
import Button from '../Button';

const Wrapper = styled.form`
  width: 100%;

  .recurly-hosted-field-card {
    border: none;
    padding: 2px 8px;
    height: 40px;
    background-color: ${p => p.theme.mmxGreyF4};
    margin: 0;

    #generic_card {
      fill: green !;
    }
  }
`;

const InputStyled = styled(SimpleTextInput)`
  height: 40px;
  font-size: 14px;
  line-height: 22px;

  input {
    font-weight: 500 !important;
    ${p => p.error && `margin-right: 8px;`}
  }
`;

const Section = styled.div`
  display: flex;
  margin-bottom: 17px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Group = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: ${p => p.theme.spacing.xSmall};
  margin-right: ${p => p.theme.spacing.xSmall};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const PopperStyled = styled(Popper)`
  ${SelectionWrapper} {
    position: relative;
    border-color: transparent;
    border-radius: ${p => p.theme.borderRadius};
    transition: ${p => p.theme.transition};
    color: ${p => p.theme.mmxGrey43};
    background-color: ${p => p.theme.mmxGreyF4};
  }
`;

const CreditCardInput = props => {
  const form = useRef(null);
  const [firstName, setFirstName] = useState(props.firstName || '');
  const [lastName, setLastName] = useState(props.lastName || '');
  const [isoCountry, setISOCountry] = useState('us');
  const [, setISOState] = useState('');
  const [isTokenizing, setIsTokenizing] = useState(false);
  const [, setError] = useState(null); // { <field>: <message> }

  // recurly injects frames on `recurly.configure`, so it needs
  // to be reconfigured every time the form is mounted
  useEffect(recurly.init, []);

  const isLoading = props.loading || isTokenizing;
  const isDisabled = props.disabled;

  return (
    <Wrapper ref={form}>
      {/* name */}
      <Section>
        <Group>
          <SimpleTextInputLabel>First Name</SimpleTextInputLabel>
          <InputStyled
            data-recurly="first_name"
            placeholder="First Name"
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
          />
        </Group>
        <Group>
          <SimpleTextInputLabel>Last Name</SimpleTextInputLabel>
          <InputStyled
            data-recurly="last_name"
            placeholder="Last Name"
            value={lastName}
            onChange={event => setLastName(event.target.value)}
          />
        </Group>
      </Section>

      {/* card */}
      <Section>
        <Group>
          <SimpleTextInputLabel>
            Card Number
            <span> (no dashes or spaces)</span>
          </SimpleTextInputLabel>
          <div data-recurly="card" />
        </Group>
      </Section>

      {/* zip code
      <Section>
        <Group>
          <SimpleTextInputLabel>Zip</SimpleTextInputLabel>
          <InputStyled
            data-recurly="postal_code"
            error={get(error, 'postal_code')}
            placeholder="eg. 98552"
          />
        </Group>
      </Section>
      */}

      {/* address
      <Section>
        <Group>
          <SimpleTextInputLabel>Address 1</SimpleTextInputLabel>
          <InputStyled
            data-recurly="address1"
            placeholder="eg. 1313 Main St."
          />
        </Group>
        <Group>
          <SimpleTextInputLabel>Address 2</SimpleTextInputLabel>
          <InputStyled
            data-recurly="address2"
            placeholder="eg. Unit 1"
          />
        </Group>
      </Section>
      */}

      <Section>
        {/* city and state
        <Group>
          <SimpleTextInputLabel>City</SimpleTextInputLabel>
          <InputStyled
            data-recurly="city"
            placeholder="eg. Hope"
          />
        </Group>
        <Group>
          <SimpleTextInputLabel>State/Province</SimpleTextInputLabel>
          <input type="hidden" value={isoState} data-recurly="state" />
          <PopperStyled
            border
            style={{ width: '100%' }}
            label={''}
            position="bottom-start"
            handleId="credit-card-state-dropdown"
            selection={isoState ? get(statesByCountry, `['${isoCountry}']['${isoState}'].title`) : null}
            items={Object.keys(statesByCountry[isoCountry])
                .map(s => ({
                  id: statesByCountry[isoCountry][s].id,
                  title: statesByCountry[isoCountry][s].title,
                  onClick: () => {
                    hideTooltip('credit-card-state-dropdown');
                    setISOState(statesByCountry[isoCountry][s].id)
                  }
                }))}
            />
        </Group>
        */}
        <Group>
          <SimpleTextInputLabel>Country</SimpleTextInputLabel>
          <input type="hidden" value={isoCountry} data-recurly="country" />
          <PopperStyled
            border
            commonHeight
            style={{ width: '100%' }}
            label={''}
            position="bottom-start"
            handleId="credit-card-country-dropdown"
            selection={
              isoCountry ? get(countries, `['${isoCountry}'].title`) : null
            }
            items={Object.values(countries).map(c => ({
              id: c.id,
              title: c.title,
              onClick: () => {
                hideTooltip('credit-card-country-dropdown');
                setISOCountry(c.id);
                setISOState('');
              },
            }))}
          />
        </Group>
      </Section>

      {/* submit */}
      <Section style={{ marginTop: 37 }}>
        <Group style={{ display: 'flex', justifyContent: 'space-between' }}>
          {props?.cancelButton && typeof props.cancelButton === 'object' && (
            <Button variant="grey50" xLarge {...props.cancelButton} />
          )}
          <Button
            primary
            xLarge
            loading={isLoading}
            disabled={isLoading || isDisabled}
            {...(props.ctaAttributes || {})}
            onClick={() => {
              if (typeof props.onCardSubmitted === 'function') {
                props.onCardSubmitted();
              }
              setIsTokenizing(true);
              recurly.token(
                form.current,
                token => {
                  setIsTokenizing(false);
                  if (typeof props.onTokenReceived === 'function') {
                    props.onTokenReceived(token);
                  }
                },
                err => {
                  const field = get(err, 'fields[0]');
                  const message = get(err, 'message');
                  setError({ [field]: message });
                  setIsTokenizing(false);
                },
              );
            }}
          >
            {props.cta}
          </Button>
        </Group>
      </Section>
    </Wrapper>
  );
};

CreditCardInput.propTypes = {
  cta: PropTypes.string,
  ctaTag: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  // function to call right after the submission of the card
  onCardSubmitted: PropTypes.func,
  // function to call right after card has been tokenized
  onTokenReceived: PropTypes.func,

  cancelButton: PropTypes.object,
};

CreditCardInput.defaultProps = {
  cta: 'Submit',
};

export default CreditCardInput;
