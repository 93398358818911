const format = ({
  amount,
  style,
  maximumFractionDigits,
  minimumFractionDigits
}) => Number(amount).toLocaleString(
  'en-US',
  {
    style,
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits
  }
);

export const formatCurrency = (amount = 0, minimumFractionDigits = 2, maximumFractionDigits = 2) =>
  format({
    amount,
    style: 'currency',
    maximumFractionDigits,
    minimumFractionDigits
  });


export const formatAmount = (amount = 0, minimumFractionDigits = 0, maximumFractionDigits = 3) =>
  format({
    amount,
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits
  });
