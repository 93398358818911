// Suite | Backwards compatibility

// Active Inventory/Site:
export const ACTIVE_INVENTORY_SELECT = 'ACTIVE_INVENTORY_SELECT';
export const ACTIVE_INVENTORY_UPDATE = 'ACTIVE_INVENTORY_UPDATE';
export const ACTIVE_INVENTORY_SET = 'ACTIVE_INVENTORY_SET';

// Site aggregate stats
export const AGGREGATE_STATS_SET = 'AGGREGATE_STATS_SET';
export const AGGREGATE_STATS_GET = 'AGGREGATE_STATS_GET';
