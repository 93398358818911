import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Text from '../../../../../components/Text';
import Button from '../../../../../components/Button';
import IconSvg from '../../../../../components/IconSvg';

import AddUserFormHeader, {
  addUserFormHeaderPropType,
} from '../../components/AddUserFormHeader';
import SimpleFields from '../../../../../components/SimpleFields';

const UserInfoStep = ({
  formFields,
  valueState,
  errors,
  handleChange,
  loading,
  goToNextStep,
  hasAccount,
  isDisabled,
  ...rest
}) => {
  return (
    <>
      <AddUserFormHeader
        {...rest}
        hasAccount={hasAccount}
        isDisabled={isDisabled}
        goToNextStep={goToNextStep}
      />
      <div>
        <Text textVariant="heading3" mb={32} style={{ textTransform: 'none' }}>
          User Information
        </Text>
        <Text textVariant="textLarge" mb={32} style={{ maxWidth: '550px' }}>
          Enter the email of a user you would like to invite to your account.
          They will use this email to login to MarketMuse.
        </Text>
        <SimpleFields
          fields={formFields}
          state={valueState}
          errors={errors}
          handleChange={handleChange}
          renderSubmit={
            <Button
              primary
              xLarge
              loading={loading}
              disabled={isDisabled}
              onClick={() => goToNextStep('billingInfoStep')}
            >
              Submit and continue
              <IconSvg
                name="arrow-right"
                size="16"
                color="white"
                style={{ marginLeft: '15px' }}
              />
            </Button>
          }
        />
      </div>
    </>
  );
};

UserInfoStep.propTypes = {
  ...addUserFormHeaderPropType,
  formFields: PropTypes.any,
  valueState: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default memo(UserInfoStep);
