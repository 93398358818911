import { Store } from 'redux';
import * as storeConfigs from '@marketmuse/data-state/store';
import { appOptimizeListener } from '@marketmuse/data-state/appOptimize';
import { appResearchListener } from '@marketmuse/data-state/appResearch';

import rootReducer from '../reducers';
import customMiddleware from '../middlewares';

import initialState from './initialState';
import { apolloClient } from './apollo/clients';

export default (): Store => {
  let useInitialState = initialState;

  if (window.Cypress && window.initialState) {
    useInitialState = window.initialState;
  }

  const isProduction = process.env.NODE_ENV === 'production';
  let createStore;
  if (isProduction) {
    createStore = storeConfigs.production;
  } else {
    createStore = storeConfigs.development;
  }

  return createStore({
    apolloClient,
    customMiddleware: [
      ...customMiddleware(),
      appResearchListener().middleware,
      appOptimizeListener().middleware,
    ],
    rootReducer,
    state: useInitialState,
  });
};
