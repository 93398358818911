import React, {  memo } from 'react';
import PropTypes from 'prop-types';
import Cell from '../Components/Cell';
import TBHead from './TBHead'; // <thead>
import TRHead from './TRHead'; // <tr>
import TDHead from './TDHead'; // <th>

const TableHeader = ({
  columns,
  loading,
  hasStickyHeader,
  columnsComputed,
  tbHeaderStyle,
  sort,
}) => {

  return (
    <TBHead
      hasStickyHeader={hasStickyHeader}
      style={tbHeaderStyle}
    >
      <TRHead>
        {columns.map(({ id, header, headerStyle, width, fixedWidth }) => {
          // args to call factory functions
          const fnArgs = { loading, sort };

          // get provided layout
          let headerParsed = typeof header === 'function'
            ? header(fnArgs)
            : header;

          // if no layout is provided, provide one
          if (typeof headerParsed !== 'object') {
            headerParsed = { center: { component: headerParsed } }
          }

          // get provided styles
          const headerStyleParsed = typeof headerStyle === 'function'
            ? headerStyle(fnArgs)
            : headerStyle;

          return (
            <TDHead
              key={id}
              width={width}
              computed={columnsComputed[id]}
              fixedWidth={fixedWidth}
            >
              <Cell
                layout={headerParsed}
                style={headerStyleParsed}
              />
            </TDHead>
          )
        })}
      </TRHead>
    </TBHead>
  )
};

TableHeader.propTypes = {
  headerGroups: PropTypes.array,
  hasStickyHeader: PropTypes.bool,
  loading: PropTypes.bool,
};

export default memo(TableHeader);
