import { ACTION_TYPES } from '@marketmuse/data-state';
import * as types from '../config/types';

export const getOrgs = ({ limit, order, page, query, callback }) => ({
  type: types.ADMIN_GET_ORGS,
  limit,
  order,
  page,
  query,
  callback,
});

export const getOrg = ({ id, options, callback, keys }) => ({
  type: types.ADMIN_GET_ORG,
  options,
  keys,
  id,
  callback,
});

export const getUsersAdmin = ({ callback }) => ({
  type: types.GET_USERS_ADMIN,
  callback,
});

export const adminModeEnable = ({ user }) => ({
  type: ACTION_TYPES.ADMIN_MODE_ENABLE,
  payload: user,
});

export const disableAdminMode = () => ({
  type: ACTION_TYPES.ADMIN_MODE_DISABLE,
});

export const setAllowedSubdomains = ({ siteId, subdomains, callback }) => ({
  type: types.SET_ALLOWED_SUBDOMAINS,
  siteId,
  subdomains,
  callback,
});

export const saveAllowedSubdomains = ({ siteId, subdomains }) => ({
  type: types.SAVE_ALLOWED_SUBDOMAINS,
  siteId,
  subdomains,
});

export const deleteOrg = ({ id, deleteSites, callback }) => ({
  type: types.DELETE_ORG,
  id,
  deleteSites,
  callback,
});
