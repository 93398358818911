import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import get from 'lodash/get';
import IconX from 'react-feather/dist/icons/x';
import { PAPI } from '@marketmuse/config/types';

import * as types from '../../config/types';

import * as adminActions from '../../actions/adminActions';
import * as reportActions from '../../actions/reportActions';
import * as miscActions from '../../actions/miscActions';

import hideTooltip from '../../utils/hideTooltip';
import isUrl from '../../utils/isUrl';

import reportCreated from '../../models/tracker/events/reports/ReportCreated';

import * as SL from '../../components/SimpleList';
import Loader from '../../components/Loader';
import Autocomplete from '../../components/Autocomplete';
import SimpleTextInput from '../../components/SimpleTextInput';
import Toggle from '../../components/Radio/Toggle';
import Tooltip from '../../components/Tooltip/Tooltip';
import Popper from '../../components/Tooltip/Popper';
import Button from '../../components/Button';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '../../components/Modal';

export const PopperStyled = styled(Popper)`
  display: block;
  width: 300px;
`;

const ButtonCancel = styled(Button)`
  border: none;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: -4px;
`;

const WarningRow = styled.div`
  background-color: ${p => p.theme.colors.redLight2};
  border: 1px solid ${p => p.theme.colors.redLight2};
  color: ${p => p.theme.colors.redDark1};
  font-size: 12px;
  width: 400px;
  border-radius: 3px;
  margin-top: 8px;
  margin-bottom: 2px;
  padding: 12px 18px;
`;

const orgMapper = org => ({
  ...org,
  text: `${org.name || org.slug}, (${org.users && org.users.length} user${
    org.users && org.users.length > 1 ? `s` : ``
  })`,
});

const BriefCreateModal = props => {
  // main settings
  const [briefType, setBriefType] = useState('create');
  const [managed, setManaged] = useState(true);
  const [topic, setTopic] = useState('');
  const [url, setUrl] = useState('');
  const [org, setOrg] = useState(props.org || null);
  const [orgPreview, setOrgPreview] = useState([]);
  const [orgQuery, setOrgQuery] = useState(null);
  const [site, setSite] = useState(props.site || null);
  const [inventoryQuery, setInventoryQuery] = useState(null);
  const [validRootUrl] = useState(true);
  const [validSubdomain] = useState(true);

  const orgSites = useMemo(() => {
    const sites = get(org, 'sites') || [];
    return sites.filter(({ visible }) => !!visible);
  }, [org]);

  const submissionCriteria = useMemo(() => {
    let creditsAvailable = false;
    let access = false;
    const level = site?.level;
    const cost = managed ? 4 : PAPI.SiteLevel.PREMIUM === level ? 0 : 1;

    if (site) {
      access = level !== PAPI.SiteLevel.FREE;

      creditsAvailable = Boolean((site?.briefCredits || 0) - cost >= 0);
    }

    return {
      cost,
      creditsAvailable,
      access,
    };
  }, [site, managed]);

  useEffect(() => {
    if (org) {
      setManaged(!org.selfReportOrdering);
    }
  }, [org]);

  // advanced settings
  useEffect(() => {
    if (!site && orgSites.length === 1) {
      setSite(orgSites[0]);
    }
  }, [site, orgSites]);

  const closeFn = data => {
    if (typeof props.close === 'function') props.close(data);
  };

  const isValidbaseReq = () => !!get(org, 'id') && !!get(site, 'id') && !!topic;

  const briefTypes = {
    create: {
      id: 'create',
      title: 'Create',
      isValid: () => isValidbaseReq(),
    },
    optimize: {
      id: 'optimize',
      title: 'Optimize',
      isValid: () => isValidbaseReq() && isUrl(url),
    },
  };

  const onOrgDebounce = useCallback(
    value => {
      props.adminActions.getOrgs({
        page: 0,
        limit: 30,
        query: value || null,
        callback: data => {
          const dropdownOrgs = get(data, 'items', []).sort(
            (a, b) => b.users.length - a.users.length,
          );

          setOrgPreview(dropdownOrgs);
        },
      });
    },
    [props.adminActions],
  );

  const orgInputProps = useMemo(() => {
    let itemsRight = null;

    if (props.loadings[types.ADMIN_GET_ORGS]) {
      itemsRight = [<Loader />];
    } else if (org && !props.preventOrgChange) {
      itemsRight = [
        <ButtonCancel
          default
          onClick={() => {
            setOrg(null);
            setSite(null);
          }}
        >
          <IconX style={{ width: 12 }} />
        </ButtonCancel>,
      ];
    }

    const value =
      orgQuery !== null
        ? orgQuery
        : get(org, 'name') || get(org, 'slug') || orgQuery;
    return {
      border: true,
      itemsRight,
      label: '* Organization',
      placeholder: 'Search...',
      value,
      onChange: e => {
        setOrgQuery(e.target.value);
        setInventoryQuery(null);
      },
      onDebounceDynamic: onOrgDebounce,
    };
  }, [org, orgQuery, props.loadings, props.preventOrgChange, onOrgDebounce]);

  return (
    <Modal hideClose close={closeFn} style={{ width: '464px' }}>
      <ModalHeader title="Create Report" close={closeFn} />
      <ModalBody>
        <SL.List
          mt={0}
          style={{
            flexFlow: 'column',
            alignItems: 'flex-start',
            justifyContent: 'stretch',
          }}
        >
          {/* brief type */}
          <SL.Row mt={0} width="100%">
            <SL.Item
              grow
              p={0}
              pt={0}
              style={{ flexFlow: 'column', alignItems: 'flex-start' }}
            >
              <Popper
                tippyUsage="dropdown"
                tippyTheme="light"
                label="Brief Type"
                handleId="report-type-dropdown"
                selection={
                  briefType ? get(briefTypes, `['${briefType}'].title`) : null
                }
                items={Object.values(briefTypes).map(p => ({
                  id: p.id,
                  component: p.title,
                  onClick: () => {
                    hideTooltip('report-type-dropdown');
                    setBriefType(p.id);
                  },
                }))}
              />
            </SL.Item>
          </SL.Row>

          {/* topic */}
          <SL.Row mt={8} width="100%">
            <SL.Item
              grow
              p={0}
              pt={8}
              style={{ flexFlow: 'column', alignItems: 'flex-start' }}
            >
              <SimpleTextInput
                border
                label="Focus Topic"
                value={topic}
                required
                onChange={e => setTopic(e.target.value)}
              />
            </SL.Item>
          </SL.Row>

          {/* url */}
          {briefType !== 'create' && (
            <SL.Row mt={8} width="100%">
              <SL.Item
                grow
                p={0}
                pt={8}
                style={{ flexFlow: 'column', alignItems: 'flex-start' }}
              >
                <SimpleTextInput
                  value={url}
                  onChange={e => setUrl(e.target.value)}
                  label={'Url'}
                  required
                />
              </SL.Item>
            </SL.Row>
          )}

          {!!url && !!site && !validRootUrl && (
            <WarningRow>The url '{url}' is not a root domain.</WarningRow>
          )}

          {!!url && !!site && !validSubdomain && (
            <WarningRow>
              The url '{url}' does not belong to the {get(site, 'domain')}{' '}
              inventory.
            </WarningRow>
          )}

          {/* org */}
          {!props.user && (
            <SL.Row mt={8} width="100%">
              <SL.Item grow p={0} pt={8}>
                <Autocomplete
                  id="org"
                  fullWidth
                  style={{ width: '100%' }}
                  shouldItemRender={() => true}
                  popperProps={{
                    style: { width: '100%' },
                    items:
                      orgPreview.length > 0
                        ? orgPreview.map(orgMapper)
                        : [
                            {
                              text: 'Type to search...',
                              disabled: true,
                            },
                          ],
                  }}
                  setValue={setOrg}
                  onSubmit={org => {
                    setOrg(org);
                    setSite(null);
                    setOrgQuery(null);
                  }}
                  inputComponent={SimpleTextInput}
                  inputProps={orgInputProps}
                />
              </SL.Item>
            </SL.Row>
          )}

          {!!org && orgSites.length === 0 && (
            <WarningRow>
              This organization has no site. You have to create a site for this
              organization.
            </WarningRow>
          )}

          {/* inventory */}
          {!props.user && !!org && orgSites.length > 0 && (
            <SL.Row mt={8} width="100%">
              <SL.Item grow p={0} pt={8}>
                <Autocomplete
                  id="inventory"
                  fullWidth
                  style={{ width: '100%' }}
                  popperProps={{
                    style: { width: '100%' },
                    items: orgSites.map(site => ({
                      ...site,
                      text: `${site.domain} (${site.level})`,
                    })),
                  }}
                  onSubmit={site => {
                    setSite(site);
                    setInventoryQuery(null);
                  }}
                  inputComponent={SimpleTextInput}
                  inputProps={{
                    border: true,
                    itemsRight:
                      !site || orgSites.length === 1 ? null : (
                        <ButtonCancel default onClick={() => setSite(null)}>
                          <IconX style={{ width: 12 }} />
                        </ButtonCancel>
                      ),
                    label: 'Inventory',
                    required: true,
                    placeholder: 'Search...',
                    value:
                      inventoryQuery !== null
                        ? inventoryQuery
                        : get(site, 'title') ||
                          get(site, 'domain') ||
                          inventoryQuery,
                    onChange: e => setInventoryQuery(e.target.value),
                  }}
                />
              </SL.Item>
            </SL.Row>
          )}

          {org && (
            <SL.Row mt={8} width="100%">
              <SL.Item grow p={0} pt={8}>
                <Tooltip
                  title={`${org.name} is ${
                    org.selfReportOrdering ? 'self serve' : 'managed'
                  }`}
                >
                  <Toggle
                    label={'Managed'}
                    active={managed}
                    onClick={() => setManaged(!managed)}
                  />
                </Tooltip>
              </SL.Item>
            </SL.Row>
          )}
        </SL.List>

        {site && !submissionCriteria.access && (
          <WarningRow>
            Brief can't be created for a {site.level} inventory.
          </WarningRow>
        )}
        {site &&
          submissionCriteria.access &&
          !submissionCriteria.creditsAvailable && (
            <WarningRow>
              {site.domain} only has {site.briefCredits} credits. <br />
              Cost: {submissionCriteria.cost}
            </WarningRow>
          )}
      </ModalBody>

      <ModalFooter hasBorderTop>
        <Button
          bold
          large
          onClick={closeFn}
          disabled={props.loadings[types.CREATE_REPORT]}
        >
          Cancel
        </Button>
        <Button
          large
          primary
          loading={props.loadings[types.CREATE_REPORT]}
          data-track={'reports--report-create'}
          disabled={
            props.loadings[types.CREATE_REPORT] ||
            !submissionCriteria.access ||
            !submissionCriteria.creditsAvailable
          }
          onClick={() => {
            let data = {
              term: topic,
              reportType: briefType,
              siteId: get(site, 'id'),
              selfServe: !managed,
              callback: data => {
                reportCreated.record({
                  topic,
                  briefType,
                  url,
                  org,
                });
                closeFn({ refetch: true });
                if (typeof props.callback === 'function') props.callback(data);
              },
            };

            // if its create, always use site url
            if (briefType === 'optimize') {
              data.url = url;
            }

            props.reportActions.createReport(data);
          }}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

BriefCreateModal.propTypes = {
  org: PropTypes.object,
  site: PropTypes.object,
  close: PropTypes.func,

  // to stop users from changing the org and site
  preventOrgChange: PropTypes.bool,

  // function to call after successful request
  callback: PropTypes.func,

  loadings: PropTypes.object,
  misc: PropTypes.object,
  adminActions: PropTypes.object,
  reportActions: PropTypes.object,
  miscActions: PropTypes.object,
};

BriefCreateModal.defaultProps = {
  hiddenReportTypes: [],
};

const mapStateToProps = state => ({
  loadings: state.loadings,
  misc: state.misc,
});

const mapDispatchToProps = dispatch => ({
  adminActions: bindActionCreators(adminActions, dispatch),
  reportActions: bindActionCreators(reportActions, dispatch),
  miscActions: bindActionCreators(miscActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BriefCreateModal);
