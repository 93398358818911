import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { sites as sitesConfig } from '@marketmuse/config/configs';
import { RECURLY, PAPI } from '@marketmuse/config/types';

import { recurlyPlansInfo } from '@marketmuse/config/configs';

import Box from '../../../../components/Box';
import { LoaderSparkles } from '../../../../components/Loader';
import Tab from '../../components/Tab';
import Text from '../../../../components/Text';

import { formatCurrency } from '../../../../utils/formatNumbers';

import isPaymentTestModeActive from '../../../../utils/isPaymentTestModeActive';
import getPlanDetails from '../../../../utils/payment/getPlanDetails';
import * as types from '../../../../config/types';

export const addUserFormHeaderPropType = {
  planName: PropTypes.string,
  setPlanName: PropTypes.func.isRequired,
  planInterval: PropTypes.string,
  currentStep: PropTypes.string,
  availableSteps: PropTypes.arrayOf(PropTypes.string),
  goToNextStep: PropTypes.func.isRequired,
  isCheckingAccount: PropTypes.bool,
  hasAccount: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  backdrop-filter: blur(2px);
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: ${p => p.theme.colors.blue};
  svg {
    color: ${p => p.theme.colors.blue};
    height: 64px;
    width: 64px;
  }
  flex-direction: column;
`;

const Tabs = styled(Box).attrs({
  boxVariant: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',
  maxWidth: '660px',
  height: '100%',
  mb: 40,
})``;

const TABS = [
  {
    id: 'userInfoStep',
    title: 'Step 1',
    description: 'Enter user information',
    icon: 'overview',
  },
  {
    id: 'billingInfoStep',
    title: 'Step 2',
    description: 'Set payment preferences',
    icon: 'payment-card',
  },
];

const userPlanDetails = getPlanDetails({
  planName: RECURLY.PLAN_NAME.USER,
  planInterval: 'monthly',
});
const appSumoUserPlanDetails = getPlanDetails({
  planName: RECURLY.PLAN_NAME.APP_SUMO_USER,
  planInterval: 'monthly',
});

const plansDetails = {
  [RECURLY.PLAN_NAME.USER]: userPlanDetails,
  [RECURLY.PLAN_NAME.APP_SUMO_USER]: appSumoUserPlanDetails,
};

const AddUserFormHeader = ({
  planName,
  setPlanName,
  planInterval,
  currentStep,
  availableSteps,
  goToNextStep,
  isCheckingAccount,
  hasAccount,
  isDisabled,
}) => {
  const isSubscribingToPlan = useSelector(
    state => state.loadings[types.CREATE_USER_SUBSCRIPTION],
  );
  const sites = useSelector(state => state.user?.org?.sites) || [];
  const account = useSelector(state => state?.payment?.account);

  const isAppSumoUserPlan = useMemo(
    () => planName && planName === RECURLY.PLAN_NAME.APP_SUMO_USER,
    [planName, planInterval],
  );

  useEffect(() => {
    if (hasAccount) {
      const hasAppSumoSites = !!sites.find(
        ({ level }) => level === PAPI.SiteLevel.APPSUMO,
      );
      let newPlan = RECURLY.PLAN_NAME.USER;
      if (hasAppSumoSites) {
        const codeMode = isPaymentTestModeActive() ? 'codeTest' : 'code';
        const appSumoPlanCode =
          recurlyPlansInfo?.[RECURLY.PLAN_NAME.APP_SUMO_USER]?.[planInterval]?.[
            codeMode
          ];
        const appSumoSubsCount =
          account?.subscriptions?.subscriptions?.subscription?.filter(
            ({ subscriptionPlanCode }) =>
              subscriptionPlanCode === appSumoPlanCode,
          )?.length || 0;
        if (
          appSumoSubsCount <
          sitesConfig[PAPI.SiteLevel.APPSUMO]?.configs
            ?.APP_SUMO_USER_SUBSCRIPTIONS_LIMIT
        ) {
          newPlan = RECURLY.PLAN_NAME.APP_SUMO_USER;
        }
      }
      setPlanName(newPlan);
    }
  }, [hasAccount, recurlyPlansInfo, account, sites, currentStep]);

  return (
    <>
      <Box boxVariant="flex">
        <Text textVariant="heading3" mb={32} style={{ textTransform: 'none' }}>
          1 User&nbsp;
          {planName &&
            !isCheckingAccount &&
            (isAppSumoUserPlan ? (
              <>
                {formatCurrency(
                  plansDetails?.[RECURLY.PLAN_NAME.APP_SUMO_USER]
                    ?.priceInCentsTerm / 100,
                  0,
                )}
                /month{' '}
                <s>
                  (
                  {formatCurrency(
                    plansDetails?.[RECURLY.PLAN_NAME.USER]?.priceInCentsTerm /
                      100,
                    0,
                  )}
                  /month)
                </s>
              </>
            ) : (
              <>
                {formatCurrency(
                  plansDetails?.[RECURLY.PLAN_NAME.USER]?.priceInCentsTerm /
                    100,
                  0,
                )}
                /month
              </>
            ))}
        </Text>
      </Box>

      <Tabs>
        {TABS.map(tab => (
          <Tab
            key={tab.id}
            tab={tab}
            isActive={tab.id === currentStep}
            isAvailable={
              !isSubscribingToPlan &&
              availableSteps.includes(tab.id) &&
              !(tab.id !== 'userInfoStep' && isDisabled)
            }
            setStep={goToNextStep}
          />
        ))}
      </Tabs>
      {isCheckingAccount && (
        <Wrapper>
          <LoaderSparkles />
        </Wrapper>
      )}
    </>
  );
};

AddUserFormHeader.propTypes = addUserFormHeaderPropType;

export default memo(AddUserFormHeader);
