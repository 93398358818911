import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { kebabCase, isEmpty } from 'lodash';

import * as planActions from '../../../actions/planActions';
import { useSuiteNavigate } from '../../../hooks/useSuiteNavigate';
import { formatPlural } from '../../../utils/pluralRules';

import Portal from '../../../components/Portal';
import Dialog from '../../../components/Dialog';

const DeletePlan = ({ planToDelete, onCancel, onDeleteComplete }) => {
  const navigate = useSuiteNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const org = useSelector(state => state?.user?.org);

  const message = useMemo(() => {
    const count = planToDelete?.projects?.length || 0;
    if (isEmpty(planToDelete)) {
      return 'No plan selected, something went wrong';
    }
    if (count === 0) {
      return (
        <>
          You are about to permanently delete this plan for all users in{' '}
          {org?.name || org?.slug}. This plan is not connected to any page or
          topics.
        </>
      );
    }

    return (
      <>
        You are about to permanently delete this plan, and its {count}{' '}
        {formatPlural('connection', count || 1)} to pages and topics for all
        users in {org?.name || org?.slug}. This cannot be undone.
      </>
    );
  }, [planToDelete, org]);

  return (
    <Portal>
      <Dialog
        {...{
          title: `Delete #${kebabCase(planToDelete?.title)}?`,
          message: message,
          close: () => onCancel(),
          ctas: [
            {
              title: 'Cancel',
              onClick: () => onCancel(),
              variant: 'blackOnTransparent',
            },
            {
              title: 'Delete Plan',
              loading: loading,
              onClick: () => {
                setLoading(true);
                dispatch(
                  planActions.deletePlans({
                    planIds: planToDelete.id,
                    callback: () => {
                      onDeleteComplete();
                      navigate('inventory/planning');
                    },
                  }),
                );
              },
              variant: 'redLight2',
            },
          ],
        }}
      />
    </Portal>
  );
};

export default DeletePlan;
