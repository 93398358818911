import { ObjectValues } from './utilities';

const AUTH_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  USER: 'USER',
  CLIENT: 'CLIENT',
  GUEST: 'GUEST',
  AUTH0: 'AUTH0',
} as const;

export type AuthRoles = ObjectValues<typeof AUTH_ROLES>;

export default AUTH_ROLES;
