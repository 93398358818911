import styled from 'styled-components';

import IconSvg from './../../IconSvg/IconSvg';

export default styled(IconSvg).attrs({
  size: 16,
  name: 'x',
})`
  color: ${p => p.theme.colors.redBase};
  width: 22px;
`;
