import React from 'react';

// Onboarding coupon is static
export const ONBOARDING_CHECKLIST_COUPON = {
  coupon: 'checklist15',
  discount: '15%',
};

export const ONBOARDING_CHECKLIST_LIST = {
  optimize_cs_1: {
    id: 'optimize_cs_1',
    active: true,
  },
  research_outline_variants_1: {
    id: 'research_outline_variants_1',
    active: true,
  },
  compete_heatmap_1: {
    id: 'compete_heatmap_1',
    active: true,
  },
  questions_run_1: {
    id: 'questions_run_1',
    active: true,
  },
};

// list of onboarding keys used to de-mark progress
// as marketing needs change, a key may be dropped
// - unique keys allow for checklist req. to change and make stale old progress
// - it is intended that product deprecation of progress means users lose this progress
// todo: deprecated, use ids above
export const ONBOARDING_CHECKLIST_KEYS = {
  optimize_1: 'optimize_cs_1',
  research_1: 'research_outline_variants_1',
  compete_1: 'compete_heatmap_1',
  questions_1: 'questions_run_1',
};

// middleware config/onboardingConfig.js -> obChecklist
export const ONBOARDING_CHECKLIST_UI = {
  optimize_cs_1: {
    id: ONBOARDING_CHECKLIST_KEYS.optimize_1,
    order: 1,
    guide: {
      tagId: 'optimize_cs_1-guide',
      location: 'apps/optimize/guide',
    },
    data: {
      title: (
        <>
          Hit your target Content Score in <strong>Optimize</strong>
        </>
      ),
    },
  },
  research_outline_variants_1: {
    id: ONBOARDING_CHECKLIST_KEYS.research_1,
    order: 2,
    guide: {
      tagId: 'research_outline_variants_1-guide',
      location: 'apps/research/guide',
    },
    data: {
      title: (
        <>
          Explore a <strong>Research</strong> topic with variants
        </>
      ),
    },
  },
  questions_run_1: {
    id: ONBOARDING_CHECKLIST_KEYS.questions_1,
    order: 4,
    guide: {
      tagId: 'questions_run_1-guide',
      location: 'apps/questions/guide',
    },
    data: {
      title: (
        <>
          Click Run-In on <strong>Questions</strong>
        </>
      ),
    },
  },
};
