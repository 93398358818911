import { SiteLevel } from './papi';
import { ObjectValues } from './utilities';

export const PLAN_NAME = {
  APP_SUMO_USER: 'appsumoUser',
  USER: 'user',
  STANDARD: SiteLevel.STANDARD,
  STANDARD_TRIAL: 'standardTrial',
  CLASSIC: 'classic',
} as const;

export const PLAN_INTERVAL = {
  monthly: 'monthly',
  annual: 'annual',
  weekly: 'weekly',
} as const;

export type PlanName = ObjectValues<typeof PLAN_NAME>;
export type PlanInterval = ObjectValues<typeof PLAN_INTERVAL>;

type PlanDetails = {
  term: string;
  termUnit: string;
  termLength: number;
  priceInCentsTerm: number;
  priceInCentsMonthly: number;
};

type Plan = {
  code: string;
  codeTest: string;
  planDetails: PlanDetails;
};

type PlanUser = {
  monthly: Plan;
};
type PlanSite = {
  monthly: Plan;
  annual: Plan;
};
type PlanSiteTrial = {
  weekly: Plan;
};

export type PlanInfo = {
  [PLAN_NAME.APP_SUMO_USER]: PlanUser;
  [PLAN_NAME.USER]: PlanUser;
  [PLAN_NAME.STANDARD]: PlanSite;
  [PLAN_NAME.STANDARD_TRIAL]: PlanSiteTrial;
};

export type BillingCountries = Record<
  string,
  { id: string; title: string; name: string; flag: string }
>;
