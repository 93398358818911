import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { capitalize, isNil } from 'lodash';

import SimpleGrid from '../SimpleGrid';
import Button from '../Button';
import {
  formatPlural,
  SUBSCRIPTION_TYPES_PLURAL_MAP,
} from '../../utils/pluralRules';
import Dialog from '../Dialog';

const Wrapper = styled.div`
  padding: 18px;
  margin-top: 22px;
`;

const Separator = styled.div`
  margin: 20px 0 0 0;
  height: 1px;
  width: 100%;
  background-color: ${p => p.theme.colors.grey15};
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Price = styled.div`
  color: ${p => p.theme.colors.grey85};
  font-weight: bold;
  font-size: 9pt;
`;

const PriceAvailable = styled.div`
  margin-left: 8px;
  color: ${p => p.theme.mmxDarkGrey};
  font-size: 8pt;
`;

const PriceSavings = styled.div`
  margin-left: 8px;
  color: ${p => p.theme.colors.greenDark1};
  font-weight: bold;
  font-size: 9pt;
`;

const FlexBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
`;

const StyledButton = styled(Button)`
  margin-left: ${p => p.theme.spacing.small};
`;

const CheckoutSection = ({
  available,
  items,
  priceCta,
  savings,
  total,
  totalNum,
  isDisabledSubmitButton,
  submitAction,
  onSubmitCallback,
  isPurchasing,
  type,
}) => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState();

  const confirmationBody = useMemo(() => {
    const map = SUBSCRIPTION_TYPES_PLURAL_MAP[type];
    const capitalizedPlural = capitalize(map.pluralString);

    return (
      <>
        <div>
          You are buying {totalNum} Extra{' '}
          {formatPlural(capitalizedPlural, totalNum, map.pluralMap)}. for{' '}
          {total}.
        </div>
        <div>
          <br />
          <strong>
            {formatPlural('', totalNum, { one: 'This', other: 'These' })}{' '}
            {formatPlural(map.pluralString, totalNum, map.pluralMap)} will never
            expire and will be seen in your Extra{' '}
            {formatPlural(capitalizedPlural, 2, map.pluralMap)} balance.
          </strong>
        </div>
      </>
    );
  }, [totalNum, type, total]);

  const onConfirm = useCallback(() => {
    setShowConfirm(false);
    dispatch(
      submitAction({
        num: totalNum,
        callback: onSubmitCallback,
      }),
    );
  }, [dispatch, submitAction, totalNum]);

  return (
    <Wrapper>
      {showConfirm && (
        <Dialog
          {...{
            title: 'Confirm Purchase',
            message: confirmationBody,
            close: () => setShowConfirm(false),
            ctas: [
              {
                title: 'Cancel',
                onClick: () => setShowConfirm(false),
                large: true,
                tertiary: true,
              },
              {
                title: 'Yes, Confirm',
                onClick: () => onConfirm(),
                primary: true,
                loading: false,
              },
            ],
          }}
        />
      )}

      <div>
        Your Order
        {items.map((item, index) => (
          <ItemWrapper key={index}>{item}</ItemWrapper>
        ))}
      </div>

      {!!total && (
        <>
          <Separator />
          <SimpleGrid.Container gap={'32px'} columns={2} padding="20px 0">
            <SimpleGrid.Item>
              <div>
                <Price>Total Cost: {total}</Price>
                {!isNil(available) && (
                  <PriceAvailable>{available}</PriceAvailable>
                )}
                {!isNil(savings) && <PriceSavings>{savings}</PriceSavings>}
              </div>
            </SimpleGrid.Item>
            <SimpleGrid.Item>
              <FlexBlock>
                {!isNil(priceCta) && priceCta}
                <StyledButton
                  large
                  primary
                  disabled={!total || isDisabledSubmitButton}
                  onClick={() => setShowConfirm(true)}
                >
                  {isPurchasing && 'Purchasing...'}
                  {!isPurchasing && 'Confirm Order'}
                </StyledButton>
              </FlexBlock>
            </SimpleGrid.Item>
          </SimpleGrid.Container>
        </>
      )}
    </Wrapper>
  );
};

CheckoutSection.propTypes = {
  items: PropTypes.array,
  total: PropTypes.any,
  totalNum: PropTypes.any,
  available: PropTypes.any,
  priceCta: PropTypes.any,
  isDisabledSubmitButton: PropTypes.bool,
  isPurchasing: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(SUBSCRIPTION_TYPES_PLURAL_MAP)),
};

CheckoutSection.defaultProps = {
  items: [],
  total: 0,
  totalNum: 0,
  available: null,
  priceCta: null,
  isPurchasing: false,
};

export default CheckoutSection;
