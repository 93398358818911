import { PlanInfo, PLAN_NAME } from '../../types/recurly';

export const plansInfo: PlanInfo = {
  [PLAN_NAME.STANDARD]: {
    monthly: {
      code: 'standard-149-monthly-q12023',
      codeTest: 'standard-149-monthly-q12023',
      planDetails: {
        term: '1 month',
        termUnit: 'months',
        termLength: 1,
        priceInCentsTerm: 14900,
        priceInCentsMonthly: 14900,
      },
    },
    annual: {
      code: 'standard-1500-annual-q12023',
      codeTest: 'standard-1500-annual-q12023',
      planDetails: {
        term: '1 year',
        termUnit: 'months',
        termLength: 12,
        priceInCentsTerm: 150000,
        priceInCentsMonthly: 12500,
      },
    },
  },
  [PLAN_NAME.APP_SUMO_USER]: {
    monthly: {
      code: 'appsumo-user',
      codeTest: 'appsumo-user',
      planDetails: {
        term: '1 month',
        termUnit: 'months',
        termLength: 1,
        priceInCentsTerm: 2500,
        priceInCentsMonthly: 2500,
      },
    },
  },
  [PLAN_NAME.USER]: {
    monthly: {
      code: 'user',
      codeTest: 'user',
      planDetails: {
        term: '1 month',
        termUnit: 'months',
        termLength: 1,
        priceInCentsTerm: 9900,
        priceInCentsMonthly: 9900,
      },
    },
  },
  [PLAN_NAME.STANDARD_TRIAL]: {
    weekly: {
      code: 'standard-trial-q22023',
      codeTest: 'standard-trial-q22023',
      planDetails: {
        term: '1 week',
        termUnit: 'week',
        termLength: 1,
        priceInCentsTerm: 0,
        priceInCentsMonthly: 0,
      },
    },
  },
};
