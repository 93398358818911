import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SummaryBlock from './SummaryBlock';

const CreditsSummary = () => {
  const site = useSelector(state => state.filter?.site) ?? {};

  const getSecondColumn = useCallback(
    ({ isExtra }) => (
      <>
        <h1>{site.briefCredits || 0}</h1>
        <div>
          <strong>{isExtra ? 'Extra ' : ''}Credits</strong>
        </div>
        <div className="description">
          {isExtra ? 'Extra' : 'Your'} credits never expire and are purchased
          individually or in bundles.
        </div>
      </>
    ),
    [site.briefCredits],
  );

  const firstColumn = useMemo(
    () => getSecondColumn({ showSubscriptionButton: true }),
    [getSecondColumn],
  );

  return (
    <SummaryBlock
      icon={{ name: 'credits', color: 'yellowBase' }}
      title="Credits summary"
      firstColumn={firstColumn}
    />
  );
};

CreditsSummary.propTypes = {
  handleSettingsClick: PropTypes.func,
  onPlanSelection: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default memo(CreditsSummary);
