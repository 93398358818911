import styled from 'styled-components';
import PropTypes from 'prop-types';

import Clickable from './../../Clickable/Clickable';

const ActionButton = styled(Clickable)`
  width: max-content;
  padding: 0 ${p => p.theme.spacing.xxSmall};
  color: ${p => p.theme.colors.grey50};
  background-color: ${p => p.theme.colors.grey10};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  border-radius: 4px;
  user-select: none;
  transition: color 0.3s ${p => p.theme.transitionFn},
    background-color 0.3s ${p => p.theme.transitionFn};

  ${p =>
    p.active &&
    `
    color: ${p.theme.colors.black};
    background-color: ${p.theme.colors.grey15};
  `}

  ${p =>
    p.selected &&
    `
    color: ${p.theme.colors.blueDark};
    background-color: ${p.theme.colors.grey15};
  `}

  ${p =>
    p.disabled &&
    `
    color: ${p.theme.colors.grey25};
  `}

  ${p =>
    !p.disabled &&
    typeof p.onClick === 'function' &&
    `
    &:hover {
      color: ${p.theme.colors.black};
    }

    &:focus {
      color: ${p.theme.colors.black};
      background-color: ${p.theme.colors.grey15};
    }
  `}
`;

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  active: PropTypes.bool,
};

export default ActionButton;
