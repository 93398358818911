import dayjs from 'dayjs';

const Dates = ({
  start,
  end,
  dateFormat = 'MMM D, YYYY'
}) => {
  const startDay = dayjs(start);
  const endDay = dayjs(end);

  const sameYear = startDay.year() === endDay.year();
  const sameMonth = sameYear && startDay.month() === endDay.month();
  const startDateFormat =
    dateFormat !== 'MMM' && sameYear ? 'MMM' : dateFormat;

  if (!start || sameMonth) {
    return dayjs(end).format(dateFormat);
  }
  return `${startDay.format(startDateFormat)} → ${endDay.format(dateFormat)}`;
};

export default Dates;
