import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { get, isNil } from 'lodash';

import ProgressBarIndeterminate from '../../ProgressBarIndeterminate';

import isNumeric from '../../../utils/isNumeric';

// TODO: MMS-290
const PageHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  width: 100%;
  padding-left: ${p => p.theme.pagePaddingLR}px;
  padding-right: ${p => p.theme.pagePaddingLR}px;
  background-color: white;
  border-bottom: 1px solid ${p => p.theme.mmxGreyE9};
`;
const attrFn = p => ({
  style: {
    height: p.collapsed && `${p.theme.pageHeaderHeight + 1}px`,
  },
});

const Head = styled.div.attrs(attrFn)`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${p => p.theme.pageHeaderHeight}px;
`;

const PageHeaderIcon = styled.div`
  flex-shrink: 0;
  font-size: 16pt;
  color: ${p => p.theme.colors.grey85};
`;

const Title = styled.h1`
  flex-shrink: 0;
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  color: ${p => p.theme.colors.grey85};
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 10px;
  user-select: none;
`;

const Contents = styled.div`
  min-height: ${p => p.theme.pageHeaderHeight}px;
  overflow: visible;
  display: flex;
  align-items: center;
  ${p =>
    !isNil(p.paddingRight) &&
    `padding-right: ${
      isNumeric(p.paddingRight) ? p.paddingRight : p.theme.pagePaddingLR
    }px;`}
  ${p =>
    !isNil(p.paddingLeft) &&
    `padding-left: ${
      isNumeric(p.paddingLeft) ? p.paddingLeft : p.theme.pagePaddingLR
    }px;`}

  ${p =>
    p.isLast &&
    `
    margin-left: auto;
    margin-right: 0;
  `}
`;

export const PageHeaderBody = styled.div`
  padding: 0 ${p => p.theme.pagePaddingLR}px;
`;

class PageHeaderComponent extends React.Component {
  render() {
    const children = get(this.props, 'children');
    return (
      <>
        <PageHeaderWrapper
          ref={this.header}
          className={get(this.props, 'className')}
          style={this.props.style}
          {...(this.props.headerProps || {})}
        >
          {(get(this.props, 'icon') || get(this.props, 'title')) && (
            <Head data-mms--page-head>
              <PageHeaderIcon>{get(this.props, 'icon')}</PageHeaderIcon>
              <Title>{get(this.props, 'title')}</Title>
            </Head>
          )}

          {!Array.isArray(children) && <Contents>{children}</Contents>}
          {Array.isArray(children) &&
            children
              .filter(c => c && !c.row)
              .map((c, index) => {
                // react uses Symbols to identify objects, and will populate $$typeof property
                // even for browsers that doesn't support Symbols, so it seems to be good way
                // of telling whether an object is just a plain object or a React component
                if (c && c.hasOwnProperty('$$typeof'))
                  return <Contents key={index}>{c}</Contents>;
                return <Contents key={index} {...c} />;
              })}

          {!get(this.props, 'hideHelp') && (
            <Contents
              isLast
              data-mms--trigger-help
              style={{ width: '50px', height: '50px' }}
            />
          )}

          {this.props.loading && (
            <ProgressBarIndeterminate
              data-mms--loading
              style={this.props.loadingTop ? { top: 0 } : { bottom: -1 }}
            />
          )}
        </PageHeaderWrapper>
        {Array.isArray(children) &&
          children
            .filter(c => c && c.row)
            .map((c, index) => {
              if (c && c.hasOwnProperty('$$typeof'))
                return <Contents key={index}>{c}</Contents>;
              return <Contents key={index} {...c} />;
            })}
      </>
    );
  }
}

PageHeaderComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any,
  hideProfile: PropTypes.bool,
  // render loading animation
  loading: PropTypes.bool,
  // render loading animation on the top
  loadingTop: PropTypes.bool,
  // Pass props to <Head> component in <PageHeader>
  headerProps: PropTypes.object,

  selectionLen: PropTypes.number,
  selectionText: PropTypes.any,
  deselectAll: PropTypes.func,

  // hide PARATIVE helper
  hideHelp: PropTypes.bool,
};

export default PageHeaderComponent;
