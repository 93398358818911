import React, { memo } from 'react';
import styled from 'styled-components';

import IconSvg from '../../../components/IconSvg';

const TabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  align-self: stretch;
  position: relative;
  min-height: 80px;
  padding: ${p => `${p.theme.spacing.regular} ${p.theme.spacing.medium}`};
  background-color: ${p =>
    p.theme.colors[p.isActive ? 'blueLight2' : 'grey05']};
  cursor: ${p => (p.isAvailable ? 'pointer' : 'default')};
  &:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

const TabInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TabName = styled.span`
  ${p => p.theme.type.heading5};
  margin: 0;
`;

const TabDescription = styled.span`
  ${p => p.theme.type.textRegular};
  margin: 0;
`;

const IconSvgStyled = styled(IconSvg)`
  margin-right: ${p => p.theme.spacing.regular};
`;

const Tab = ({
  tab: { id, icon, title, description },
  isActive,
  isAvailable,
  setStep,
}) => {
  const textStyle = { color: isAvailable ? 'black' : 'grey' };

  return (
    <TabWrapper
      onClick={isAvailable ? () => setStep(id) : () => {}}
      isActive={isActive}
      isAvailable={isAvailable}
    >
      {icon && (
        <IconSvgStyled
          name={icon}
          size={icon === 'connect' ? '16' : '28'}
          box="28"
          color={isAvailable ? 'blue' : 'grey50'}
        />
      )}

      <TabInfo>
        {title && <TabName style={textStyle}>{title}</TabName>}
        {description && (
          <TabDescription style={textStyle}>{description}</TabDescription>
        )}
      </TabInfo>
    </TabWrapper>
  );
};

export default memo(Tab);
