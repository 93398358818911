import { upperFirst, get } from 'lodash';
import * as FullStory from '@fullstory/browser';
import { SiteLevel } from '@marketmuse/config/types/papi';
import { titleCase } from '@marketmuse/utilities';
import { tracker } from '../config/instances';

const fullStorySession = () => {
  return FullStory.isInitialized() && FullStory.getCurrentSessionURL(true);
};

const highestInventoryLevel = orgSiteLevels => {
  const hasPremium = orgSiteLevels.includes(SiteLevel.PREMIUM);
  const hasClassic = orgSiteLevels.includes(SiteLevel.CLASSIC);
  const hasStandard = orgSiteLevels.includes(SiteLevel.STANDARD);
  const hasAppsumo = orgSiteLevels.includes(SiteLevel.APPSUMO);
  const hasFree = orgSiteLevels.includes(SiteLevel.FREE);

  if (hasPremium) {
    return SiteLevel.PREMIUM;
  }
  if (hasClassic) {
    return SiteLevel.CLASSIC;
  }
  if (hasStandard) {
    return SiteLevel.STANDARD;
  }
  if (hasAppsumo) {
    return SiteLevel.APPSUMO;
  }
  if (hasFree) {
    return SiteLevel.FREE;
  }
};

const userDataLayer = ({ user, site, subscriptions = [] }) => {
  const orgSiteLevels = (get(user, 'org.sites') || [])
    .filter(site => site?.visible)
    .map(site => site?.level);

  return {
    id: get(user, 'id'),
    fullName: get(user, 'fullName'),
    firstName: get(user, 'firstName'),
    lastName: get(user, 'lastName'),
    email: get(user, 'email'),
    org: get(user, 'org'),
    tier: titleCase(get(user, 'org.clientTier') || 'None'),
    sfdcContactId: get(user, 'sfdcContactId'),
    site,
    free: orgSiteLevels.includes(SiteLevel.FREE),
    appsumo: orgSiteLevels.includes(SiteLevel.APPSUMO),
    standard: orgSiteLevels.includes(SiteLevel.STANDARD),
    classic: orgSiteLevels.includes(SiteLevel.CLASSIC),
    premium: orgSiteLevels.includes(SiteLevel.PREMIUM),
    fullStorySession: fullStorySession(),
    highestInventoryLevel: upperFirst(highestInventoryLevel(orgSiteLevels)),
    subscriptions,
  };
};

export const updateTrackedUser = ({ user, site, subscriptions }) => {
  tracker.update(userDataLayer({ user, site, subscriptions }));
};

export const initTrackedUser = ({ user, site, subscriptions }) => {
  if (get(tracker, 'user.id') !== user?.id) {
    tracker.init(userDataLayer({ user, site, subscriptions }));
  } else {
    updateTrackedUser({ user, site, subscriptions });
  }
};

export const trackerReset = () => {
  tracker.clear();
};
