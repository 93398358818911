import { isEmpty } from 'lodash';
import { RecurlySubscription } from '@marketmuse/config/types/papi';
import { recurlyPlansCodes } from '@marketmuse/config/configs';

type SubscriptionTrial = {
  standardTrialExpiryDate: Date;
};
type SubscriptionStarted = {
  subscribedToStandardDate: Date;
};
type SubscriptionCancelled = {
  cancelledStandardDate: Date;
};

type ResponseShape =
  | SubscriptionTrial
  | SubscriptionStarted
  | SubscriptionCancelled;

const serializePendoSubscriptionInfo = (
  subscriptions: RecurlySubscription[],
): ResponseShape => {
  const returnValue = {};

  if (isEmpty(subscriptions)) {
    return returnValue;
  }
  const subscriptionsSorted = subscriptions.slice().sort((a, b) => {
    const dateA = new Date(a?.createdAt?.toString()).valueOf();
    const dateB = new Date(b?.createdAt?.toString()).valueOf();
    return dateB - dateA;
  });

  const canceled = subscriptionsSorted.filter(subscription => {
    return (
      recurlyPlansCodes.standard.includes(subscription.plan.code) &&
      ['expired', 'canceled'].includes(subscription.state)
    );
  });

  const active = subscriptionsSorted.filter(subscription => {
    return (
      recurlyPlansCodes.standard.includes(subscription.plan.code) &&
      ['active'].includes(subscription.state)
    );
  });

  const trial = subscriptionsSorted.filter(subscription => {
    return recurlyPlansCodes.standardTrial.includes(subscription.plan.code);
  });

  if (canceled.length && !active.length) {
    returnValue.cancelledStandardDate = canceled[0].canceledAt;
  }

  if (active.length) {
    returnValue.subscribedToStandardDate = active[0].activatedAt;
  }

  if (trial.length) {
    returnValue.standardTrialExpiryDate = trial[0].currentTermEndsAt;
  }

  return returnValue;
};

export default serializePendoSubscriptionInfo;
