import React from 'react';
import PropTypes from 'prop-types';

import { TDBody } from './styleTD';

const TDBodyComponent = ({
  style,
  children,
  width,
  fixedWidth,
  computed,
  showOnHover,
}) => (
  <TDBody
    style={style}
    width={width}
    fixedWidth={fixedWidth}
    computed={computed}
    className={showOnHover ? 'mms--td-showOnhover' : ''}
  >
    {children}
  </TDBody>
);

TDBodyComponent.propTypes = {
  children: PropTypes.any,
  cellProps: PropTypes.object,
  fixedWidth: PropTypes.bool,
  showOnHover: PropTypes.bool,
  style: PropTypes.object,
};

export default TDBodyComponent;

